import React, { useEffect, useState } from "react";
import FlexCol from "../Common/FlexCol";
import Heading from "../Common/Heading";
import CommonButton from "../Common/Button";
import Cards from "../Common/Cards";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import Graph from "../Common/Graph";
import PieChart from "../Common/PieChart";
import FeedCattle from "../Common/FeedCattle";
import SearchBox from "../Common/SearchBox";
import Filterbtn from "../Common/Filterbtn";
import ReactTable from "../Common/Table";
import Dropdownbtn from "../Common/Dropdownbtn";
import DiagnoseCattle from "../Drawer/DiagnoseCattle";
import cowicon from "../../assets/Images/Cow icon.png";
import API, { action } from "../../Api";
import { useLocation, useNavigate } from "react-router";
import { Pagination } from "antd";
import TableAccordion from "../Common/TableAccordion";
import { useLoader } from "../../LoaderContext";
import Preview from "../Drawer/Review";
import Review from "../Drawer/Review";

function HealthMonitoring() {
  const [report, setReport] = useState(false);
  const [Diagnose, setDiagnose] = useState(false);
  const [preview,setPreview] = useState(false)
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState(null)
  const [paginationcount, setPaginationcount] = useState()
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('tablePage');
    return savedPage ? parseInt(savedPage) : 1;
  });
  const [pageSize, setPageSize] = useState(10);
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [checking , setChecking] = useState(false)
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const location = useLocation();

  const DiagnoseReport = () => {
    setReport(prevState => {

      if (prevState) {
        getDiagnosisList()
      }
      return !prevState;
    });

  };
  const MedicationReport = () => {
    navigate("/healthMonitoring/medicationReport")
  };
  const [tab, setTab] = useState(location.state?.tab || "1");
  const pillsToggle = (tabs) => {
    if (tab !== tabs) {
      setTab(tabs);
    }
  };
  useEffect(() => {
    if (tab) {
      setTab(tab); // Replace with your tab-setting logic
    }
  }, [tab]);

  useEffect(() => {
    localStorage.setItem('tablePage', currentPage.toString());
  }, [currentPage]);

  const handleclick = (userId) => {
    setDiagnose (prevState => {
            
      if (prevState) {
        const currentStoredPage = localStorage.getItem('tablePage') || 1;
    getDiagnosisList(parseInt(currentStoredPage), pageSize);
      }
      return !prevState; 
  });
   
    setSelectedData(userId)
  };
  console.log(selectedData, "dattata");

  const handlepreview = (userId) => {
    setPreview (prevState => {
            
      if (prevState) {
        const currentStoredPage = localStorage.getItem('tablePage') || 1;
    getDiagnosisList(parseInt(currentStoredPage), pageSize);
      }
      return !prevState; 
  });
   
    setSelectedData(userId)
  };
  console.log(selectedData, "dattata");

  const headings = [
    {
      id: 1,
      title: "Sl No",
      value: "SlNo",
    },
    {
      id: 2,
      title: "Cattle ID",
      value: "CattleID",
      icon: cowicon,
    },
    {
      id: 3,
      title: "Issue Type",
      value: "issuetype",
    },

    {
      id: 4,
      title: "Issue Date",
      value: "issuedate",
    },
    {
      id: 5,
      title: "Severity",
      value: "Severity",
    },
    {
      id: 6,
      title: "Status",
      value: "Status",
    },
    // {
    //   id: 7,
    //   title: "Action",
    //   value: "action",
    // },
    {
      id: 8,
      title: "Actions",
      value: "actions",
    },
  ];

  const data = [
    {
      SlNo: "01",
      CattleID: "Cattle No.1856",
      LactationNumber: "2",
      LastCalvingdate: "06-09-2024",
      Severity: "Normal",
      HeatDate: "11-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "02",
      CattleID: "Cattle No.1987",
      LactationNumber: "3",
      LastCalvingdate: "02-08-2024",
      Severity: "Normal",
      HeatDate: "12-10-2024",
      action: "View Report",
    },
    {
      SlNo: "03",
      CattleID: "Cattle No.1745",
      LactationNumber: "1",
      LastCalvingdate: "15-06-2024",
      Severity: "Major",
      HeatDate: "10-11-2024",
      action: "View Report",
    },
    {
      SlNo: "04",
      CattleID: "Cattle No.1623",
      LactationNumber: "4",
      LastCalvingdate: "20-07-2024",
      Severity: "Normal",
      HeatDate: "05-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "05",
      CattleID: "Cattle No.1456",
      LactationNumber: "2",
      LastCalvingdate: "25-05-2024",
      Severity: "Critical",
      HeatDate: "30-09-2024",
      action: "View Report",
    },
    {
      SlNo: "06",
      CattleID: "Cattle No.1543",
      LactationNumber: "3",
      LastCalvingdate: "18-08-2024",
      Severity: "Normal",
      HeatDate: "20-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "07",
      CattleID: "Cattle No.1678",
      LactationNumber: "1",
      LastCalvingdate: "01-09-2024",
      Severity: "Critical",
      HeatDate: "15-11-2024",
      action: "View Report",
    },
    {
      SlNo: "08",
      CattleID: "Cattle No.1234",
      LactationNumber: "4",
      LastCalvingdate: "12-06-2024",
      Severity: "Normal",
      HeatDate: "25-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "09",
      CattleID: "Cattle No.1098",
      LactationNumber: "3",
      LastCalvingdate: "14-08-2024",
      Severity: "Normal",
      HeatDate: "02-11-2024",
      action: "View Report",
    },
    {
      SlNo: "10",
      CattleID: "Cattle No.1999",
      LactationNumber: "2",
      LastCalvingdate: "27-07-2024",
      Severity: "Normal",
      HeatDate: "16-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "11",
      CattleID: "Cattle No.1321",
      LactationNumber: "1",
      LastCalvingdate: "03-09-2024",
      Severity: "Normal",
      HeatDate: "28-11-2024",
      action: "View Report",
    },
    {
      SlNo: "12",
      CattleID: "Cattle No.1156",
      LactationNumber: "4",
      LastCalvingdate: "30-05-2024",
      Severity: "Critical",
      HeatDate: "14-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "13",
      CattleID: "Cattle No.1425",
      LactationNumber: "3",
      LastCalvingdate: "19-08-2024",
      Severity: "Major",
      HeatDate: "01-11-2024",
      action: "Diagnose",
    },
    {
      SlNo: "14",
      CattleID: "Cattle No.1867",
      LactationNumber: "2",
      LastCalvingdate: "10-07-2024",
      Severity: "Major",
      HeatDate: "15-10-2024",
      action: "Diagnose",
    },
    {
      SlNo: "15",
      CattleID: "Cattle No.1789",
      LactationNumber: "1",
      LastCalvingdate: "22-09-2024",
      Severity: "Critical",
      HeatDate: "29-11-2024",
      action: "Diagnose",
    },
  ];
  const headingsformobile = [
    {
      id: 1,
      title: "Cattle ID",
      value: "CattleID",
      icon: cowicon,
    },
    {
      id: 2,
      title: "Issue Type",
      value: "issuetype",
    },

    {
      id: 3,
      title: "Issue Date",
      value: "issuedate",
    },
    {
      id: 4,
      title: "Severity",
      value: "Severity",
    },
    {
      id: 5,
      title: "Status",
      value: "Status",
    },

  ];

  const getDiagnosisList = async (page = currentPage, pageSize = 10) => {
    setLoading(true);
    const result = await action(API.GET_DIAGNOSIS_LIST, {
      searchkey: searchTerm,
      page: page,
      pageCount: pageSize,
      type: tab == "1" ? null : parseInt(tab) - 2,
    });
    console.log(result.response.data, "get diagnosis list");
    setLoading(false);
    // setEmployeeLeave(result.result);
    setPaginationcount(result.response.count);
    setDiagnosisList(result.response.data);
    const formattedData = result.response.data.map((item, index) => ({
      SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), // Format SlNo
      CattleID: item.cattle_id, // Adjust according to your API response structure
      issuetype: item.issue_type,
      issuedate: item.issue_date,
      Severity: item.severity,
      Status: item.status,
      need_medicine: item.need_medicine,
      medication_date: item.medicines?.medication_date || null,
      mode_of_administration: item.medicines?.mode_of_administration || null,
      // medicine_name: item.medicines?.medicine_name || null,
      // dosage: item.medicines?.dosage || null,
      // slot: item.medicines?.slot || null,
      medicines: item.medicines,
      id: item.id,
      cured_notes: item.cured_notes,
      cured_date: item.cured_date,
      remark:item.remark,
      end_date: item.end_date,
      primaryAction: item.status === "cured" ? "View Report" : "Diagnose",
      secondaryAction:"Review",
      actions:true,
      review:item.reviews
     
    }));
    const hasCuredStatus = result.response.data.some(item => item.status === "cured");
    setChecking(hasCuredStatus);
    setDiagnosisList(formattedData);
  };

  useEffect(() => {
    const currentStoredPage = localStorage.getItem('tablePage') || 1;
    getDiagnosisList();
    // console.log(diagnosisList, "diagnosisList");
  }, [tab, pageSize, currentPage, searchTerm]);

  // const filteredUserList = (diagnosisList || []).filter((item) => {
  //   const CattleID = item.CattleID || "";

  //   const issuetype = item.issuetype || "";
  //   const issuedate = item.issuedate || "";
  //   const Severity = item.Severity || "";
  //   const Status = item.Status || "";
  //   return (
  //     issuetype.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     issuedate.includes(searchTerm) ||
  //     Severity.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     CattleID.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     Status.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });
  useEffect(() => {
    if (!isInitialLoad) {
      setCurrentPage(1)
      getDiagnosisList(1, pageSize)
    } else {
      setIsInitialLoad(false)
      getDiagnosisList(currentPage, pageSize)
    }
  }, [searchTerm, tab, pageSize])

  useEffect(() => {
    if (!isInitialLoad) {
      getDiagnosisList(currentPage, pageSize)
    }
  }, [currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    getDiagnosisList(page, pageSize)
  }
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div style={{ backgroundColor: "#FDFDFD" }}>
      <FlexCol>
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="d-flex space-x-2">
            <Heading title="Cattle Health Monitoring" />
            {/* <Dropdownbtn
              title="Today"
              style={{
                backgroundColor: "#FAFAFA",
                color: "#5A5555",
                border: "1px #CCCBCB solid",
              }}
            /> */}
          </div>
          <div className="d-flex  space-x-5">
            <SearchBox
              placeholder="Search Cattle"
              className="bg-[#F8F9FB]"
              value={searchTerm}
              onSearch={setSearchTerm}
            />
            {/* <Filterbtn /> */}
            <CommonButton title=" Medication List" onClick={MedicationReport} />
            <CommonButton title=" Diagnose Cattle" onClick={DiagnoseReport} />
          </div>
        </div>
        {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
        <Nav pills className="nav-success ">
          {[
            "All",
            "Diagnosed",
            "In medication",
            "Not in medication",
            "Critical",
            "Recovered",
          ].map((tabs, index) => (
            <NavItem key={index}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: "#0A0B0A",
                  backgroundColor:
                    tab === String(index + 1) ? "#E3E3E3" : "transparent",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  textDecoration: "none", // Optional underline for active tab
                }}
                className={tab === String(index + 1) ? "active" : ""}
                onClick={() => pillsToggle(String(index + 1))}
              >
                {tabs}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {isMobile ? (
          <TableAccordion headings={headingsformobile} cattleData={diagnosisList} handleAction={handleclick} />
        ) : (
          <>
            {tab === "1" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}

            {tab === "2" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}
            {tab === "3" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}
            {tab === "4" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}
            {tab === "5" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}
            {tab === "6" && (
              <ReactTable
                headings={headings}
                data={diagnosisList}
                headingcolor="#FCEFF1"
                textcolor='#A0454E'
                handleAction={handleclick}
                pagination={paginationcount}
                fetchData={getDiagnosisList}
                handleSecondaryAction={handlepreview}
              />
            )}
          </>
        )}
        <div>
          {(diagnosisList.length > pageSize || paginationcount > 10) && (
            <div className="flex justify-end mt-4">
              <Pagination
                current={currentPage}
                total={paginationcount}
                pageSize={pageSize}
                onChange={handlePageChange}
                showSizeChanger={false}
                className="p-2 font-sans text-[#A0A0A0]"
                itemRender={(page, type, originalElement) => {
                  if (type === "page") {
                    return (
                      <span
                        className={`px-2 ${page === currentPage ? "font-bold text-black" : "font-normal"
                          }`}
                      >
                        {page}
                      </span>
                    );
                  }
                  return originalElement;
                }}
              />
            </div>
          )}
        </div>
      </FlexCol>
      <DiagnoseCattle isOpen={report} toggle={DiagnoseReport} />
      <DiagnoseCattle isOpen={Diagnose} toggle={handleclick} selectedData={selectedData} />
      <Review isOpen={preview} toggle={handlepreview} selectedData={selectedData} Api={getDiagnosisList} checking={checking} />
    </div>
  );
}

export default HealthMonitoring;
