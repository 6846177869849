import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import CommonButton from '../Common/Button'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import ReactTable from '../Common/Table'
import CreateRole from '../Drawer/CreateRole'
import CreateUser from '../Drawer/CreateUser'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useNavigate } from 'react-router'
import API, { action } from '../../Api'
import ChangePassword from '../Drawer/ChangePassword'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ViewUserDetails from '../Drawer/ViewUserDetails'
import { Pagination } from 'antd'
import { useLoader } from '../../LoaderContext'

function UserMangement() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [createuser, setCreateuser] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [userList, setUSerList] = useState([])
    const [selectedUser, setSelectedUser] = useState(null);
    const [updateuser, setUpdateuser] = useState(null)
    const [userView, setUserView] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentType, setCurrentType] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const navigate = useNavigate()
    const { setLoading } = useLoader();
    // const toggleDrawer = () => setDrawerOpen(!drawerOpen);
    // const createUser = () => {
    //     setCreateuser(!createuser)
    //     getUserList(currentPage, pageSize, currentType)
    // }
    const createUser = () => {
        setCreateuser(prevState => {
            
            if (prevState) {
                getUserList(currentPage, pageSize, currentType);
            }
            return !prevState; 
        });
    };
    
    const toggleDrawer = () => {
        navigate("/userManagement/userRole")
    }
    const changepassword = (userId) => {
        setSelectedUser(userId); // Set the selected user data
        setChangePassword(prevState => {
            
            if (prevState) {
                getUserList(currentPage, pageSize, currentType);
            }
            return !prevState; 
        });
    };

    const editdetails = (userId) => {
        console.log(userId, "kkk");

        setUpdateuser(userId);
        setCreateuser(prevState => {
            
            if (prevState) {
                getUserList(currentPage, pageSize, currentType);
            }
            return !prevState; 
        });
    }

    const userdetails = (userId) => {
        console.log(userId, "kkk");

        setUpdateuser(userId);
        setUserView(!userView)
    }
    console.log(updateuser, "selected user");

    const handleUseView = () => {

    }

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Username",
            value: "UserID",


        },
        {
            id: 3,
            title: "User Role",
            value: "Role",

        },

        {
            id: 4,
            title: "Email",
            value: "email"
        },

        {
            id: 5,
            title: "Created Date",
            value: "CreatedDate",


        },
        {
            id: 6,
            title: "Status",
            value: "Status",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]

    const data = [
        {
            SlNo: "01",
            UserID: "Farm User 1",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },
        {
            SlNo: "02",
            UserID: "Farm User 2",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },
        {
            SlNo: "03",
            UserID: "Farm User 3",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }

        },
        {
            SlNo: "04",
            UserID: "Farm User 4",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },
        {
            SlNo: "05",
            UserID: "Farm User 5",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },
        {
            SlNo: "06",
            UserID: "Farm User 6",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }
        },
        {
            SlNo: "07",
            UserID: "Farm User 7",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            Status: {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },

        {
            SlNo: "08",
            UserID: "Farm User 8",
            Role: "Supervisor",
            PhoneNumber: "7548123654",
            CreatedDate: "11-10-2024",
            "Status": {
                "value": "active",
                "toggle": true
            },
            action: {
                "value": "active",
                "dots": true
            }


        },

    ];
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
        let newType = null;

        if (tabs === "2") {
            newType = 1;
        } else if (tabs === "3") {
            newType = 0;
        }

        setTab(tabs);
        setCurrentPage(1); // Reset to first page when switching tabs
        setCurrentType(newType);
        // localStorage.setItem("userType", newType);
        getUserList(1, pageSize, newType); // Fetch data with the updated type
    };



    const handleToggle = (userId, currentStatus) => {
        console.log(currentStatus, "rrr");

        const newStatus = currentStatus === true ? false : true; // Toggle the status
        updateStatus(userId, newStatus); // Call the updateStatus function with new status
    };


    const getUserList = async (page = currentPage, pageSize = 10, type = currentType) => {
        setLoading(true);
        const result = await action(API.USER_LIST, {
            pageCount: pageSize,
            page: page,
            type: type,
            searchKey: searchTerm

        });
        setLoading(false);
        console.log(result.response.data, "get user list")
        console.log(page, "page");
        const userData = result.response.data;
        if (userData.length === 0 && page > 1) {
            // Automatically switch to the previous page
            const previousPage = page - 1;
            setCurrentPage(previousPage);
            // Recall getUserList with the previous page
            return getUserList(previousPage, pageSize, type);
          }
        // setEmployeeLeave(result.result);
        setPaginationcount(result.response.count);
        setUSerList(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'),// Format SlNo
            UserRole: item.role_name
            , // Adjust according to your API response structure
            UserID: item.name,
            Role: item.role,
            // PhoneNumber: item.mobile,
            CreatedDate: item.created_at,
            userId: item.user_id,
            email: item.email,
            Status: {
                value: item.status, // Set to true or false based on API response
                toggle: true // Set toggle to the same boolean value
            },
            action: {
                value: "active", // Adjust according to your logic
                dots: true // Assuming dots should always be true
            }
        }))

        setUSerList(formattedData);
    };



    const updateStatus = async (userId, newStatus) => {
        try {
            const result = await action(API.UPDATE_STATUS, {
                id: userId,
                status: newStatus
            });
            if (result.status && result.status.code === 200) {
                setUSerList(prevList =>
                    prevList.map(user =>
                        user.userId === userId ? { ...user, Status: { ...user.Status, value: newStatus } } : user
                    )
                );
                let userType = null;
                if (tab === "2") userType = 1; // Active users
                else if (tab === "3") userType = 0; // Inactive users

                toast.success("Status updated successfully");
                getUserList(currentPage, pageSize, userType)
            } else {
                toast.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("Error updating status");
        }
    };






    useEffect(() => {
        getUserList()
        console.log(userList, 'userlist');

    }, [pageSize, currentPage,])
    // const filteredUserList = (userList || []).filter(user => {
    //     const userId = user.UserID || ""; // Prevent null error
    //     const phoneNumber = user.PhoneNumber || ""; // Prevent null error
    //     const role = user.Role || "";
    //     return (
    //         userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         phoneNumber.includes(searchTerm) ||
    //         role.toLowerCase().includes(searchTerm.toLowerCase())
    //     )
    // });
    useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getUserList(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getUserList(currentPage, pageSize)
        }
    }, [searchTerm, tab, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getUserList(currentPage, pageSize)
        }
    }, [currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page)
        getUserList(page, pageSize)
    }
    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='User Management' />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        <div className="flex space-x-5">
                            <SearchBox placeholder="Search User" className="bg-[#F8F9FB]" value={searchTerm}
                                onSearch={setSearchTerm} />
                            {/* <Filterbtn /> */}
                        </div>
                        <div className="flex space-x-5 sm:mt-4">
                            <CommonButton title="User Role" onClick={toggleDrawer} className="border border-[#16A34A] text-[#16A34A] bg-none" />
                            <CommonButton title="Create User" onClick={createUser} />
                        </div>
                    </div>
                </div>
                <Nav pills className="nav-success ">
                    {["All", "Active", "Inactive"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                {tab === "1" && <ReactTable headings={headings} data={userList} handlePassword={changepassword} handleToggle={handleToggle} handleEDit={editdetails} handleUseView={userdetails} pagination={paginationcount}
                    fetchData={getUserList} headingcolor="#E9F9EF" textcolor='#188A42' />}
                {tab === "2" && <ReactTable headings={headings} data={userList} handlePassword={changepassword} handleToggle={handleToggle} handleEDit={editdetails} handleUseView={userdetails} pagination={paginationcount}
                    fetchData={getUserList} headingcolor="#E9F9EF" textcolor='#188A42' />}
                {tab === "3" && <ReactTable headings={headings} data={userList} handlePassword={changepassword} handleToggle={handleToggle} handleEDit={editdetails} handleUseView={userdetails} pagination={paginationcount}
                    fetchData={getUserList} headingcolor="#E9F9EF" textcolor='#188A42' />}
                {/* {tab === "2" && <ReactTable headings={headings} data={Inseminated_Data} />}
        {tab === "3" && <ReactTable headings={headings} data={HeatedCattle_Data} />}
        {tab === "4" && <ReactTable headings={headings} data={Pregnant_Data} />}
                <ReactTable headings={headings} data={data} headingcolor="#E3E3E3" /> */}
                {(userList.length > pageSize || paginationcount > 10) && (
                    <div className="flex justify-end mt-4">
                        <Pagination
                            current={currentPage}
                            total={paginationcount}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            className="p-2 font-sans text-[#A0A0A0]"
                            itemRender={(page, type, originalElement) => {
                                if (type === "page") {
                                  return (
                                    <span
                                      className={`px-2 ${
                                        page === currentPage ? "font-bold text-black" : "font-normal"
                                      }`}
                                    >
                                      {page}
                                    </span>
                                  );
                                }
                                return originalElement;
                              }}
                        />
                    </div>
                )}
            </FlexCol>
            <CreateRole isOpen={drawerOpen} toggle={toggleDrawer} />
            <CreateUser isOpen={createuser} toggle={createUser} selectedUserId={updateuser} />
            <ChangePassword isOpen={changePassword} toggle={changepassword} user={selectedUser} />
            <ViewUserDetails isOpen={userView} toggle={userdetails} userDetails={updateuser} handleUseView={handleUseView} />
            <ToastContainer
                position={"top-center"}
                autoClose={2000}
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
            />
        </div>
    )
}

export default UserMangement