import React, { useState } from 'react';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import { Flex } from 'antd';
import AddPregnancy from './AddPregnancy';
import Label from '../Common/Label';
import CommonButton from '../Common/Button';

function InseminationRecordView({ isOpen, toggle, selectedData, refresh }) {
    console.log(selectedData, "lklk");

    const [activeTab, setActiveTab] = useState('Natural');
    const [pregnancy, setPregnancy] = useState(false);
    const [isReInseminated, setIsReInseminated] = useState(false);
    const [loading, setLoading] = useState(false);
    const tabs = ['Natural', 'Artificial', 'double_ai'];

    const handleClick = () => {
        setPregnancy(!pregnancy);
        toggle();
        refresh();
    };

    const handleclose = () => {
        setPregnancy(!pregnancy);
        refresh();
    };

    const handleReInseminateClick = () => {
        setIsReInseminated(true);
    };

    const toggle1 = () => {
        setIsReInseminated(false);
        toggle();
    };

    const BreedingCycleInfo = () => (
        <div className="p-2 bg-white">


            <div className="mb-6">
                <h3 className="text-green-500 text-sm ">Insemination Status</h3>
                <p className='text-sm space-y-1 text-[#0A0B0A] font-medium'>{selectedData?.status || "Pregnant"}</p>
                <div className="text-sm space-y-1">
                    <div>{selectedData?.status || "Pregnant"}</div>
                    <div>Last Insemination Date</div>
                    <div>{selectedData?.insemination_date || "04 Oct 2024"}</div>
                    <div>Cattle</div>
                    <div>{selectedData?.cattle_id || "1845"}</div>
                    <div className="text-gray-500">{selectedData?.description || "Cattle is pregnant"}</div>
                </div>
            </div>

            <div className="mb-6">
                <h3 className="text-green-500 text-sm mb-2">Re-insemination Type</h3>
                <div className="text-sm space-y-1">
                    <div>{selectedData?.reinsemination_type || "Artificial"}</div>
                    <div>Insemination Date</div>
                    <div>{selectedData?.reinsemination_date || "04 Oct 2024"}</div>
                    <div>Cattle</div>
                    <div>{selectedData?.cattle_id || "1845"}</div>
                    <div className="text-gray-500">{selectedData?.reinsemination_description || "Cattle is Perfect/y Ok for pregnancy"}</div>
                </div>
            </div>

            <div className="mb-6">
                <h3 className="text-green-500 text-sm mb-2">Insemination Type</h3>
                <div className="text-sm space-y-1">
                    <div>{selectedData?.insemination_type || "Artificial"}</div>
                    <div>Insemination Date</div>
                    <div>{selectedData?.insemination_date || "04 Oct 2024"}</div>
                    <div>Cattle</div>
                    <div>{selectedData?.cattle_id || "1845"}</div>
                    <div className="text-gray-500">{selectedData?.insemination_description || "Cattle is Perfect/y Ok for pregnancy"}</div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Offcanvas
                isOpen={isOpen}
                toggle={toggle1}
                direction="end"
                className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
            >
                <FlexCol className="p-4">
                    <OffcanvasHeader>
                        <div>
                            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                                Record Insemination
                            </div>
                            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                                Add Insemination Details
                            </div>
                        </div>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <FlexCol className="" gap={20}>
                            <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                <FormGroup>
                                    <Label title=' Cattle ID' value={selectedData?.[0]?.CattleID || '--'} />

                                   
                                    <Label title='Insemination Type' value={selectedData?.[0]?.InseminationType || '--'} />
                                    <Label title='InseminationDate' value={selectedData?.[0]?.InseminationDate
                                        || '--'} />
                                    <Label title='ExpectedPregnancy' value={selectedData?.[0]?.ExpectedPregnancy

                                        || '--'} />
                                   
                                </FormGroup>
                               
                        <CommonButton
                            className="w-full"
                            style={{
                                fontSize: '12px',
                                backgroundColor: 'transparent',
                                color: '#726C6C',
                                border: '1px #CCCBCB solid',
                            }}
                            onClick={toggle}
                            outline
                            title="Discard"
                            disabled={loading}
                        />
                    
                            </Flex>
                        </FlexCol>
                    </OffcanvasBody>
                </FlexCol>
            </Offcanvas>
            
        </>
    );
}

export default InseminationRecordView;