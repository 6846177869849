import React, { useEffect, useState } from 'react';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import { Flex, notification } from 'antd';
import DateSelect from '../Common/DateSelector';
import FormInput from '../Common/FormInput';
import TextArea from '../Common/TextArea';
import CommonButton from '../Common/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API, { action } from '../../Api';
import Label from '../Common/Label';

export default function RecordMedication({ isOpen, toggle, selectedData }) {
    console.log(selectedData, "selecteddata");
    const isDisabled = !!selectedData?.[0]?.recorded_notes;
    const [activeTab, setActiveTab] = useState(selectedData?.[0]?.slot || "");
    const tabs = ['Morning', 'Noon', 'Evening', 'All'];
    const [loading, setLoading] = useState(false);

    // Formik setup
    const formik = useFormik({
        initialValues: {

            description: '',
            medicineName:'',
            dosage:''
        },
        validationSchema: Yup.object({

            description: Yup.string().required('Description is required'),
            medicineName: Yup.string().required('Medicine name is required'),
            dosage: Yup.string().required('Dosage is required'),
        }),
        onSubmit: async (values) => {
            console.log('Form Submitted:', values);
            setLoading(true);
            try {

                const result = await action(API.UPDATE_MEDICATION_STATUS, {
                    id: selectedData?.[0]?.id,
                    recorded_notes: values.description,
                    medicine_name :values.medicineName,
                    dosage: values.dosage
                });
                notification.success({ message: result.response.message, placement: "top", });
                // }
                // Optionally reset the form or close the offcanvas
                formik.resetForm();
                customToggle(); // Close the offcanvas
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });

    const customToggle = () => {
        formik.resetForm();
        toggle();
    };

    useEffect(() => {
        if (selectedData?.[0]) {
            formik.setFieldValue('medicineName', selectedData[0].medicine_name || '');
            formik.setFieldValue('dosage', selectedData[0].dosage || '');
        }
    }, [selectedData]);

    return (
        <Offcanvas isOpen={isOpen} toggle={customToggle} direction="end" className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>

                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                          {!isDisabled? 'Record Medication' : 'Medication Report'}
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                        {!isDisabled? 'Add dosage and treatment details.' : 'Dosage and treatment details.'}
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            {!isDisabled ? (
                                <FlexCol className="" gap={20}>
                                    <Flex className="grid grid-cols-1 gap-[30px] items-center">

                                        <>
                                            <FormInput
                                                title="Medicine Name"
                                                placeholder="Enter Medicine name"
                                                // className='"text-green-600 font-semibold font-plus-jakarta-sans'
                                                name="medicineName"
                                                value={formik.values.medicineName }
                                                change={(e) => formik.setFieldValue('medicineName', e)}
                                                error={formik.touched.medicineName && formik.errors.medicineName}
                                                required={true}
                                                // disabled={true}

                                            />

                                            { /* <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                      Medicine Slot
                    </div>
                    <div className="flex gap-2">
                      {tabs.map((tab) => (
                        <div
                          key={tab}
                          onClick={() => setActiveTab(tab)}
                          className={`p-2 rounded-lg cursor-pointer ${
                            activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'
                          } flex justify-center items-center`}
                        >
                          <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words`}>
                            {tab}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */ }

                                            <FormInput
                                                title="Dosage"
                                                placeholder="Enter Dosage"
                                                name="dosage"
                                                value={formik.values.dosage}
                                                change={(e) => formik.setFieldValue('dosage', e)}
                                                error={formik.touched.dosage && formik.errors.dosage}
                                                required={true}
                                                // disabled={true}
                                            />
                                            <DateSelect
                                                title="Medication Date"
                                                placeholder="04 Oct 2024"
                                                name="medicationDate"
                                                value={selectedData?.[0]?.medication_date || ""}
                                                change={(e) => formik.setFieldValue('medicationDate', e)}

                                                disabled={true}
                                            />
                                            <FormInput
                                                title="Cattle"
                                                placeholder="1865"
                                                name="cattle"
                                                value={selectedData?.[0]?.CattleID || ""}
                                                change={(e) => formik.setFieldValue('cattle', e)}

                                                disabled={true}
                                            />
                                            {/* <FormInput
                    title="Health Status"
                    placeholder="Critical"
                    name="healthStatus"
                    value={formik.values.healthStatus}
                    change={(e) => formik.setFieldValue('healthStatus', e)}
                    error={formik.errors.healthStatus}
                  />
                  <DateSelect
                    title="Next Medication Date"
                    placeholder="04 Oct 2024"
                    name="nextMedicationDate"
                    value={formik.values.nextMedicationDate}
                    change={(e) => formik.setFieldValue('nextMedicationDate', e)}
                    error={formik.errors.nextMedicationDate}
                  />  */}
                                            <TextArea
                                                title="Description"
                                                placeholder="Enter Description"
                                                name="description"
                                                value={formik.values.description || selectedData?.[0]?.recorded_notes}
                                                change={(e) => formik.setFieldValue('description', e)}
                                                error={formik.touched.description && formik.errors.description}
                                                disabled={isDisabled}
                                                required={true}
                                            />
                                        </>
                                    </Flex>
                                </FlexCol>
                            ) : (
                                <>

                                    <Label title='Medicine Name' value={selectedData?.[0]?.medicine_name || ''} />

                                    <Label title='Dosage' value={selectedData?.[0]?.dosage || ''} />
                                    <Label title='Medication Date' value={selectedData?.[0]?.medication_date || ''} />
                                    <Label title='Cattle' value={selectedData?.[0]?.CattleID
                                        || ''} />
                                    <Label title='Description' value={selectedData?.[0]?.recorded_notes
                                        || ''} />
                                </>
                            )}

                        </FormGroup>
                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{
                                    fontSize: '12px',
                                    backgroundColor: 'none',
                                    color: '#726C6C',
                                    border: '1px #CCCBCB solid',
                                    marginRight: '8px',
                                }}
                                onClick={customToggle}
                                outline
                                title="Discard"
                                disabled={loading}
                            />
                            {!isDisabled && (
                                <CommonButton type="submit" title="Save" style={{ fontSize: '12px' }} disabled={loading} />
                            )}
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}
