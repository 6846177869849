import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import ReportMonitoringComparisonGraph from '../Common/ReportMonitoringComparisonGraph'
import Cards from '../Common/Cards'
import API, { action } from '../../Api'
import { useNavigate } from 'react-router'

function MilkingReport() {
    const [milkingReport, setMilkingReport] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [expectedData, setExpectedData] = useState([])
    const navigate = useNavigate()

    const handleClick=()=>{
        navigate("/reportsMonitoring/Milkreport")
    }
    
    const getAllMonthNames = () => {
        return Array.from({ length: 12 }, (_, i) => 
            new Date(2024, i).toLocaleString('default', { month: 'short' })
        );
    };

    const getMilkingReport = async () => {
        const result = await action(API.GET_MILKING_REPORT, {
            start_Date: null,
            end_Date: null,
            filterType: null,
        });
        const responseData = result.response.data;
        setMilkingReport(responseData);

        // Get all month names
        const allMonths = getAllMonthNames();
        
        // Create a map of existing data
        const monthDataMap = new Map(
            responseData?.monthly_report?.map(item => [
                item.month - 1,  // Convert to 0-based index
                item.totalLiters
            ])
        );

        // Create actual data array with all months
        const actualData = allMonths.map((monthName, index) => ({
            name: monthName,
            value: monthDataMap.get(index) || 0
        }));

        // Create expected data array with all months
        const expectedValues = allMonths.map(monthName => ({
            name: monthName,
            value:null // Set your expected value here
        }));

        setCurrentData(actualData);
        setExpectedData(expectedValues);
    };

    useEffect(() => {
        getMilkingReport();
    }, []);

    const cardClick =(item)=>{
        if (item.label === 'Total Milking' ) {
        navigate("/reportsMonitoring/Milkreport")
       }else{
        navigate("")
       }
       }

    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter:milkingReport.milking_report?.total_cattle || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Milking",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "-3.57",
            counter: milkingReport.milking_report?.milking_count || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Dry",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter: milkingReport.milking_report?.dry_cattle_count || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Pregnant",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: milkingReport.milking_report?.pregnent_count || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]

    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Milking Report' />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        <div className="flex space-x-5">
                            {/* <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" /> */}
                            {/* <Filterbtn /> */}
                        </div>
                        <div className="flex space-x-5 sm:mt-4">
                            {/* <CommonButton title="Export" /> */}
                        </div>
                    </div>
                </div>
                <ReportMonitoringComparisonGraph 
                    // title="Comparison"   
                    CategoryName="Milked" 
                    CategoryName2="Expected" 
                    currentData={currentData}
                    availableData={expectedData}
                />
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Milking Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#0EA5E9] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        {/* <CommonButton 
                            title="View All Reports" 
                            style={{ 
                                backgroundColor: "none", 
                                color: "#0EA5E9", 
                                border: "1px #0EA5E9 solid", 
                            }} 
                            onClick={handleClick}
                        /> */}
                    </div>
                </div>
                <Cards data={data} cardClick={cardClick} />
            </FlexCol>
        </div>
    )
}

export default MilkingReport