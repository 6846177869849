import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { CardBody } from 'reactstrap';

const CustomCalendar = ({ vaccinationData }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  // Initialize an empty events object
  const [events, setEvents] = useState({});

  // Format the vaccination data into a structured event map
  useEffect(() => {
    if (!vaccinationData || !vaccinationData.vaccination_data) return;

    const eventMap = {};

    // Process vaccination data into eventMap if available
    vaccinationData.vaccination_data.forEach((event) => {
      const date = event.vaccination_date;
      if (date) {
        eventMap[date] = { type: 'vaccination', count: event.count };
      }
    });

    // Process delivery data if available
    if (vaccinationData.delivery_data) {
      vaccinationData.delivery_data.forEach((event) => {
        const date = event.delivery_date;
        if (date && date !== null) {
          eventMap[date] = { type: 'delivery', count: event.count };
        }
      });
    }

    // Update the events state with parsed data
    setEvents(eventMap);

  }, [vaccinationData]);

  const getEventStyle = (date) => {
    const eventKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(date).padStart(2, '0')}`;
    if (!events[eventKey]) return '';

    const eventType = events[eventKey]?.type;
    switch (eventType) {
      case 'delivery':
        return 'border-2 border-amber-400 '; // Delivery events
      case 'vaccination':
        return 'border-2 border-green-400'; // Vaccination events
      default:
        return 'border-2 border-gray-200'; // Default for other event types
    }
  };

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const getDaysInPreviousMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
  };

  const navigateMonth = (direction) => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const newDate = new Date(currentYear, currentMonth + direction, 1);
    setCurrentDate(newDate);
  };

  const formatMonth = (date) => {
    return new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date);
  };

  // Generate calendar data
  const firstDayOfMonth = getFirstDayOfMonth(currentDate);
  const daysInMonth = getDaysInMonth(currentDate);
  const daysInPrevMonth = getDaysInPreviousMonth(currentDate);

  // Previous month days
  const prevMonthDays = Array.from({ length: firstDayOfMonth }, (_, i) => ({
    date: daysInPrevMonth - firstDayOfMonth + i + 1,
    isPrevMonth: true
  }));

  // Current month days
  const currentMonthDays = Array.from({ length: daysInMonth }, (_, i) => ({
    date: i + 1,
    isCurrentMonth: true
  }));

  // Next month days
  const remainingDays = 42 - (prevMonthDays.length + currentMonthDays.length); // 42 = 6 rows * 7 days
  const nextMonthDays = Array.from({ length: remainingDays }, (_, i) => ({
    date: i + 1,
    isNextMonth: true
  }));

  const allDays = [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];

  const calendarStyle = {
    fontFamily: "'Plus Jakarta Sans', sans-serif"
  };

  return (
    <React.Fragment>
      <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300" style={calendarStyle}>
        <CardBody className="p-0 pb-2">
          <div  class="h-[410px] 2xl:h-[457px] overflow-y-auto">
            {/* Header */}
            <div className="flex items-center justify-between mb-4 mt-2">
              <button onClick={() => navigateMonth(-1)} className="p-1 hover:bg-gray-100 rounded-full">
                <ChevronLeft className="w-5 h-5 text-gray-400" />
              </button>
              <h2
                className="2xl:text-[20px] text-[14px]"
                style={{
                  color: "#495057",
                  fontFamily: "Plus Jakarta Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                {formatMonth(currentDate)}
              </h2>
              <button onClick={() => navigateMonth(1)} className="p-1 hover:bg-gray-100 rounded-full">
                <ChevronRight className="w-5 h-5 text-gray-400" />
              </button>
            </div>

            {/* Calendar Grid */}
            <div className="grid grid-cols-7 gap-1">
              {/* Day labels */}
              {days.map((day) => (
                <div key={day} className="text-center text-sm text-gray-500 py-2 font-plus-jakarta-sans">
                  {day}
                </div>
              ))}

              {/* Calendar days */}
              {allDays.map((day, index) => (
                <div
                  key={`${day.date}-${index}`}
                  className={`text-center text-[#0A0B0A] py-2 rounded-lg cursor-pointer hover:bg-gray-50 font-plus-jakarta-sans 2xl:text-[20px] text-[14px]
                    ${day.isCurrentMonth ? getEventStyle(day.date) : 'text-gray-300'}
                    ${day.isCurrentMonth ? '' : 'pointer-events-none'}`}
                  style={calendarStyle}
                >
                  {day.date}
                </div>
              ))}
            </div>

            {/* Legend */}
            <div className="mt-4 flex gap-4 text-sm text-gray-500 flex-wrap ml-4">
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                <span>Vaccination</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-amber-400"></div>
                <span>Delivery</span>
              </div>
            </div>
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default CustomCalendar;
