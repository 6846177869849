import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import FlexCol from "../Common/FlexCol";
import CommonButton from "../Common/Button";
import FormInput from "../Common/FormInput";
import { Flex, notification } from "antd";
import TextArea from "../Common/TextArea";
import DateSelect from "../Common/DateSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import API, { action } from "../../Api";
import DropdownButton from "../Common/DropdownButton";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Label from "../Common/Label";

function HealthMonitoringFormView({ isOpen, toggle, selectedData }) {
  console.log(selectedData, "selectedData");
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);

  const [medicines, setMedicines] = useState([
    { name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" },
  ]); // Change slot to an array
  const [cattleid, setCattleid] = useState([]);
  const tabs = ["Foot & Mouth", "Other"];
  const medicineNeededOptions = ["Yes", "No"];
  const medicineSlotOptions = ["Any", "Morning", "Noon", "Evening"];
  const isDisabled = !!selectedData;
  const isdisabled = !!selectedData?.[0]?.cured_notes;
  console.log(selectedData?.[0]?.cured_notes, "cured");
  const id = localStorage.getItem('userid');
  const handleAddMedicine = () => {
    const newMedicine = {
      name: "",
      dosage: "",
      slots: ["Any"],
      startDate: "",
      endDate: "",
    }; // Change slot to an array
    setMedicines([...medicines, newMedicine]);
    formik.setFieldValue("medicines", [...medicines, newMedicine]);
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };

  const handleSlotChange = (index, slot) => {
    const updatedMedicines = [...medicines];
    let currentSlots = updatedMedicines[index].slots || [];

    if (slot === "Any") {
      // If "Any" is selected, clear all other slots
      currentSlots = currentSlots.includes("Any") ? [] : ["Any"];
    } else {
      // If another slot is selected, remove "Any" and toggle the current slot
      currentSlots = currentSlots.filter((s) => s !== "Any");
      if (currentSlots.includes(slot)) {
        currentSlots = currentSlots.filter((s) => s !== slot); // Deselect the slot
      } else {
        currentSlots.push(slot); // Select the slot
      }
    }
    if (currentSlots.length === 0) {
      currentSlots = ["Any"];
    }
    updatedMedicines[index].slots = currentSlots;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };


  const formik = useFormik({
    initialValues: {
      issueType: "Foot & Mouth",
      issueDate: "",
      cattleId: "",
      severity: "normal",
      medicineNeeded: "No",
      modeOfAdministration: "",
      medicines: medicines,
      other: "",
    },
    validationSchema: Yup.object({
      issueType: Yup.string().required("Issue type is required"),
      issueDate: Yup.string().required("Issue date is required"),
      cattleId: Yup.string().required("Cattle id is required"),
      severity: Yup.string().required("Severity is required"),
      medicineNeeded: Yup.string().required("Required"),
      other: Yup.string().when('issueType', {
        is: 'Other',
        then: () => Yup.string().required('Other issue is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      // modeOfAdministration: Yup.string().required("Required").max(200, "Description is too long"),
      medicines: Yup.array().when('medicineNeeded', {
        is: 'Yes',
        then: () => Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required('Medicine name is required'),
            dosage: Yup.string()
              .required('Dosage is required'),
            // slots: Yup.string()
            //   .required('Medicine slot is required'),
            startDate: Yup.string()
              .required('Start date is required'),
            endDate: Yup.string()
              .required('End date is required')
              .test('date-validation', 'End date must be after start date',
                function (value) {
                  const { startDate } = this.parent;
                  if (!startDate || !value) return true;
                  return new Date(value) > new Date(startDate);
                }),
          })
        ).min(1, 'At least one medicine is required'),
        otherwise: () => Yup.array()
      }),
    }),
    onSubmit: async (values) => {
      // console.log(values, "values");
      if (selectedData && selectedData.length > 0) {
        formik2.handleSubmit(); // Submit formik2
      } else {
        setLoading(true);
        try {

          // Map the medicines to the API format
          const formattedMedicines = values.medicines.map((medicine) => ({
            start_date: medicine.startDate,
            end_date: medicine.endDate,
            mode_of_administration: values.modeOfAdministration, // Added from form values
            medicine_name: medicine.name,
            dosage: medicine.dosage,
            //   slot: medicine.slots.join(", "),
            slot: medicine.slots,
          }));

          const result = await action(API.ADD_DIAGNOSIS, {
            issue_type: values.issueType,
            issue_date: values.issueDate,
            cattle_id: values.cattleId,
            severity: values.severity,
            need_medicine: values.medicineNeeded,
            created_by: id, // Replace with actual user ID if available
            medicines: formattedMedicines,
            other_issue: values.other
          });
          notification.success({
            message: result?.response?.message,
            placement: "top",
          });
          formik.resetForm();
          setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
          toggle(); // Close the offcanvas
        } catch (error) {
          console.error("Error creating diagnosis:", error);
          notification.error({
            message: error.response?.data?.response?.message,
            placement: "top",
          });
        } finally {
          setLoading(false); // Stop loading
        }
      }
    },
  });

  console.log(formik.values, "-------------------");

  const customToggle = () => {
    // setId(null);
    formik.resetForm(); // Set id to null
    setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
    toggle(); // Call the original toggle function
  };
  const severityOptions = [
    { value: "critical", label: "Critical" },
    { value: "normal", label: "Normal" },
    { value: "major", label: "Major" },
  ];

  const getCattleId = async () => {
    try {
      const result = await action(API.GET_CATTLE_ID, {
        searchKey: searchKey,
        page: 1,
        pageCount: 60,
      });
      const cattleID = result.response.data.map((id) => ({
        label: id.cattle_id, // Display name
        value: id.cattle_id, // Unique identifier
      }));
      setCattleid(cattleID); // Set data with both label and value

      console.log(cattleID); // Check if data is in correct format
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    getCattleId();
    console.log(cattleid, "cattleid");
  }, [searchKey]);


  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      // Set basic fields
      formik.setFieldValue("issueDate", selectedData[0]?.issue_date);
      formik.setFieldValue("severity", selectedData[0]?.severity);
      formik.setFieldValue("cattleId", selectedData[0]?.CattleID);
      formik.setFieldValue("issueType", selectedData[0]?.issue_type);
      formik.setFieldValue("medicineNeeded", selectedData[0]?.need_medicine);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("slot", selectedData[0]?.slot);
      formik2.setFieldValue("curednotes", selectedData[0]?.cured_notes);
      formik2.setFieldValue("cureddate", selectedData[0]?.cured_date);

      // Set medicines
      const medicinesFromData = selectedData.map((data) => ({
        name: data?.medicine_name || "",
        dosage: data?.dosage || "",
        slots: data?.slots || [],
        startDate: data?.medication_date || "",
        endDate: data?.end_date || "",
      }));
      setMedicines(medicinesFromData);
      formik.setFieldValue("medicines", medicinesFromData);

      console.log(selectedData[0]?.CattleID, "Selected Data ID");
    }
  }, [selectedData]);

  console.log(selectedData, "hhh");



  const formik2 = useFormik({
    initialValues: {
      id: "",
      curednotes: "",
      cureddate: "",
    },
    validationSchema: Yup.object({
      curednotes: Yup.string().required("Cured Note is required"),
      cureddate: Yup.string().required("Cured Date is required"),

    }),
    onSubmit: async (values) => {
      setLoading(true);
      console.log(values, "values");
      try {


        const result = await action(API.UPDATE_DIAGNOSIS_STATUS, {
          id: selectedData[0]?.id,
          cured_notes: values.curednotes,
          cured_date: values.cureddate,

        });
        notification.success({
          message: result?.response?.message,
          placement: "top",
        });
        formik2.resetForm();
        setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
        toggle(); // Close the offcanvas
      } catch (error) {
        console.error("Error creating diagnosis:", error);
        notification.error({
          message: error.response?.data?.response?.message,
          placement: "top",
        });
        // Handle the error as needed
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });


  const handleDeleteMedicine = (indexToDelete) => {
    console.log(indexToDelete, "index");

    const updatedMedicines = medicines.filter((_, index) => index !== indexToDelete);
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };

  const handleIssueTypeChange = (newIssueType) => {
    if (!isDisabled) {
      // Reset form but preserve the new issue type
      formik.resetForm({
        values: {
          ...formik.initialValues,
          issueType: newIssueType,
        }
      });
      // Reset medicines state
      setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
    }
  };

  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={customToggle}
      direction="end"
      className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
    >
      <FlexCol className="p-4">
        <OffcanvasHeader>
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              {isDisabled ? "Diagnose Report" : "Diagnose Cattle"}
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">

              {isDisabled ? "Diagnose Details and Summary" : " Enter symptoms and treatment."}
            </div>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              
                <>
                  <Label title='Issue Type' value={formik.values.issueType || ''} />

                  <Label title='Issue Date' value={formik.values.issueDate || ''} />
                  <Label title='Cattle ID' value={formik.values.cattleId || ''} />
                  <Label title='Severity' value={formik.values.severity
                    || ''} />
                  <Label title='Cattle' value={formik.values.cattleId
                    || ''} />
                  {formik.values.medicines.map((medicine, index) => {
                    // console.log(formik?.values?.calves?.length, "length");
                    return (
                      <div key={index}>
                        <Label title='Medicine Name' value={medicine.name || '--'} />
                        <Label title='Dosage (mg/bwt)' value={medicine.dosage || '--'} />
                        <Label title='Start Date' value={medicine.startDate || '--'} />
                        <Label title='End Date' value={medicine.endDate || '--'} />
                        {/* {index < formik.values.calves.length - 1 && <hr />} */}
                      </div>
                    );
                  })}
                  <Label title='Mode of Administration' value={formik.values.modeOfAdministration || '--'} />
                  <Label title='Cured Date' value={formik2.values.cureddate || '--'} />
                  <Label title='Cured Notes' value={formik2.values.curednotes || '--'} />
                </>
             
            </FormGroup>
            <Col className="flex justify-end mt-5 gap-2">
            
              <CommonButton title="Cancel" type="button" onClick={toggle} disabled={loading} />
            </Col>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default HealthMonitoringFormView;
