import React from 'react'
import nodata from '../../assets/Images/Tasknodata.png'
function NoDataComponent() {
  return (

    <div className="flex flex-col items-center justify-center  w-full bg-yellow-50/50 rounded-lg border-[#FDBF26] border-1 ">

      <img className='w-36 h-36 mt-4 items-center' src={nodata}></img>

      <div className="text-center">
        <h3 className="text-gray-800 text-lg font-medium  font-plus-jakarta-sans">All caught up!</h3>
        <p className="text-gray-600 text-sm font-plus-jakarta-sans">Nothing left to tackle—time to take a breather.</p>
      </div>
    </div>
  );
};

export default NoDataComponent