import React from 'react'
import nodata from '../../assets/Images/nodata.png'
function TableNodata() {
  return (
 
<div className="flex flex-col items-center justify-center  w-full ">
<div className="w-48 h-48 relative ">
<img src={nodata}></img>
</div>
<div className="text-center">
  <h3 className="text-[#151413] text-lg font-medium mb-1 font-plus-jakarta-sans">Nothing Here!</h3>
  <p className="text-[#898483] text-xs font-plus-jakarta-sans">Oops! This session is empty for now. Start adding data <br/> to see it populate!</p>
</div>
</div>
);
};

export default TableNodata