import React from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const FileUploader = ({ onChange, className = "", title = "", onDelete }) => {
  const uploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error(`${file.name} is not an image file`);
        return Upload.LIST_IGNORE;
      }
      // Directly call the parent handler with the file
      onChange?.(file);
      return false; // Prevent automatic upload
    },
    onRemove: (file) => {
      // Call the parent's onDelete handler when file is removed
      onDelete?.(file);
    },
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {title && (
        <label className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
          {title}
        </label>
      )}
      <Dragger {...uploadProps}>
        <p className="font-plus-jakarta-sans text-[12px]">
          Select an image to upload. Supports single image upload.
        </p>
        <p className="font-plus-jakarta-sans text-[12px]">Supported formats:</p>
        <p className="font-plus-jakarta-sans text-[12px]">
          jpeg, jpg, png, bmp.
        </p>
      </Dragger>
    </div>
  );
};

export default FileUploader;
