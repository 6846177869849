import React from 'react';

const LoadingSection = () => {
  return (
    <div className="w-full">
      {/* Two cards in one row */}
      <div className="flex gap-4 mb-8">
        {[1, 2].map((item) => (
          <div 
            key={item} 
            className="flex-1 bg-white rounded-lg p-4 shadow-sm animate-pulse"
          >
            <div className="flex justify-between items-center mb-2">
              <div className="h-6 bg-gray-200 rounded w-1/3"></div>
              <div className="h-6 bg-gray-200 rounded w-16"></div>
            </div>
            <div className="h-10 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-6 bg-gray-200 rounded w-20"></div>
          </div>
        ))}
      </div>

      {/* Chart section below */}
      <div className="bg-white rounded-lg p-6 shadow-sm animate-pulse">
        <div className="flex justify-between items-center mb-6">
          <div>
            <div className="h-5 bg-gray-200 rounded w-32 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-64"></div>
          </div>
          <div className="h-9 bg-gray-200 rounded w-28"></div>
        </div>
        
        {/* Chart skeleton */}
        <div className="h-64 flex items-end justify-between gap-4 mb-4 pt-6">
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <div key={item} className="w-full flex flex-col items-center gap-2">
              <div 
                className="bg-gray-200 rounded-t w-full"
                style={{
                  height: `${Math.random() * 80 + 20}%`
                }}
              ></div>
              <div className="h-4 bg-gray-200 rounded w-12"></div>
            </div>
          ))}
        </div>
        
        {/* Legend skeleton */}
        <div className="flex justify-center gap-6 mt-4">
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-16"></div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-16"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSection;