import React from "react";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const DateFilter = ({
    change = () => {},
    className,
    pickerType = "date",
    dateFormat = "YYYY-MM-DD",
    value = null,
    title = "",
    description = "",
    error = "",
    required = false,
    placeholder = "",
    placement = "",
    joiningDate,
    defaultPickerValue = null,
    dateofBirth = false,
    hideYear = false,
    fromDate,
    maxdate = false,
    disabled = false,
    disableBeforeDate = ''
}) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 1439 });

    return (
        <div className="date-filter w-full">
            <DatePicker
                value={value ? dayjs(value, dateFormat) : null}
                picker={pickerType}
                format={dateFormat}
                onChange={(date, dateString) => {
                    change(dateString);
                }}
                status={error ? "error" : ""}
                size={isSmallScreen ? "default" : "large"}
                className={`w-full  ${error ? "border-rose-400" : ""} ${className || ""}`}
                style={{
                    width: '100%',
                    ...(error ? {
                        boxShadow: "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    } : {})
                }}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    );
};

export default DateFilter;