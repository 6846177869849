import React, { useEffect, useState } from 'react'
import MultiSelector from '../Common/MultiSelector'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex, notification } from 'antd'
import TextArea from '../Common/TextArea'
import DropdownButton from '../Common/DropdownButton'
import CommonButton from '../Common/Button'
import API, { action } from '../../Api'
import FormInput from '../Common/FormInput'
import { useFormik } from 'formik'
import * as Yup from "yup";
import Label from '../Common/Label'

function AddCulling({ isOpen, toggle, data }) {
    console.log(data,"adata");
    
    const [cattleid, setCattleid] = useState([])
    const [searchKey, setSearchKey] = useState('');
    const [loading, setLoading] = useState(false);
    const options = [{ label: "Disbursal", value: "disbursal" }, { label: "Other", value: "other" }]

    const getCattleId = async () => {
        try {
            const result = await action(API.GET_CATTLE_ID, {
                searchKey: searchKey,
                page: 1,
                pageCount: 60,
            });
            // Ensure we're mapping to an array of objects with label and value
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id || '', 
                value: id.cattle_id || ''
            })).filter(item => item.label); // Remove any empty items
            setCattleid(cattleID);
        } catch (error) {
            console.error("Error fetching cattle data:", error);
            setCattleid([]); // Ensure it's always an array
        }
    };

    const validationSchema = Yup.object({
        cattleIds: Yup.lazy((value) => {
            if (data?.[0]?.CattleID) {
                return Yup.mixed().notRequired();
            }
            return Yup.array()
                .of(Yup.string())
                .min(1, 'At least one Cattle ID is required')
                .required('Cattle ID is required');
        }),
        type: Yup.string().required('Type is required'),
        remarks: Yup.string().required('Remarks are required'),
    })

    const formik = useFormik({
        initialValues: {
            cattleIds: [], // Ensure this is an array
            type: '',
            remarks: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log('Form submitted with values:', values);
            setLoading(true);
            try {
               
                const cattleIdsToSubmit = data?.[0]?.CattleID 
                ? [data[0].CattleID]  // Use the single cattle ID from data
                : (Array.isArray(values.cattleIds) 
                    ? values.cattleIds 
                    : [values.cattleIds].filter(Boolean));

                const result = await action(API.ADD_CULLING, {
                    cattle_ids:cattleIdsToSubmit,
                    remarks: values.remarks,
                    type: values.type,
                });
                notification.success({ message: result?.response?.message, placement: "top" });

                formik.resetForm();
                toggle();
            } catch (error) {
                notification.error({ 
                    message: error.response?.data?.response?.message || 'An error occurred', 
                    placement: "top" 
                });
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        getCattleId();
    }, [searchKey]);

    const toggle1=()=>{
        formik.resetForm()
        toggle()
    }

    return (
        <Offcanvas 
            isOpen={isOpen} 
            toggle={toggle1} 
            direction="end" 
            className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
        >
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Culling
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Enter Culling Information
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            {!data?.[0]?.type?(

                           
                            <FlexCol className="" gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    {data?.[0]?.CattleID ? (
                                        <FormInput
                                            title="Select Cattle"
                                            placeholder=""
                                            value={data?.[0]?.CattleID || ''}
                                            disabled={true}
                                            required={true}
                                        />
                                    ) : (
                                        <MultiSelector
                                            title="Select Cattle"
                                            placeholder="Select Cattle"
                                            options={cattleid || []}
                                            value={formik.values.cattleIds}
                                            onSearch={setSearchKey}
                                            required={true}
                                            multiple={true}
                                            onChange={(selectedValues) => {
                                                // Ensure selectedValues is an array
                                                const values = Array.isArray(selectedValues) 
                                                    ? selectedValues 
                                                    : [selectedValues].filter(Boolean);
                                                formik.setFieldValue("cattleIds", values);
                                            }}
                                            error={formik.touched.cattleIds && formik.errors.cattleIds}
                                        />
                                    )}
                                    <DropdownButton
                                        title="Type"
                                        placeholder="Select Type"
                                        options={options}
                                        value={formik.values.type}
                                        change={(e) => formik.setFieldValue("type", e)}
                                        error={formik.touched.type && formik.errors.type}
                                    />
                                    <TextArea
                                        title="Remarks"
                                        placeholder="Add Remark"
                                        value={formik.values.remarks}
                                        change={(e) => formik.setFieldValue("remarks", e)}
                                        required={true}
                                        error={formik.touched.remarks && formik.errors.remarks}
                                    />
                                </Flex>
                            </FlexCol>
                            ):(
                                <>
                                <Label title='Cattle Id' value={data?.[0]?.CattleID} />
                                <Label title='Type' value={data?.[0]?.type} />
                                <Label title='Remarks' value={data?.[0]?.remarks} />
                                </>
                            )}
                        </FormGroup>
                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{ 
                                    fontSize: "12px", 
                                    backgroundColor: "none", 
                                    color: "#726C6C", 
                                    border: "1px #CCCBCB solid", 
                                    marginRight: "8px" 
                                }}
                                onClick={toggle1}
                                outline
                                title='Discard'
                                disabled={loading}
                            />
                             {!data?.[0]?.type?(

                            <CommonButton 
                                title='Save' 
                                style={{ fontSize: "12px" }}
                                type="submit"
                            />
                             ):(
                                null
                             )}
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    )
}

export default AddCulling