import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import Graph from '../../Common/Graph'
import MyTask from '../../Common/MyTask'
import FlexCol from '../../Common/FlexCol'
import Heading from '../../Common/Heading'
import Cards from '../../Common/Cards'
import PieChart from '../../Common/PieChart'
import Button from '../../Common/Button'
import CommonButton from '../../Common/Button'
import Dropdownbtn from '../../Common/Dropdownbtn'
import CommonCalendar from '../../Common/Calendar'
import DiagnoseCattle from '../../Drawer/DiagnoseCattle'
import { Navigate, useNavigate } from 'react-router'
import Accordion from '../../Common/Accordion'
import API, { action } from '../../../Api'
import RecordVaccine from '../../Drawer/RecordVaccine'
import { useLoader } from '../../../LoaderContext'

function DoctoreDashboard() {
  const [report, setReport] = useState(false)
  const [vaccine, setVaccine] = useState(false)
  const [cardCount, setCardCount] = useState({})
  const [medicationList, setMedicationList] = useState({})
  const [vaccineList, setVaccineList] = useState({})
  const [graphdata, setGraphdata] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const [pieChartKey, setPieChartKey] = useState(0);
  const [loader, setLoader] = useState(false)
  const [filter, setFilter] = useState("today")
  const { setLoading } = useLoader();
  const options = [{ value: "today", label: "Today" }, { value: "this_month", label: "This Month" }, { value: "this_year", label: "This Year" }]
  const DiagnoseReport = () => {
    setReport(prevState => {

      if (prevState) {
        getMedicationChecklist()
        getCardCount()
        getCalendardata()
        getCattleyearlycount()
        setPieChartKey(prevKey => prevKey + 1);
      }
      return !prevState;
    });

  }

  const navigate = useNavigate();

  const handleClick = () => {
    setVaccine(!vaccine)
    getVaccineChecklist()
  };

  const handlemedicationClick = () => {
    navigate('/healthMonitoring/medicationReport')
  }


  const getCardCount = async () => {
    setLoading(true);
    const result = await action(API.GET_DASHBOARD_COUNT, {
      filterType: filter
    });
    setLoading(false);
    console.log(result.response.data, "get card count")
    // setEmployeeLeave(result.result);
    setCardCount(result.response.data)
  };



  useEffect(() => {
    getCardCount()
    console.log(cardCount.critical_count, 'cardcount');

  }, [filter])


  const getMedicationChecklist = async () => {
    const result = await action(API.GET_MEDICATION_TODAY_LIST, {})
    console.log(result.response.data, "get medication list")

    // Transform the API response into the format expected by MyTask component
    const transformedData = result.response.data.map(item => ({
      id: item.id,
      forId: `task_${item.id}`,
      text: `${item.medicine_name} for cattle ${item.Cattle.cattle_id}`,
      date: item.medication_date,
    }))

    setMedicationList(transformedData)
  }

  useEffect(() => {
    getMedicationChecklist()
  }, [])




  const getVaccineChecklist = async () => {
    const result = await action(API.GET_VACCINATION_TODAY_LIST, {})
    console.log(result.response.data, "get vaccine list")

    // Transform the API response into the format expected by MyTask component
    const transformedData = result.response.data.map(item => ({
      id: item.id,
      forId: `task_${item.id}`,
      text: `Vaccine for cattle ${item.Cattle.cattle_id}`,
      date: item.vaccination_date,
    }))

    setVaccineList(transformedData)
  }

  useEffect(() => {
    getVaccineChecklist()
  }, [])



  const getCattleyearlycount = async () => {
    const result = await action(API.GET_SICK_CATTLE_YEARLY_COUNT, {});
    console.log(result.response.data, "get yearly count");

    // Create an array with all 12 months initialized to 0
    const allMonths = Array.from({ length: 12 }, (_, index) => ({
      month: index + 1,
      diagnosed: 0,  // Default value if no data
      cured: 0,      // Default value if no data
    }));

    // Fill in the data received from the API
    result.response.data.forEach(item => {
      const monthIndex = item.month - 1; // Adjust because months are 1-based (Jan = 1)
      allMonths[monthIndex] = {
        month: item.month,
        diagnosed: item.diagnosed,
        cured: item.cured,
      };
    });

    // Now allMonths will contain data for every month, with missing months filled with 0
    setGraphdata(allMonths);
  };

  useEffect(() => {
    getCattleyearlycount();
  }, []);

  const getCalendardata = async () => {
    const result = await action(API.GET_DASHBOARD_CALENDAR_DATA, {});
    console.log(result.response.data, "response data");
    setCalendarData(result.response.data)
  }
  //   if (result.response.data) {
  //     const vaccinationEvents = result.response.data.vaccination_data.map(item => ({
  //       date: new Date(item.vaccination_date),
  //       type: 'Vaccination',
  //       count: item.count
  //     }));
  //     const deliveryEvents = result.response.data.delivery_data.map(item => ({
  //       date: item.delivery_date ? new Date(item.delivery_date) : null,
  //       type: 'Delivery',
  //       count: item.count
  //     }));

  //     // Combine all events
  //     const allEvents = [...vaccinationEvents, ...deliveryEvents].filter(event => event.date);
  //     setCalendarData(allEvents);
  //   } else {
  //     console.error("API response data is not structured as expected");
  //   }
  // };


  useEffect(() => {
    getCalendardata()
    console.log(calendarData, "calendar data");



  }, [])



  const cardClick = (item) => {
    if (item.label === 'Issue Cattle') {
      navigate('/healthMonitoring', { state: { tab: "2" } });
    }
    else if (item.label === 'Critical') {
      navigate('/healthMonitoring', { state: { tab: "5" } });// Pass tab as part of state
    }
    else if (item.label === 'Ongoing Medication') {
      navigate('/healthMonitoring', { state: { tab: "3" } });
    }
    else if (item.label === 'Not on Medication') {
      navigate('/healthMonitoring', { state: { tab: "4" } });
    } else if (item.label === 'Pregnant Cattle') {
      navigate("/breeding/pregnancyReport")
    }
    else if (item.label === 'Calf & Heifer') {
      navigate("/cattleDashboard")
    }
    else if (item.label === 'Expected Heat') {
      navigate("")
    }
    else if (item.label === 'Expected Delivery') {
      navigate("/breeding/pregnancyReport")
    }
    else {
      navigate('');
    }
  }




  const carddata = [
    {
      id: 1,
      cardColor: "primary",
      label: "Issue Cattle",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: cardCount?.issue_cattle?.issue_cattle_diff,
      counter: cardCount?.issue_cattle?.issue_cattle_count || 0,
      link: "View net earnings",
      bgcolor: "primary",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k",
    },
    {
      id: 2,
      cardColor: "secondary",
      label: "Ongoing Medication",
      badge: "ri-arrow-right-down-line",
      badgeClass: "danger",
      percentage: cardCount?.ongoing_medication?.ongoing_medication_diff,
      counter: cardCount?.ongoing_medication?.ongoing_medication_count || 0,
      link: "View all orders",
      bgcolor: "primary",
      icon: "bx bx-shopping-bag",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: ""
    },
    {
      id: 3,
      cardColor: "success",
      label: "Not on Medication",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: cardCount?.not_on_medication?.not_on_medication_diff,
      counter: cardCount?.not_on_medication?.not_on_medication || 0,
      link: "See details",
      bgcolor: "primary",
      icon: "bx bx-user-circle",
      decimals: 2,
      prefix: "",
      suffix: "M"
    },
    {
      id: 4,
      cardColor: "info",
      label: "Critical",
      badgeClass: "muted",
      percentage: cardCount?.critical_count?.critical_count_diff,
      counter: cardCount?.critical_count?.critical_count || 0,
      link: "Withdraw money",
      bgcolor: "primary",
      icon: "bx bx-wallet",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
    {
      id: 1,
      cardColor: "primary",
      label: "Total Cattle",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: cardCount?.total_cattle_count?.total_cattle_count_diff,
      counter: cardCount?.total_cattle_count?.total_cattle_count || 0,
      link: "View net earnings",
      bgcolor: "primary",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
    {
      id: 2,
      cardColor: "primary",
      label: "Expected Delivery",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: cardCount?.expected_delivery_count?.expected_delivery_count_diff,
      counter: cardCount?.expected_delivery_count?.expected_delivery_count || 0,
      link: "View net earnings",
      bgcolor: "primary",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
    // {
    //   id: 2,
    //   cardColor: "secondary",
    //   label: "Expected Heat",
    //   badge: "ri-arrow-right-down-line",
    //   badgeClass: "danger",
    //   percentage: cardCount?.expected_heat_count?.expected_heat_count_diff,
    //   counter: cardCount?.expected_heat_count?.expected_heat_count || 0,
    //   link: "View all orders",
    //   bgcolor: "primary",
    //   icon: "bx bx-shopping-bag",
    //   decimals: 0,
    //   prefix: "",
    //   separator: ",",
    //   suffix: ""
    // },
    {
      id: 3,
      cardColor: "success",
      label: "Pregnant Cattle",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: cardCount?.pregnant_count?.pregnant_count_diff,
      counter: cardCount?.pregnant_count?.pregnant_count || 0,
      link: "See details",
      bgcolor: "primary",
      icon: "bx bx-user-circle",
      decimals: 2,
      prefix: "",
      suffix: "M"
    },
    {
      id: 4,
      cardColor: "info",
      label: "Calf & Heifer",
      badgeClass: "muted",
      percentage: cardCount?.calf_heifer_count?.calf_heifer_count_diff,
      counter: cardCount?.calf_heifer_count?.calf_heifer_count || 0,
      link: "Withdraw money",
      bgcolor: "primary",
      icon: "bx bx-wallet",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
  ];

  const medicationChecklist = [
    {
      id: 1,
      forId: "task_one",
      text: "AB Medicine for cattle 5846",
      date: "15 Sep, 2021",
    },
    {
      id: 2,
      forId: "task_two",
      text: "Deworming of Cattle 5468",
      date: "20 Sep, 2021",
    },
    {
      id: 3,
      forId: "task_three",
      text: "CC Medicine of Cattle 5468",
      date: "24 Sep, 2021",
    },
    {
      id: 4,
      forId: "task_four",
      text: "AB Medicine for cattle 5846",
      date: "27 Sep, 2021",
    },
    {
      id: 5,
      forId: "task_five",
      text: "CC Medicine of Cattle 5468",
      date: "27 Sep, 2021",
    },
    {
      id: 6,
      forId: "task_six",
      text: "AB Medicine for cattle 5846",
      date: "27 Sep, 2021",
    },
  ];

  const vaccineChecklist = [
    {
      id: 1,
      forId: "task_one",
      text: "Vaccine  Cattle 5846",
      date: "15 Sep, 2021",
    },
    {
      id: 2,
      forId: "task_two",
      text: "Vaccine  Cattle 5846",
      date: "20 Sep, 2021",
    },
    {
      id: 3,
      forId: "task_three",
      text: "Vaccine  Cattle 5846",
      date: "24 Sep, 2021",
    },
    {
      id: 4,
      forId: "task_four",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
    },
    {
      id: 5,
      forId: "task_five",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
    },
    {
      id: 6,
      forId: "task_six",
      text: "Vaccine  Cattle 5846",
      date: "27 Sep, 2021",
    },
  ];


  const handleSelect = (option) => {
    console.log('Selected option:', option);
  };

  return (
    <div style={{ backgroundColor: "#FDFDFD" }}>
      <FlexCol>
        <div className="flex flex-col sm:flex-row justify-between">
          <div className='d-flex space-x-2'>
            <Heading title='Cattle  Health Dashboard' />
            <Dropdownbtn style={{ backgroundColor: '#FAFAFA', color: '#5A5555', border: '1px #CCCBCB solid', paddingTop: '1px', paddingBottom: '1px', paddingLeft: "2px", paddingRight: "2px" }} options={options} onSelect={(value) => setFilter(value)} />
          </div>
          <CommonButton title='Diagnose Cattle' onClick={DiagnoseReport} />
        </div>


        <Cards data={carddata} style={{ color: "#E4626F" }} cardClick={cardClick} filter={filter === "today" ? "vs yesterday" : filter === "this_month" ? "vs last month" : filter === "this_year" ? "vs last year" : ""} />
        {/* <div className="grid grid-cols-16 gap-4 ">
   <div className="col-span-12 xl:col-span-8">
   <Graph/>
    </div>
    <div className="col-span-8 xl:col-span-8">
    <MyTask/>
    </div> 
     </div> */}
        <Row className="gx-3 gy-4">
          <Col xl={6}>
            {/* <Graph title='Cattle Health Statistics' /> */}
            <MyTask heading="Medication Checklist" data={medicationList} btntitle='Add Medication' onclick={DiagnoseReport} seeAllLink='/healthMonitoring/medicationReport' />
          </Col>
          <Col xl={6}>
            {/* <PieChart /> */}
            <MyTask heading='Vaccine Checklist' data={vaccineList} btntitle='Add New' onclick={handleClick} seeAllLink='/vaccination/vaccination-report' />
          </Col>
        </Row>
        <Row className="gx-3 gy-4">
          <Col xl={6} >
            <PieChart title="Sick Cattle Distribution" key={pieChartKey} />
          </Col>
          <Col xl={6}>

            <CommonCalendar vaccinationData={calendarData} />
          </Col>
        </Row>
        <Row style={{ marginTop: "5px" }}>
          <Graph
            title="Cattle Health Statistics"
            series={[
              { name: "Diagnosed", type: "bar", data: graphdata.map(item => item.diagnosed) },
              { name: "Cured", type: "line", data: graphdata.map(item => item.cured) }
            ]}
            categories={graphdata.map(item => `Month ${item.month}`)}
          />

        </Row>

      </FlexCol>
      <DiagnoseCattle isOpen={report} toggle={DiagnoseReport} />
      <RecordVaccine isOpen={vaccine} toggle={handleClick} />
    </div>
  )
}

export default DoctoreDashboard