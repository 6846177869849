import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol';
import Heading from '../Common/Heading';
import Dropdownbtn from '../Common/Dropdownbtn';
import SearchBox from '../Common/SearchBox';
import { Nav, NavItem, NavLink } from 'reactstrap';
import ReactTable from '../Common/Table';
import CommonButton from '../Common/Button';
import cowicon from '../../assets/Images/Cow icon.png'
import AddPregnancy from '../Drawer/AddPregnancy';
import RecordDeliveryForm from '../Drawer/RecordDeliveryForm';
import RecordDelivery from '../Drawer/RecordDelivery';
import API, { action } from '../../Api';
import { Pagination } from 'antd';
import FileSaver from 'file-saver';
import { useLoader } from '../../LoaderContext';

function DeliveryReportsMonitoring() {
    const [report, setReport] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const [tab, setTab] = useState("1");
    const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };
    const handleclick = (userId) => {
        console.log(userId, "pppp");

        setSelectedData(userId)
        setReport(!report)
    }

    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Insemination Type",
            value: "InseminationType",

        },


        {
            id: 4,
            title: "Insemination Date",
            value: "inseminationdate",


        },
        {
            id: 5,
            title: "Status",
            value: "status",


        },
        {
            id: 6,
            title: "Action",
            value: "action",


        },

    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",

            action: "Take Delivery",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            Severity: "Critical",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            Severity: "Need Care",
            ExpectedDeliveryDate: "11-10-2024",
            action: "Take Delivery",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "View Report",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            InseminationType: "1",
            Severity: "Normal",
            ExpectedDeliveryDate: "11-10-2024",
            action: "View Report",
        },
    ];

    const [showPregnancyReport, setShowPregnancyRepor] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [deliveryList, setDeliveryList] = useState([])
    const { setLoading } = useLoader();
    const handleButtonClick = () => setShowPregnancyRepor(!showPregnancyReport)
    const ButtonClick = () => setDelivery(!delivery)



    const getDeliveryReport = async (page = currentPage, pageSize = 10) => {
        setLoading(true);
        const result = await action(API.GET_CATTLE_PREGNANCY_DATA, {
            type: 3,
            searchKey:searchTerm ,
            page: page,
            pageCount: pageSize
        });
        setLoading(false);
        console.log(result.response.data, "get delivery list")
             setPaginationcount(result.response.count);
        // setEmployeeLeave(result.result);
        setDeliveryList(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: (index + 1).toString().padStart(2, '0'), // Format SlNo


            CattleID: item.Insemination.Cattle.cattle_id,
            InseminationType: item.Insemination.insemination_type,
            InseminationDate: item.Insemination.insemination_date,
            id: item.id,
            status: item.status,
            ExpectedDeliveryDate: item.expected_delivery_date,
            inseminationdate: item.Insemination.insemination_date,
            action: 'View Report',
            inseminationId:item.insemination_id,
            deleveryDate:item.delivery_date,
            calf: item.calf_data
        }))

        setDeliveryList(formattedData);
    };
    useEffect(() => {
        getDeliveryReport()
        console.log(deliveryList, 'deliveryList');

    }, [searchTerm ,pageSize, currentPage,])

    // const filteredUserList = (deliveryList || []).filter(user => {
    //     const CattleID = user.CattleID || ""; // Prevent null error
    //     const InseminationType = user.InseminationType || ""; // Prevent null error
    //     const role = user.Role || "";
    //     return (
    //         CattleID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         InseminationType.includes(searchTerm) ||
    //         role.toLowerCase().includes(searchTerm.toLowerCase())
    //     )
    // });


    const handleExport= async()=>{
        const result = await action(API.GET_DELIVERY_REPORT_EXPORT, {
        
            searchKey: searchTerm,
           
        });
        const filecontent = result;
     
            const byteCharacters = atob(filecontent);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
     
            const file = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
     
            FileSaver.saveAs(file, "Delivery Report.xlsx");
    }
    
    useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getDeliveryReport(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getDeliveryReport(currentPage, pageSize)
        }
    }, [searchTerm, tab, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getDeliveryReport(currentPage, pageSize)
        }
    }, [currentPage])

    const handlePageChange = (page) => {
        setCurrentPage(page)
        getDeliveryReport(page, pageSize)
    }
    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Delivery Report' />
                        {/* <Dropdownbtn title='Today' style={{ backgroundColor: "#FAFAFA", color: "#5A5555", border: '1px #CCCBCB solid' }} /> */}
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm} onSearch={setSearchTerm} />
                        {/* <CommonButton title="Add Pregnancy" style={{ backgroundColor: "none", color: "#16A34A", border: "1px solid #16A34A" }} onClick={handleButtonClick} />
                        <CommonButton title="Record Delivery" onClick={ButtonClick} /> */}
                      <CommonButton title="Export" onClick={handleExport}  />         
                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                {/* <Nav pills className="nav-success ">
                    {["All", "Pending", "In Progress", "Completed"].map(( tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav> */}
                {/* {tab === "1" && <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "2" && <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "3" && <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "4" && <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick} />} */}
                <ReactTable headings={headings} data={deliveryList} headingcolor="#D9D9D9" handleAction={handleclick}   pagination={paginationcount}
            fetchData={getDeliveryReport}/>
             {(deliveryList.length > pageSize || paginationcount > 10) && (
                        <div className="flex justify-end mt-4">
                            <Pagination
                                current={currentPage}
                                total={paginationcount}
                                pageSize={pageSize}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                className="p-2 font-sans text-[#A0A0A0]"
                                itemRender={(page, type, originalElement) => {
                                    if (type === "page") {
                                      return (
                                        <span
                                          className={`px-2 ${
                                            page === currentPage ? "font-bold text-black" : "font-normal"
                                          }`}
                                        >
                                          {page}
                                        </span>
                                      );
                                    }
                                    return originalElement;
                                  }}
                            />
                         </div>
                )}
            </FlexCol>
            {/* <AddPregnancy isOpen={showPregnancyReport} toggle={handleButtonClick} />
            <RecordDeliveryForm isOpen={delivery} toggle={ButtonClick} /> */}
            <RecordDelivery isOpen={report} toggle={handleclick} data={selectedData} />


        </div>
    )
}

export default DeliveryReportsMonitoring