import React from "react";

function Label({ title, value }) {
  return (
    <div>
      <p className="formheading mb-1 capitalize">{title}</p>
      <p className="formvalue capitalize"> {value}</p>
    </div>
  );
}

export default Label;
