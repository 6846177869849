import React from "react";
import { Card, CardBody } from "reactstrap";
import Chart from "react-apexcharts";
import barchart from "../../assets/Images/Frame.svg";
import TableNodata from "../Common/TableNodata";

const MilkReportChart = ({ cattleData }) => {
  // Get current month (1-based, where 1 is Jan and 12 is Dec)
  const currentMonth = new Date().getMonth() + 1;

  // Calculate start month (6 months ago)
  const startMonth = (currentMonth - 5 + 12) % 12 || 12; // Ensure we get 12 instead of 0

  // Extract monthly data from cattleData
  const monthlyData = cattleData?.monthly_report || [];

  // Filter data to show last 6 months
  const filteredMonthlyData = monthlyData.filter((item) => {
    if (startMonth <= currentMonth) {
      // Simple case: just check if month is within range
      return item.month >= startMonth && item.month <= currentMonth;
    } else {
      // Wrap around case (e.g., Nov, Dec, Jan, Feb, Mar, Apr)
      return item.month >= startMonth || item.month <= currentMonth;
    }
  });

  // Sort the filtered data to ensure correct order
  const sortedMonthlyData = [...filteredMonthlyData].sort((a, b) => {
    if (startMonth <= currentMonth) {
      return a.month - b.month;
    } else {
      // Handle wrap around case
      const aMonth = a.month < startMonth ? a.month + 12 : a.month;
      const bMonth = b.month < startMonth ? b.month + 12 : b.month;
      return aMonth - bMonth;
    }
  });

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Create arrays for chart data
  const months = sortedMonthlyData.map((item) => monthNames[item.month - 1]);
  const liters = sortedMonthlyData.map((item) => item.totalLiters);

  const hasNonZeroValues = liters.some((value) => value > 0);

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      events: {
        mouseMove: function (event, chartContext, config) {
          // Reset all labels to default style
          const labels = document.querySelectorAll(".apexcharts-xaxis-label");
          labels.forEach((label) => {
            label.style.fill = "#878787";
            label.style.fontSize = "12px";
          });

          // If hovering over a label, highlight it
          if (config.dataPointIndex !== -1) {
            const activeLabel = document.querySelector(
              `.apexcharts-xaxis-label:nth-child(${config.dataPointIndex + 1})`
            );
            if (activeLabel) {
              activeLabel.style.fill = "#16A34A";
              activeLabel.style.fontSize = "14px";
            }
          }
        },
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: "#878787",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "400",
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    colors: ["#16A34A"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: "40%",
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "light",
      style: {
        fontSize: "14px",
        fontFamily: "Arial, sans-serif",
        color: "#fff",
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        const category = w.globals.labels[dataPointIndex];
        const seriesColor = chartOptions.colors[seriesIndex];

        return `
           <div
            style="
              border: 1px solid rgb(213, 218, 218);
              border-radius: 5px;
              background-color: #fff;
              color: #000;
              overflow: hidden;
            "
          >
            <div
              style="
                font-weight: bold;
                padding: 10px;
                background-color: rgb(213, 218, 218);
                border-bottom: 1px solid rgb(218, 214, 214);
              "
            >
              ${category}
            </div>
            <div style="padding: 10px; display: flex; align-items: center; gap:10px;">
              <div
                style="
                  height: 10px;
                  width: 10px;
                  border-radius: 50%;
                  background-color: ${seriesColor};
                "
              ></div>
              <div>Milk : ${value}</div>
            </div>
          </div>
        `;
      },
    },
  };

  const chartSeries = [
    {
      name: "Milk",
      data: liters,
    },
  ];

  return (
    <div className="bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
      <CardBody>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded mb-2">
              <img src={barchart} alt="Bar chart icon" />
            </div>
            <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">
              Monthly Milk Report
            </h2>
          </div>
          {/* <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
            <img src={ci} className="w-7 h-5" alt="expand icon" />
            <p className="ml-1 mt-1">...</p>
          </div> */}
        </div>
        {hasNonZeroValues ? (
          <>
            <div className="flex items-center">
              <div className="w-2.5 h-2.5 bg-green-600 rounded-sm" />
              <span className="ml-2 text-gray-800 font-plus-jakarta-sans text-xs">
                Milk
              </span>
            </div>

            <Chart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={270}
            />
          </>
        ) : (
          <TableNodata />
        )}
      </CardBody>
    </div>
  );
};

export default MilkReportChart;
