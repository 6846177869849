import React, { useEffect, useState } from 'react';
import FlexCol from '../Common/FlexCol';
import CommonButton from '../Common/Button';
import Cards from '../Common/Cards';
import { Col, Row } from 'reactstrap';
import RecordMilk from '../Common/RecordMilk';
import YieldingCattle from '../Common/YieldingCattle';
import MilkReportChart from './MilkReportChart';
import DailyMilkReport from './DailyMilkReport';
import MilkingStatistics from './MilkingStatistics';
import Heading from '../Common/Heading';
import API, { action } from '../../Api';
import { useNavigate } from 'react-router';
import MultipleMilkEntries from '../Drawer/MultipleMilkEntries';
import { useLoader } from '../../LoaderContext';
import Dropdownbtn from '../Common/Dropdownbtn';

function MilkReport() {
  const [showMilkingStats, setShowMilkingStats] = useState(false);
  const [cattledata, setCattledata] = useState([])
  const [dashboarddata, setDashboarddata] = useState([])
  const [recordMilk, setRecordMilk] = useState(false)
  const [filter, setFilter]=useState("today")
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const options = [{value:"today",label:"Today"},{value:"this_month",label:"This Month"},{value:"this_year",label:"This Year"}]
  const handleButtonClick = () => {
    navigate("/milkReport/milkingStatistics")
  };
  const cardClick = (item) => {
    if (item.label === 'Total Milking') {
      navigate("/milkReport/milkingStatistics")
    } else {
      navigate("")
    }
  }
  const oncardclick = () => {
    navigate("/milkReport/milkingStatistics")
  }
  const getTopCattleData = async () => {
    const result = await action(API.GET_TOP_CATTLE_DATA, {

    });
    console.log(result.response.data, 'get upcomming list');
    setCattledata(result.response.data);
  };

  useEffect(() => {
    getTopCattleData();
  }, []);


  const getMilkDashboardData = async () => {
    setLoading(true);
    const result = await action(API.GET_MILK_DASHBOARD_DATA, {
      filterType:filter
    });
    setLoading(false);
    console.log(result.response.data, 'get dashboard data');
    setDashboarddata(result.response.data);
  };

  useEffect(() => {
    getMilkDashboardData();
  }, [filter]);


  const carddata = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Milking",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboarddata.total_milk?.total_milk_diff,
      counter: (dashboarddata.total_milk?.total_milk || 0 )  ,
      link: "View net earnings",
      bgcolor: "primary",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
    {
      id: 2,
      cardColor: "secondary",
      label: "To be Milked",
      badge: "ri-arrow-right-down-line",
      badgeClass: "danger",
      percentage: dashboarddata.pending_record?.pending_record_diff,
      counter: dashboarddata.pending_record?.pending_record || 0,
      link: "View all orders",
      bgcolor: "primary",
      icon: "bx bx-shopping-bag",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: ""
    },
    {
      id: 3,
      cardColor: "success",
      label: " Milk Not Usable",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: dashboarddata.not_usable?.not_usable_diff,
      counter: dashboarddata.not_usable?.not_usable|| 0,
      link: "See details",
      bgcolor: "primary",
      icon: "bx bx-user-circle",
      decimals: 2,
      prefix: "",
      suffix: "M"
    },
    {
      id: 4,
      cardColor: "info",
      label: "Dry Cattle",
      badgeClass: "muted",
      percentage: dashboarddata.dry_cattles?.dry_cattles_diff,
      counter: dashboarddata.dry_cattles?.dry_cattles || 0,
      link: "Withdraw money",
      bgcolor: "primary",
      icon: "bx bx-wallet",
      decimals: 2,
      prefix: "$",
      suffix: "k"
    },
  ]
  const handleclick = () => {
    setRecordMilk(prevState => {

      if (prevState) {
        getMilkDashboardData();
        getTopCattleData()
      }
      return !prevState;
    });


  }
  return (
    <div style={{ backgroundColor: "#FDFDFD" }}>
      <FlexCol>
        {!showMilkingStats ? ( // Conditional rendering
          <>
            <div className="flex flex-col sm:flex-row justify-between">
            <div className="d-flex space-x-2">
              <Heading title='Milking Report' />
              <Dropdownbtn  style={{ backgroundColor: '#FAFAFA', color: '#5A5555', border: '1px #CCCBCB solid',paddingTop: '1px',paddingBottom: '1px',paddingLeft:"2px",paddingRight:"2px"}} options={options} onSelect={(value) =>setFilter(value)} />
              </div>
              <div className="d-flex space-x-5">
                <CommonButton
                  title="View All Milk Records"
                  style={{ color: "#0EA5E9", backgroundColor: "none", border: "1px #0EA5E9 solid" }}
                  onClick={handleButtonClick} // Add onClick handler
                />
              </div>
            </div>

            <Cards data={carddata} cardClick={cardClick}  filter={filter === "today" ? "vs yesterday" : filter === "this_month" ? "vs last month" : filter === "this_year" ? "vs last year" : ""} />
            <Row className="gx-3 gy-4">
              <Col xl={8}>
                <div >
                  <DailyMilkReport cattleData={dashboarddata} />
                </div>
              </Col>
              <Col xl={4}>
                <RecordMilk handleclick={handleclick} />
              </Col>
              <Row className="gx-3 gy-3">
                <Col xl={5}>
                  <MilkReportChart cattleData={dashboarddata} />
                </Col>
                <Col xl={7}>
                  <YieldingCattle cattleData={cattledata} onCardClick={oncardclick} />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <MilkingStatistics /> // Show MilkingStatistics when button is clicked
        )}
      </FlexCol>
      <MultipleMilkEntries isOpen={recordMilk} toggle={handleclick} />
    </div>
  );
}

export default MilkReport;
