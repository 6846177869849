import React, { useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import DateSelect from '../Common/DateSelector';
import FormInput from '../Common/FormInput';
import { Flex } from 'antd';
import CommonButton from '../Common/Button';
import TextArea from '../Common/TextArea';
import AddPregnancy from './AddPregnancy';
import DropdownButton from '../Common/DropdownButton';
import API, { action } from '../../Api';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { notification } from 'antd';
import Label from '../Common/Label';

function RecordInseminationView({ isOpen, toggle, selectedData, refresh }) {
    console.log(selectedData, "datatat");

    const tabs = ['Natural', 'Artificial', 'double_ai'];
    const [activeTab, setActiveTab] = useState('Natural');
    const [pregnancy, setPregnancy] = useState(false)
    const [isReInseminated, setIsReInseminated] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setPregnancy(!pregnancy)
        toggle()
        refresh()

    }
    const handleclose = () => {
        setPregnancy(!pregnancy)
        refresh()
    }

    const handleReInseminateClick = () => {
        setIsReInseminated(true);  // Set the state to true when the button is clicked
    };

    const toggle1 = () => {
        setIsReInseminated(false)
        formik.resetForm()
        toggle()
    }

    const iD = localStorage.getItem('userid');
    const formik = useFormik({
        initialValues: {
            inseminationType: 'Natural',
            inseminationDate: '',
            expectedPregnancy: '',
            cattle: '',
            description: '',
            id: ''
        },
        validationSchema: Yup.object({
            inseminationDate: Yup.date().required('Required'),
            expectedPregnancy: Yup.date().required('Required'),
            // cattle: Yup.string().required('Required'),
            description: Yup.string().optional(),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                // if (id) { // If id is present, update the role
                //     await action(API.UPDATE_USER, {
                //         id: selectedUserId[0].userId, // Add the role ID
                //         full_name: values.userName,
                //         privileges: values.permissions,
                //         role_id: values.userRole,
                //         // password: values.password,
                //         email: values.email
                //     });
                // } else {
                console.log(values, "pp");

                const result = await action(API.UPDATE_INSEMINATION, {
                    cattle_id: selectedData?.[0]?.CattleID
                        || '',
                    insemination_type: values.inseminationType,
                    insemination_date: values.inseminationDate,
                    id: selectedData?.[0]?.ID
                        || '',
                    expected_pregnancy: values.expectedPregnancy,
                    description: values.description,
                    created_by: iD
                });
                notification.success({ message: result?.response?.message, placement: "top", });
                // }
                // Optionally reset the form or close the offcanvas
                formik.resetForm();
                toggle(); // Close the offcanvas
                setIsReInseminated(false)
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });
    const formatTabText = (text) => {
        // Handle cases with underscore
        if (text.includes('_')) {
            return text.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
        // Handle single word cases
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };


    return (
        <>
            <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className="w-full md:!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
                <FlexCol className={"p-4"} >

                    <OffcanvasHeader>
                        <div >
                            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Insemination</div>
                            <div className="text-[10px] 2xl:text-sm   text-[#726C6C] font-plus-jakarta-sans ">Add Insemination Details</div>
                        </div>
                    </OffcanvasHeader>
                    <OffcanvasBody>

                        <FormGroup>
                            {selectedData?.[0]?.status !== "failed" ? (
                                !isReInseminated && (
                                    <>
                                        <FlexCol className={""} gap={20}>
                                            <Flex className="grid grid-cols-1 gap-[30px] items-center">

                                                <FormInput title='Insemination Type  '
                                                    placeholder=''
                                                    value={formatTabText(selectedData?.[0]?.InseminationType || '')}
                                                    disabled={true}
                                                />

                                                <FormInput
                                                    title='Insemination Date'
                                                    placeholder=''
                                                    value={selectedData?.[0]?.InseminationDate || ''}
                                                    disabled={true}
                                                />
                                                <FormInput
                                                    title='Expected Pregnancy'
                                                    placeholder=''
                                                    value={selectedData?.[0]?.ExpectedPregnancy || ''}
                                                    disabled={true}
                                                />
                                                <FormInput
                                                    title='Cattle ID'
                                                    placeholder=''

                                                    value={selectedData?.[0]?.CattleID
                                                        || ''}
                                                    disabled={true}
                                                />
                                                {selectedData?.[0]?.description &&(
                                                <TextArea
                                                    title='Description'
                                                    placeholder=''

                                                    value={selectedData?.[0]?.description
                                                        || ''}
                                                    disabled={true}
                                                />
                                            )}
                                                {/* <TextArea
                                        title='Description'
                                        placeholder='Add Description'
                                        // value={formik.values.description}
                                        // change={formik.handleChange('description')}
                                        // onBlur={formik.handleBlur('description')}
                                        // error={formik.touched.description && formik.errors.description}
                                    /> */}

                                            </Flex>
                                        </FlexCol>
                                    </>
                                )
                            ) : (
                                <>
                                    <Label title='Insemination Type' value={formatTabText(selectedData?.[0]?.InseminationType || '')} />

                                    <Label title='Insemination Date' value={selectedData?.[0]?.InseminationDate || ''} />
                                    <Label title='Expected Pregnancy' value={selectedData?.[0]?.ExpectedPregnancy || ''} />
                                    <Label title='Cattle ID' value={selectedData?.[0]?.CattleID
                                        || ''} />
                                    <Label title='Description' value={selectedData?.[0]?.description
                                        || '--'} />

                                </>
                            )}
                        </FormGroup>
                        {selectedData?.[0]?.status !== "failed" ? (
                            !isReInseminated && (
                                <div className="flex justify-between mt-8">
                                    <CommonButton
                                        style={{
                                            fontSize: "12px",
                                            backgroundColor: "none",
                                            color: "#726C6C",
                                            border: "1px #CCCBCB solid",
                                            marginRight: "8px",
                                        }}
                                        onClick={handleReInseminateClick}
                                        outline
                                        title="Re Inseminate"
                                    />
                                    <CommonButton
                                        title="Mark Pregnant"
                                        style={{ fontSize: "12px" }}
                                        onClick={handleClick}
                                    />
                                </div>
                            )
                        ) : (
                            <div className="flex justify-between mt-8">
                                <CommonButton
                                    style={{
                                        fontSize: "12px",
                                        backgroundColor: "none",
                                        color: "#726C6C",
                                        border: "1px #CCCBCB solid",
                                        marginRight: "8px",
                                    }}
                                    onClick={toggle1}
                                    outline
                                    title="Cancel"
                                    disabled={loading}
                                />
                            </div>
                        )}

                        {/* When Re Inseminate is clicked, show additional fields */}
                        {isReInseminated && (

                            <form onSubmit={formik.handleSubmit}>

                                <FlexCol className={""} gap={20}>
                                    <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 20, display: 'flex' }}>
                                            <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                                Insemination Type
                                            </div>
                                            <div className="flex gap-2">
                                                {/* You can map over the tabs if needed */}
                                                {/* Assuming "tabs" is a predefined array */}
                                                {tabs.map((tab) => (
                                                    <div
                                                        key={tab}
                                                        onClick={() => {
                                                            setActiveTab(tab);
                                                            formik.setFieldValue('inseminationType', tab);
                                                        }}
                                                        className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                    >
                                                        <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words capitalize`}>
                                                            {tab.replace(/_/g, ' ')}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <DateSelect
                                                title="Insemination Date"
                                                placeholder="Choose Insemination Date"
                                                value={formik.values.inseminationDate}
                                                change={(date) => formik.setFieldValue('inseminationDate', date)}
                                                error={formik.touched.inseminationDate && formik.errors.inseminationDate}
                                                required={true}
                                                disableBeforeDate={
                                                    selectedData?.[0]?.InseminationDate
                                                        ? new Date(
                                                            new Date(selectedData[0].InseminationDate).setDate(
                                                                new Date(selectedData[0].InseminationDate).getDate() + 1
                                                            )
                                                        )
                                                        : null
                                                }

                                            />
                                            <DateSelect
                                                title="Expected Pregnancy"
                                                placeholder="Choose Expected Pregnancy Date"
                                                disableBeforeDate={formik.values.inseminationDate}
                                                value={formik.values.expectedPregnancy}
                                                change={(date) => formik.setFieldValue('expectedPregnancy', date)}
                                                error={formik.touched.expectedPregnancy && formik.errors.expectedPregnancy}
                                                required={true}
                                            />
                                            {/* <DropdownButton
                                            title="Cattle"
                                            placeholder="1865"
                                            // options={cattleid}

                                            value={formik.values.cattle}
                                            change={formik.handleChange('cattle')}
                                            onBlur={formik.handleBlur('cattle')}
                                            error={formik.touched.cattle && formik.errors.cattle}
                                        /> */}
                                            <TextArea
                                                title="Description"
                                                placeholder="Add Description"
                                                value={formik.values.description}
                                                change={formik.handleChange('description')}
                                                onBlur={formik.handleBlur('description')}
                                                error={formik.touched.description && formik.errors.description}

                                            />
                                        </div>
                                        <FormGroup />

                                        <div className="flex justify-between mt-8">
                                            <CommonButton
                                                style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px" }}
                                                onClick={toggle1}
                                                outline
                                                title='Discard'
                                                disabled={loading}
                                            />
                                            <CommonButton
                                                type='submit'

                                                title='Save Insemination'
                                                style={{ fontSize: "12px" }}
                                                disabled={loading}
                                            />
                                        </div>

                                    </Flex>
                                </FlexCol>
                            </form>
                        )}

                    </OffcanvasBody>

                </FlexCol>
            </Offcanvas>
            <AddPregnancy isOpen={pregnancy} toggle={handleclose} selectedData={selectedData} />
        </>
    )
}

export default RecordInseminationView