import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Card, CardBody } from 'reactstrap';
import barchart from "../../assets/Images/Frame.svg";
import ci from "../../assets/Images/ci_expand.svg";
import API, { action } from "../../Api";

const data = [
    { day: "SUN", litre: 176 },
    { day: "MON", litre: 156 },
    { day: "TUE", litre: 136 },
    { day: "WED", litre: 116 },
    { day: "THU", litre: 132 },
    { day: "FRI", litre: 156 },
    { day: "SAT", litre: 224 },
];



const   CommonGraph = ({ title, img, barColor = "#16A34A", slopeColor = "#16A34A", gradientColors = ["rgba(94.67, 212, 84, 0.55)", "rgba(255, 255, 255, 0)"],Selected  },) => {
    console.log(Selected.cattle_id
        ,"Milking");
    const [milkingList,setMilkingList]=useState([])
    const getMilkingList = async () => {
        try {
            const result = await action(API.GET_CATTLE_MILKING_DATA, {
                cattle_id: Selected?.cattle_id,
            });

            if (result?.response?.data) {
                const transformedData = result.response.data.map(item => {
                    return {
                        day: getDayName(item.day),  // Map the day number to the day name
                        litre: item.totalLiters,     // Use the totalLiters from the API
                    };
                });
                setMilkingList(transformedData);
            }
        } catch (error) {
            console.error("Error fetching milking list:", error);
        }
    };

useEffect(() => {

    getMilkingList();  // Fetch cattle list if cattle_id is present
  
}, [Selected])



const getDayName = (dayNumber) => {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    return days[dayNumber - 1];  // Day number starts from 1 (SUN) to 7 (SAT)
};

    return (
        <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-3">
            <CardBody>
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center gap-2">
                        <div className="w-4 h-4 rounded mb-2">
                            <img src={img} alt="Chart Icon" />
                        </div>
                        <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">{title}</h2>
                    </div>
                    {/* <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
                        <img src={ci} className='w-7 h-5' alt="Expand Icon" />
                        <p className='ml-1 mt-1'>...</p>
                    </div> */}
                </div>

                <div className="relative h-[350px]">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={milkingList} barSize={128} barGap={2}>
                            <XAxis dataKey="day" axisLine={false} tickLine={false} tick={<CustomizedTick />} />
                            <YAxis hide />
                            <Bar dataKey="litre" shape={<CustomBar barColor={barColor} slopeColor={slopeColor} gradientColors={gradientColors} />} label={<CustomLabel />} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardBody>
        </div>
    );
};

// Custom Tick Component
const CustomizedTick = ({ x, y, payload }) => {
    return (
        <text
            x={x}
            y={y + 15}
            fill="#6B7280"
            textAnchor="middle"
            style={{ fontSize: "10px", fontWeight: "bold" }}
        >
            {payload.value}
        </text>
    );
};

// Custom Bar Component
const CustomBar = ({ x, y, width, height, index, value, barColor, slopeColor, gradientColors }) => {
    const currentDayIndex = new Date().getDay();
    const dataDayIndex = index;
    const fill = dataDayIndex === currentDayIndex ? barColor : `url(#gradient${index})`;
    const slopeHeight = 20;
    const borderRadius = 0;
    const borderRadius2 = 10;
    const borderHeight = 850;
    const topLineY1 = y; // Current bar's top
    const slope = 20; // The desired slope value
    if (!value) {
        return (
          <g>
            {/* Empty bar with light gray color */}
            <rect
              x={x}
              y={y}
              width={width}
              height={height}
              fill="#F3F4F6" // Light gray fill for empty bars
              rx={4} // Rounded corners for visual consistency
              ry={4}
            />
            <rect
            x={x}
            width={width}
            height={borderHeight} // Fixed height for the border
            fill="none" // No fill for the border rectangle
            stroke="#E0E0E0"
            strokeWidth={1} // Border width
            rx={borderRadius2} // Horizontal border radius
            ry={borderRadius2} // Vertical border radius
          />
          </g>
        );
      }

    return (
        <g>
            <defs>
                <linearGradient id={`gradient${index}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={gradientColors[0]} />
                    <stop offset="100%" stopColor={gradientColors[1]} />
                </linearGradient>
            </defs>

            <path
                d={`
          M ${x + borderRadius},${y}
          L ${x + width - borderRadius},${y + slopeHeight}
          Q ${x + width},${y + slopeHeight} ${x + width},${y + slopeHeight + borderRadius}
          L ${x + width},${y + height - borderRadius}
          Q ${x + width},${y + height} ${x + width - borderRadius},${y + height}
          L ${x + borderRadius},${y + height}
          Q ${x},${y + height} ${x},${y + height - borderRadius}
          L ${x},${y + borderRadius}
          Q ${x},${y} ${x + borderRadius},${y}
        `}
                fill={fill}
                strokeWidth={1}
            />

            <line
                x1={x}
                y1={topLineY1}
                x2={x + width}
                y2={topLineY1 + slope} // Apply slope to create a descending line
                stroke={slopeColor}
                strokeWidth={1}
            />

            <rect
                x={x}
                width={width}
                height={borderHeight}
                fill="none"
                stroke="#E0E0E0"
                strokeWidth={1}
                rx={borderRadius2}
                ry={borderRadius2}
            />
        </g>
    );
};

// Custom Label Component
const CustomLabel = (props) => {
    const { x, y, width, value } = props;
    const VERTICAL_OFFSET = 280;
    return (
        <text
            x={x + width / 2}
            y={VERTICAL_OFFSET}
            fill="#6B7280"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "12px", color:"#000",fontFamily:'Plus Jakarta Sans',fontWeight:600 }}
            >
              {value}
              <tspan x={x + width / 2} dy="1.2em">{`Litre`}</tspan>
        </text>
    );
};

export default CommonGraph;
