import { Flex } from "antd";
import React from "react";

export default function FlexCol({ children, className, id, ref }) {
  return (
    <Flex
      id={id}
      // gap={gap}
      className={`${className} flex flex-col dark:text-white lg:gap-4 2xl:gap-[24px] gap-4`}
      ref={ref}
    >
      {children}
    </Flex>
  );
}