import React, { useState } from "react";
import cow from "../../assets/Images/cow.png";
import TableNodata from "./TableNodata";

function YieldingCattle({ cattleData = [], onCardClick }) {
  const [showAll, setShowAll] = useState(false);

  const calculateDateDifference = (expectedDate) => {
    const today = new Date();
    const milkedDate = new Date(expectedDate);

    today.setHours(0, 0, 0, 0);
    milkedDate.setHours(0, 0, 0, 0);

    const timeDiff = milkedDate - today;

    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    console.log(daysDiff, "days");

    return daysDiff;
  };

  // Show only the first 4 items or all based on the `showAll` state
  const displayedCattleData = showAll ? cattleData : cattleData.slice(0, 4);

  return (
    <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border border-[#E3E3E3] flex flex-col items-start gap-2.5">
      <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
        <div className="self-stretch flex justify-between items-center">
          <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">Top Yielding Cattle</div>
          {/* Button that toggles between showing all and showing just the first 4 */}
          {/* <div
                        onClick={() => onCardClick()}
                        className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans cursor-pointer">
                        See All
                    </div> */}
        </div>
        <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
          {displayedCattleData.length > 0 ? (
            displayedCattleData.map((item, index) => {
              const daysBetween = calculateDateDifference(
                item.latestMilkingDate
              );
              return (
                <div key={index} className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center ">
                  <div className="self-stretch flex justify-start items-start gap-5">
                    <div className="relative w-10 h-10">
                      <div className="absolute w-10 h-10 bg-[#16A34A] rounded-md" />
                      <img
                        className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                        src={cow}
                        alt={`Cattle ${item.Cattle.cattle_id}`}
                      />
                    </div>
                    <div className="flex flex-col justify-start items-start gap-1">
                      <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans">
                        Cattle No. {item.Cattle.cattle_id}
                      </div>
                      <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">
                        Last Recorded{" "}
                        {Math.abs(daysBetween) == 0
                          ? "Today"
                          : Math.abs(daysBetween) == 1
                            ? "Yesterday"
                            : `${Math.abs(daysBetween)} days ago`}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-1.5 p-2 bg-white rounded-md border border-[#E0E0E0]">
                    <div className="text-[#080808] text-xs font-bold leading-[19.2px] font-plus-jakarta-sans">
                      {item.totalLiters} ltr
                    </div>
                    {/* <div className="text-[#878787] text-xs font-bold leading-[19.2px] font-plus-jakarta-sans">
                                            + 12%
                                        </div> */}
                  </div>
                </div>
              );
            })
          ) : (
            // <div className="flex justify-center items-center w-full h-full text-center text-[#898483] text-xs font-normal leading-[16.8px]">
            //     No Data
            // </div>
            <TableNodata />
          )}
        </div>
      </div>
    </div>
  );
}

export default YieldingCattle;
