import React, { useState } from 'react'
import cow from "../../assets/Images/Cow icon.png";
import VaccinationReport from './VaccinationReport';
import { Link } from 'react-router-dom';
import TableNodata from '../Common/TableNodata';
function PendingVaccinations({ cattleData = [],onCardClick }) {
    const [showAll, setShowAll] = useState(false);

    const displayedCattleData = showAll ? cattleData : cattleData.slice(0, 4);
    const handleMarkVaccinated = (e, cattleId) => {
        e.stopPropagation(); // Prevent the card click event from firing
        onCardClick(cattleId);
    };

    return (
        <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border border-[#E3E3E3] flex flex-col items-start gap-2.5">
            <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
                <div className="self-stretch flex justify-between items-center">
                    <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">Pending vaccinations</div>
                    {/* <div className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans cursor-pointer"onClick={() => onCardClick()}>See All</div> */}
                </div>
                <div className="self-stretch h-full flex flex-col justify-start items-start gap-3">
                {displayedCattleData.length > 0 ? (
                        displayedCattleData.map((item, index) => {
                            return (
                        <div key={index} className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center cursor-pointer" onClick={() => onCardClick()}>
                            <div className="self-stretch flex justify-start items-start gap-2">
                                <div className="relative w-10 h-10">
                                    <div className="absolute w-10 h-10 bg-[#FDBF26] rounded-md" />
                                    <img
                                        className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                                        src={cow}
                                    //   alt={item.text}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-start gap-1">
                                    <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans capitalize"> Cattle No.{item.Cattle.cattle_id}</div>
                                    <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">Vaccination date: {item.vaccination_date}</div>
                                </div>
                            </div>
                             <div className="flex items-center gap-1.5 p-2 bg-white  rounded-md border border-[#E0E0E0] text:decoration-null">
                                <div className="text-[#080808] text-xs font-bold leading-[19.2px] font-plus-jakarta-sans "  onClick={(e) => handleMarkVaccinated(e, item.id)} >Mark as Vaccinated</div>

                            </div>
                            
                        </div>
                     );
                    })
                ) : (
                    // <div className="flex justify-center items-center w-full h-full text-center text-[#898483] text-xs font-normal leading-[16.8px]">
                    //     No Data
                    // </div>
                    <TableNodata/>
                )}
                </div>
            </div>
        </div>
    )
}

export default PendingVaccinations