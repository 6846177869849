import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Dropdownbtn from '../Common/Dropdownbtn'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import { Nav, NavItem, NavLink } from 'reactstrap'
import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import CommonButton from '../Common/Button'
import MultipleMilkEntries from '../Drawer/MultipleMilkEntries'
import API, { action } from '../../Api'
import TableAccordion from '../Common/TableAccordion'
import { Pagination } from 'antd'
import FileSaver from 'file-saver'
import { useLoader } from '../../LoaderContext'
function MilkReportMonitoring() {
    const [tab, setTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState("");
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };

    const [showMilkingStats, setShowMilkingStats] = useState(false);
    const [milk, setMilk] = useState(false)
    const [millkList, setMilkList] = useState([])
    const [updateMilk, setUpdateMilk] = useState([])
    const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const { setLoading } = useLoader();
    const handleButtonClick = () => {
        setShowMilkingStats(!showMilkingStats)
        getMilkReport()
    }
    const handleclick = (CattleID) => {
        setMilk(!milk)

        setUpdateMilk(CattleID)
        getMilkReport()
    }


    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Quantity",
            value: "quantity",

        },


        {
            id: 4,
            title: "Slot",
            value: "slot",


        },
        {
            id: 5,
            title: "Milking Date",
            value: "milking_date",


        },
        // {
        //     id: 6,
        //     title: "Heat Date",
        //     value: "HeatDate",


        // },
        {
            id: 6,
            title: "Action",
            value: "action",


        },



    ]
    const headingsformobile = [


        {
            id: 1,
            title: "Quantity",
            value: "quantity",

        },


        {
            id: 2,
            title: "Slot",
            value: "slot",


        },
        {
            id: 3,
            title: "Milking Date",
            value: "milking_date",


        },

    ]


    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            HeatType: "Artificial",
            HeatDate: "12-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            HeatType: "Natural",
            HeatDate: "11-10-2024",
            action: "Add Milking",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "06-09-2024",
            HeatType: "Artificial",
            HeatDate: "11-10-2024",
            action: "View Milk report",
        },
    ];



    const getMilkReport = async (page = currentPage, pageSize = 10) => {
        setLoading(true);
        const result = await action(API.GET_MILKING, {

            searchkey: searchTerm,
            page: page,
            pageCount: pageSize
        });
        setLoading(false);
        console.log(result.response.data, "get milk list")
        // setEmployeeLeave(result.result);
        setPaginationcount(result.response.count);
        setMilkList(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), // Format SlNo
            CattleID: item.Cattle.cattle_id
            , // Adjust according to your API response structure
            LactationNumber: item.name,
            LastCalvingdate: item.role,
            HeatType: item.mobile,
            CreatedDate: item.created_at,
            action: "View Report",
            quantity: item.quantity,
            slot: item.slot,
            milking_date: item.milking_date

        }))

        setMilkList(formattedData);
    };



    useEffect(() => {
        getMilkReport()
        console.log(millkList, 'milklist');

    }, [pageSize, currentPage, searchTerm])


    // const filteredUserList = (millkList || []).filter(user => {
    //     const CattleID = user.CattleID || ""; // Prevent null error

    //     return (
    //         CattleID.toLowerCase().includes(searchTerm.toLowerCase())

    //     )
    // });

    const handleExport = async () => {
        const result = await action(API.GET_MILKING_REPORT_EXPORT, {

            searchkey: searchTerm

        });
        const filecontent = result;

        const byteCharacters = atob(filecontent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const file = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        FileSaver.saveAs(file, "Milk Report.xlsx");
    }

    useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getMilkReport(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getMilkReport(currentPage, pageSize)
        }
    }, [searchTerm, tab, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getMilkReport(currentPage, pageSize)
        }
    }, [currentPage])
    const handlePageChange = (page) => {
        setCurrentPage(page)
        getMilkReport(page, pageSize)
    }
    return (
        <div style={{ backgroundColor: "#FDFDFD" }} className='h-[calc(100vh_-_135px)]'>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Milking Analytics' />
                        {/* <Dropdownbtn title='Today' style={{ backgroundColor: "#FAFAFA", color: "#5A5555", border: '1px #CCCBCB solid' }} /> */}
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm} onSearch={setSearchTerm} />
                        {/* <Filterbtn /> */}
                        <CommonButton title='Export' onClick={handleExport} />
                    </div>
                </div>

                {/* <Nav pills className="nav-success ">
                    {["All", "Inseminated", "Heated Cattle", "Pregnant", "Milking"].map((tabs, index) => (
                        
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav> */}
                {/* {tab === "1" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleclick} pagination={paginationcount} fetchData={getMilkReport} />}
                {tab === "2" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "3" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "4" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleclick} />}
                {tab === "5" && <ReactTable headings={headings} data={filteredUserList} headingcolor="#D9D9D9" handleAction={handleclick} />} */}
                {isMobile ? (
                    <TableAccordion headings={headingsformobile} cattleData={millkList} handleAction={handleclick} />
                ) : (
                    <ReactTable headings={headings} data={millkList} headingcolor="#D9D9D9" handleAction={handleclick} pagination={paginationcount} fetchData={getMilkReport} />
                )}
                {(millkList.length > pageSize || paginationcount > 10) && (
                    <div className="flex justify-end mt-4">
                        <Pagination
                            current={currentPage}
                            total={paginationcount}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            className="p-2 font-sans text-[#A0A0A0]"
                            itemRender={(page, type, originalElement) => {
                                if (type === "page") {
                                    return (
                                        <span
                                            className={`px-2 ${page === currentPage ? "font-bold text-black" : "font-normal"
                                                }`}
                                        >
                                            {page}
                                        </span>
                                    );
                                }
                                return originalElement;
                            }}
                        />
                    </div>
                )}

            </FlexCol>
            {/* <MultipleMilkEntries isOpen={showMilkingStats} toggle={handleButtonClick} /> */}
            <MultipleMilkEntries isOpen={milk} toggle={handleclick} selectedCattle={updateMilk} />
        </div>
    )
}

export default MilkReportMonitoring