import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "./ChartDaynamicColor";

const RevenueCharts = ({ dataColors, series ,type ="line" }) => {
    var linechartcustomerColors = getChartColorsArray(dataColors);
    const barColor = ['#16A34A', '#FED46E']; // Added a color for previous data

    var options = {
        chart: {
            height: 370,
            type: "bar", // Default to line for previous revenue
            toolbar: {
                show: false,
            },
            fontFamily:'Plus Jakarta Sans',
            
        },
        stroke: {
            curve: "smooth",
            width: [2, 2], // Adjust widths for both series
        },
        fill: {
       
             
            backgroundBarColors: [barColor],
      
        },
        markers: {
            size: [0, 0], // Hide markers for the bar, show for the line
            strokeWidth: 2,
            hover: {
                size: 6,
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                     fontFamily:'Plus Jakarta Sans',
                    
                },
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10,
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
             fontFamily:'Plus Jakarta Sans',
             
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                barHeight: "70%",
                endingShape: 'rounded',
                borderRadius: 5,
                
            },
        },
        colors: barColor,
        tooltip: {
            shared: true,
            intersect: false,
            yaxis: {
                formatter: function (value) {
                    return value.toFixed(2); // Ensure labels have two decimal places
                }
            },
            
        },
       
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                type={type} // Default type for line
                height="370"
                className="apex-charts text-black font-plus-jakarta-sans "
            />
        </React.Fragment>
    );
};

export default RevenueCharts;
