import React, { useState, useEffect } from 'react';

const CattleImageUpload = ({ onImageUpload, onImageDelete, existingImage }) => {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
const Imageurl = process.env.REACT_APP_MAIN_URL + "uploads/"
  // Effect to handle existing image
  useEffect(() => {
    if (existingImage) {
      setPreviewUrl(`${Imageurl}${existingImage}`);
      setImage(existingImage);
    }
  }, [existingImage]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      // Check if file is an image
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        alert(`${file.name} is not an image file`);
        return;
      }

      // Handle image preview
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      // Call parent handler
      onImageUpload?.(file);
    }
  };

  const handleDelete = () => {
    setImage(null);
    setPreviewUrl(null);
    onImageDelete?.();
  };

  return (
    <div className="w-full h-32">
      <p className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
        Cattle image
      </p>
      
      <div className="">
        {previewUrl ? (
          <div className="relative">
            <img 
              src={previewUrl} 
              alt="Preview" 
              className="w-full h-16 object-contain rounded-lg"
            />
          </div>
        ) : (
          <div className="w-full h-16 bg-gray-100 rounded-lg flex items-center justify-center">
            <span className="text-gray-400">No image</span>
          </div>
        )}
        
        <div className="flex gap-2 mt-2">
          <label className="flex-1">
            <input
              type="file"
              className="hidden"
              accept="image/jpeg,image/png,image/bmp,image/jpg"
              onChange={handleImageUpload}
            />
            <span className="block text-center py-1 px-3 bg-gray-50 hover:bg-gray-100 border border-gray-300 rounded-md cursor-pointer text-[16px] md:text-[12px] 2xl:text-[16px] font-medium text-black">
              Upload
            </span>
          </label>
          
          <button
            onClick={handleDelete}
            disabled={!image}
            className={`flex-1 py-1 px-3 rounded-md text-[16px] md:text-[12px] 2xl:text-[16px] font-medium
              ${image 
                ? 'bg-red-50 hover:bg-red-100 text-red-600 border border-red-300' 
                : 'bg-gray-50 text-gray-400 border border-gray-300 cursor-not-allowed'
              }`}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default CattleImageUpload;