import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';
import API, { action } from '../../Api';
import TableNodata from './TableNodata';

const PieChart = ({ title }) => {
  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]); // To hold the series data
  const [labels, setLabels] = useState([]); // To hold the labels
  const [hasNonZeroValues, setHasNonZeroValues] = useState(false);

  const getPiedata = async () => {
    try {
      const result = await action(API.GET_MONTHLY_DIAGNOSE_COUNT_COUNT, {});
      console.log(result.response.data, "get pie data");
  
      // Extract data from API response
      const percentages = result.response.data.percentages;
      const seriesData = [];
      const labelsData = [];
  
      // Add the percentages and corresponding labels dynamically
      // Object.keys(percentages).forEach(key => {
      //   seriesData.push(percentages[key]);

      let nonZeroFound = false;
  
      Object.keys(percentages).forEach(key => {
        const value = percentages[key];
        seriesData.push(value);
        if (value > 0) {
          nonZeroFound = true;
        }
  
        // Split the key into words and add "and" between them if there are two words
        const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(' '); // Add space before uppercase letters
        
        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        
        if (capitalizedWords.length === 2) {
          // If there are exactly two words, add "and" between them
          labelsData.push(`${capitalizedWords[0]} and ${capitalizedWords[1]}`);
        } else {
          // Otherwise, just join them
          labelsData.push(capitalizedWords.join(' '));
        }
      });
  
      setSeries(seriesData);
      setLabels(labelsData);
      setHasNonZeroValues(nonZeroFound);
    } catch (error) {
      console.error("Error fetching pie data:", error);
      setHasNonZeroValues(false);
    }
  };

  useEffect(() => {
    getPiedata();
  }, []);

  const options = {
    chart: {
      height: 300,
      type: 'pie',
    },
    colors: [
      '#16A34A', // green
      '#FDBF26', // yellow
      
    ],
    labels: labels, // Use the dynamic labels
    legend: {
      position: 'right',
      
    fontFamily: 'Plus Jakarta Sans',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val.toFixed(0) + "%"; // Display the percentage
      },
      textAnchor: 'middle',
      distributed: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#fff'], // Label color
        fontFamily: "Plus Jakarta Sans",
        width:50,
        height:50,
        padding:20,
      },
      background: {
        enabled: true,
        foreColor: '#000',
        // marginTop: 10,
        // marginLeft: 10,
        borderRadius: '50%', // Make the background circular
        borderWidth: 1,
        borderColor: '#fff',
        width:50,
        height:50,
        opacity: 1,
      },
      dropShadow: {
        enabled: true,
        top: 2,
        left: 2,
        blur: 4,
        opacity: 0.25,
      },
      offsetY: -20, // Move the label upwards
      offsetX: 0,
      
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 30, // Adjust this to move the labels outside of the pie
        },
      },
    },
    fill: {
      type: 'solid',
    },
  };

  return (
    <React.Fragment>
      <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300 min-h-[435px]">
        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div className="border-0 align-items-center d-flex ml-5">
              <h4
                className="card-title mb-0 flex-grow-1 mt-2 2xl:text-[20px] text-[14px]"
                style={{
                  color: "#495057",
                  fontFamily: "Plus Jakarta Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                {title}
              </h4>
            </div>
            {hasNonZeroValues? (
            <div className="chart-container" style={{ position: "relative", marginTop: "50px" }}>
              <ReactApexChart
                dir="ltr"
                className="apex-charts font-plus-jakarta-sans text-#767676"
                series={series} // Use dynamic series
                options={options}
                type="donut"
                height={370}
                
              />
            </div>
            ):(
              <div className='mt-8'>

              <TableNodata/>
              </div>
              )}
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default PieChart;


// import React from 'react';
// import { CardBody } from 'reactstrap';
// import { PieChart, Pie, Cell, Legend } from 'recharts';

// const PieChart1 = ({title}) => {
//   const data = [
//     { name: 'Issue 1', value: 60, color: '#4CAF50' },
//     { name: 'Foot & Mouth', value: 20, color: '#FFC107' },
//     { name: 'Other', value: 20, color: '#03A9F4' }
//   ];

//   return (
//     <React.Fragment>
//           <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300">
//              <CardBody className="p-0 pb-2">
//                <div className="w-100">
//                  <div className="border-0 align-items-center d-flex ml-5">
//                    <h4
//                     className="card-title mb-0 flex-grow-1 mt-2 2xl:text-[20px] text-[14px]"
//                     style={{
//                       color: "#495057",
//                       fontFamily: "Plus Jakarta Sans",
//                       fontStyle: "normal",
//                       fontWeight: 700,
//                       lineHeight: "normal",
//                     }}
//                   >
//                     {title}
//                   </h4>
//                 </div>
//                 <div className="chart-container" style={{ position: "relative", marginTop: "50px" }}>
      
//       <PieChart width={400} height={300}>
//         <Pie
//           data={data}
//           cx="50%"
//           cy="50%"
//           innerRadius={60}
//           outerRadius={130}
//           paddingAngle={0}
//           dataKey="value"
//           label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
//             const RADIAN = Math.PI / 180;
//             // Calculate position for the percentage label
//             const radius = (innerRadius + outerRadius) / 2;
//             const x = cx + radius * Math.cos(-midAngle * RADIAN);
//             const y = cy + radius * Math.sin(-midAngle * RADIAN);
            
//             return (
//               <g>
//                 {/* White circle background */}
//                 <circle cx={x} cy={y} r={26} fill="white"/>
//                 {/* Percentage text */}
//                 <text 
//                   x={x} 
//                   y={y} 
//                   textAnchor="middle" 
//                   dominantBaseline="central"
//                   className="font-semibold"
//                 >
//                   {`${value}%`}
//                 </text>
//               </g>
//             );
//           }}
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={entry.color} />
//           ))}
//         </Pie>
//         <Legend
//           verticalAlign="middle"
//           align="right"
//           layout="vertical"
//           iconType="circle"
//           formatter={(value, entry) => (
//             <span className="text-sm ">{value}</span>
//           )}
//         />
//       </PieChart>
    
//     </div>
//            </div>
//          </CardBody>
//        </div>
//    </React.Fragment>
//   );
// };

// export default PieChart1;