import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from 'yup';
import API, { action } from '../../Api';
import { Flex, notification } from 'antd';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import FormInput from '../Common/FormInput';
import DropdownButton from '../Common/DropdownButton';
import CommonButton from '../Common/Button';
import DateSelect from '../Common/DateSelector';
import TextArea from '../Common/TextArea';
function AddState({ isOpen, toggle, selectedData,Api }) {
    console.log(selectedData?.Selected?.cattle_id, "selectedData");

    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            cattle_id: selectedData?.Selected?.cattle_id,
            state: "",
            description: "",
            date: "",
        },
        validationSchema: Yup.object({
            state: Yup.string().required('State is required'),
            description: Yup.string()

                .required('Description is required'),
            date: Yup.string().required('Date is required'),

        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {

                await action(API.ADD_BREEDING_STATE, {
                    cattle_id: values.cattle_id,
                    state: values.state,
                    description: values.description,
                    added_on: values.date,
                });


                notification.success({ message: "New State added successfully.", placement: "top" });

                formik.resetForm();
                Api()
                toggle();
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top" });
            } finally {
                setLoading(false);
            }
        },
    });

    const stateOptions =
        [{ value: "milking", label: "Milking" },
        { value: "dry", label: "Dry" }]



    return (
        <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className="p-4">
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Add State
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Cattle State Details
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit} >
                        <FormGroup>
                            <FlexCol gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <DropdownButton
                                        title="State"
                                        placeholder="Enter State"
                                        SelectName="Select State"
                                        value={formik.values.state}
                                        options={stateOptions}
                                        change={(e) => formik.setFieldValue("state", e)}
                                        error={formik.touched.state && formik.errors.state}
                                        required={true}
                                    />
                                    <DateSelect
                                        title="Date"
                                        placeholder="Choose Date"
                                        value={formik.values.date}
                                        change={(e) => formik.setFieldValue("date", e)}
                                        error={formik.touched.date && formik.errors.date}
                                        required={true}
                                    />
                                    <TextArea
                                        title="Description"
                                        placeholder="Enter Description"
                                        value={formik.values.description}
                                        change={(e) => formik.setFieldValue("description", e)}
                                        error={formik.touched.description && formik.errors.description}
                                        required={true}

                                    />

                                </Flex>
                            </FlexCol>
                        </FormGroup>

                        <div className="flex justify-between mt-8">
                            <CommonButton
                                style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", marginRight: "8px", fontSize: "12px" }}
                                onClick={toggle}
                                outline
                                title='Cancel'
                                disabled={loading}
                            />
                            <CommonButton title="Save" style={{ fontSize: "12px" }} type="submit" disabled={loading} />

                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}


export default AddState