import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LoaderProvider } from './LoaderContext';
import Loader from './Components/Common/Loader';

// Add Remix Icon CSS
const remixLink = document.createElement('link');
remixLink.rel = 'stylesheet';
remixLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/remixicon/2.5.0/remixicon.min.css';
document.head.appendChild(remixLink);

// Add Material Design Icons CSS
const mdiLink = document.createElement('link');
mdiLink.rel = 'stylesheet';
mdiLink.href = 'https://cdn.materialdesignicons.com/5.4.55/css/materialdesignicons.min.css';
document.head.appendChild(mdiLink);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LoaderProvider>
  <React.StrictMode>
    <App />
    <Loader />
  </React.StrictMode>
  </LoaderProvider>
);

// Performance measuring
reportWebVitals();
