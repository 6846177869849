import React from 'react';
import { Select } from 'antd';
import { FaAsterisk } from 'react-icons/fa';
import '../../assets/css/style.css'

const MultiSelector = ({
  title = "",
  options = [],
  onChange = () => {},
  onSearch = () => {},  // Make sure prop name is onChange
  defaultValue = [],
  className = "",
  error = "",
  required = false,
  disabled = false,
  placeholder = "Select one or more options",
  value = [], // Ensure this is an array for selected values
}) => {


  const formatLabel = (label) => {
    if (typeof label === 'string') {
      return label.charAt(0).toUpperCase() + label.slice(1);
    }
    return label; // Return as-is if it's not a string (e.g., number)
  };
  return (
    <div className={`flex flex-col ${title ? "gap-2" : "gap-0 items-center"} ${className}`}>
      {/* Title and required indicator */}
      <div className="flex items-center dark:text-white gap-0.5">
        {title && (
          <label className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
            {title}
          </label>
        )}
        {required && <FaAsterisk className="text-[6px] text-rose-600" />}
      </div>

      {/* MultiSelector Input */}
      <Select
        mode="multiple"
        value={value} 
        placeholder={placeholder}
        className={`w-full font-plus-jakarta-sans !rounded-lg ${error ? "border-rose-400" : ""}`}
        defaultValue={defaultValue}
        onChange={onChange}  
        onSearch={onSearch}
        optionLabelProp="label"
        status={error ? "error" : ""}
        dropdownStyle={{
          color: '#5A5555',
          fontFamily: 'Plus Jakarta Sans',
          fontSize: "10px"
        }}
        size="medium"
        disabled={disabled}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value} label={formatLabel(option.label)}>
            {formatLabel(option.label)}  {/* Only show the label */}
          </Select.Option>
        ))}
      </Select>

      {/* Error message */}
      {error && (
        <p className="flex justify-start items-center mt-2 my-1 mb-0 text-[10px] text-red-600">
          <span className="text-[10px] pl-1">{error}</span>
        </p>
      )}
    </div>
  );
};

export default MultiSelector;
