import React from 'react';
import { Spin } from 'antd';
import { useLoader } from '../../LoaderContext';


const Loader = () => {
  const { loading } = useLoader();

  if (!loading) return null; // Don't render the loader if not loading

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 9999,
      }}
    >
      <Spin size="large"  />
    </div>
  );
};

export default Loader;
