import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import Chart from "react-apexcharts";
import ci from "../../assets/Images/ci_expand.svg";
import TableNodata from "./TableNodata";

const Commonbarchart = ({
  title,
  bgcolor,
  bgcolor2,
  bgcolor3,
  img,
  data = [],
  type,
}) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = new Date().getMonth(); // Gets current month index (0 for Jan, 11 for Dec)
  const startMonth = (currentMonth - 5 + 12) % 12; // Start from 5 months ago to show the past 6 months

  // Create an array of months for the past 6 months
  const months = Array.from(
    { length: 6 },
    (_, index) => (startMonth + index) % 12
  );

  // Prepare an array to store the final filtered data, making sure we account for all 6 months
  const filteredData = months.map((monthIndex) => {
    // Correct the month index for comparison (1 for Jan, 2 for Feb, etc.)
    const actualMonth = monthIndex + 1; // +1 to adjust for zero-indexed month

    // Find data for the current month or handle null
    const monthData = data.find(
      (item) => item.month === actualMonth || item.month === null
    );

    // If there's no data for this month, return zero values
    if (!monthData) {
      return {
        month: monthNames[monthIndex], // Default to the month name
        pregnant: 0,
        inseminated: 0,
        re_inseminated: 0,
        Calf: 0,
        // Heifer: 0,
        // Bull: 0,
      };
    }

    // If monthData has null, use the default month
    if (monthData.month === null) {
      return {
        month: monthNames[monthIndex], // Default to the current month or first month
        pregnant: monthData.pregnant || 0,
        inseminated: monthData.inseminated || 0,
        re_inseminated: monthData.re_inseminated || 0,
        Calf: monthData.Calf || 0,
        // Heifer: monthData.Heifer || 0,
        // Bull: monthData.Bull || 0,
      };
    }

    // Return the actual data for the matched month
    return {
      month: monthNames[monthIndex], // The current month's name
      pregnant: monthData.pregnant || 0,
      inseminated: monthData.inseminated || 0,
      re_inseminated: monthData.re_inseminated || 0,
      Calf: monthData.Calf || 0,
      // Heifer: monthData.Heifer || 0,
      // Bull: monthData.Bull || 0,
    };
  });

  // Generate categories (months)
  const categories = filteredData.map((item) => item.month);

  // Dynamic series based on the type
  const chartSeries =
    type === "insemination"
      ? [
          { name: "Pregnant", data: filteredData.map((item) => item.pregnant) },
          {
            name: "Inseminated",
            data: filteredData.map((item) => item.inseminated),
          },
          {
            name: "Re-inseminated",
            data: filteredData.map((item) => item.re_inseminated),
          },
        ]
      : [
          { name: "Calf", data: filteredData.map((item) => item.Calf) },
          // { name: "Heifer", data: filteredData.map((item) => item.Heifer) },
          // { name: "Bull", data: filteredData.map((item) => item.Bull) },
        ];

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: { show: false },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#878787",
          fontSize: "12px",
          fontFamily: "Plus Jakarta Sans",
          fontWeight: "400",
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    colors: [bgcolor, bgcolor2, bgcolor3], // Colors for the different series
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        columnWidth: "40%",
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: "light", // You can use 'dark', 'light', or 'custom'
      style: {
        fontSize: "14px",
        fontFamily: "Plus Jakarta Sans",
        color: "#fff", // Text color inside the tooltip
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesName = w.globals.seriesNames[seriesIndex];
        const value = series[seriesIndex][dataPointIndex];
        const category = w.globals.labels[dataPointIndex];
        const seriesColor = chartOptions.colors[seriesIndex];
        console.log(seriesColor, w);

        return `
          <div
            style="
              border: 1px solid rgb(213, 218, 218);
              border-radius: 5px;
              background-color: #fff;
              color: #000;
              overflow: hidden;
            "
          >
            <div
              style="
                font-weight: bold;
                padding: 10px;
                background-color: rgb(213, 218, 218);
                border-bottom: 1px solid rgb(218, 214, 214);
              "
            >
              ${category}
            </div>
            <div style="padding: 10px; display: flex; align-items: center; gap:10px;">
              <div
                style="
                  height: 10px;
                  width: 10px;
                  border-radius: 50%;
                  background-color: ${seriesColor};
                "
              ></div>
              <div>${seriesName} : ${value}</div>
            </div>
          </div>
        `;
      },
    },
  };
  const hasValidData = data.length > 0 ;
  return (
    <div className="2xl:p-6 p-3 w-full  relative bg-white rounded-xl border-1 border-gray-300">
      <CardBody>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 rounded mb-2">
              <img src={img} alt="Icon" />
            </div>
            <h2 className="text-sm font-bold text-[#080808] font-plus-jakarta-sans">
              {title}
            </h2>
          </div>
          {/* <div className="bg-white rounded-md border border-gray-300 flex items-center w-14 h-7">
            <img src={ci} className="w-7 h-5" alt="Expand Icon" />
            <p className="ml-1 mt-1 text-[#878787]">...</p>
          </div> */}
        </div>
        {hasValidData ? (
                <>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={250}
        />
        </>
        ) : (
          <TableNodata />
        )}
        
      </CardBody>
    </div>
  );
};

export default Commonbarchart;
