import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import { Nav, NavItem, NavLink } from 'reactstrap'
import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import RecordVaccine from '../Drawer/RecordVaccine'
import API, { action } from '../../Api'
import TableAccordion from '../Common/TableAccordion'
import DateFilter from '../Common/DateFilter'
import { useLocation, useNavigate } from 'react-router'
import { Pagination } from 'antd'
import CommonButton from '../Common/Button'
import VaccinationReportViewForm from './VaccinationReportViewForm'
import { useLoader } from '../../LoaderContext'
import FileSaver from 'file-saver'


function VaccinationReportMonitoring() {
    const location = useLocation();
    const [vaccine, setVaccine] = useState(false)
    const [vaccinationList, setVeccinationList] = useState([])
    const [tab, setTab] = useState(location.state?.tab || "1")
    const [selectedData, setSelectedData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [paginationcount, setPaginationcount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [datefilter, setDatefilter] = useState()
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const navigate = useNavigate()
    const { setLoading } = useLoader();
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs)
        }
    }

    const handleActionClick = (userId) => {
        setVaccine(!vaccine)
        setSelectedData(userId)
        getVaccinationReport(currentPage, pageSize)
    }

    const headings = [
        {
            id: 1,
            title: "Sl No",
            value: "SlNo",
        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon
        },
        {
            id: 3,
            title: "Vaccination Name",
            value: "VaccinationName",
        },
        {
            id: 4,
            title: "Vaccination Date",
            value: "LastVaccinated",
        },
        {
            id: 5,
            title: "Health Status",
            value: "HealthStatus",
        },
        {
            id: 6,
            title: "Status",
            value: "status",
        },
        {
            id: 7,
            title: "Action",
            value: "action",
        },
    ]

    const headingsformobile = [
        {
            id: 1,
            title: "Vaccination Name",
            value: "VaccinationName",
        },
        {
            id: 2,
            title: "Last Vaccinated",
            value: "LastVaccinated",
        },
        {
            id: 3,
            title: "Health Status",
            value: "HealthStatus",
        },
        {
            id: 4,
            title: "Status",
            value: "status",
        },
    ]

    const getVaccinationReport = async (page = currentPage, size = pageSize) => {
        try {
            setLoading(true);
            const result = await action(API.GET_VACCINATION, {
                searchkey: searchTerm,
                page: page,
                pageCount: size,
                date: datefilter,
                type: tab === "1" ? null : tab === "2" ? 1 : tab === "3" ? 0 : null
            })
            setLoading(false);
            setPaginationcount(result.response.count)
            
            const formattedData = result.response.data.map((item, index) => ({
                SlNo: ((page - 1) * size + index + 1).toString().padStart(2, '0'),
                CattleID: item.Cattle.cattle_id,
                VaccinationName: item.vaccination_name,
                LastVaccinated: item.vaccination_date,
                HealthStatus: item.health_status,
                status: item.status,
                action:"View Report", 
                dosage: item.dosage,
                vaccination_type: item.vaccination_type,
                id: item.id
            }))

            setVeccinationList(formattedData)
        } catch (error) {
            console.error("Error fetching vaccination report:", error)
        }
    }




    const handleExport = async () => {
        const result = await action(API.GET_VACCINATION_REPORT_EXPORT, {
            type: tab === "1" ? null : tab === "2" ? 1 : tab === "3" ? 2: null,
            searchKey: searchTerm

        });
        const filecontent = result;

        const byteCharacters = atob(filecontent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        const file = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        FileSaver.saveAs(file, "report name.xlsx");
    }


    const handlePageChange = (page) => {
        setCurrentPage(page)
        getVaccinationReport(page, pageSize)
    }

    useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getVaccinationReport(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getVaccinationReport(currentPage, pageSize)
        }
    }, [searchTerm, datefilter, tab, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getVaccinationReport(currentPage, pageSize)
        }
    }, [currentPage])

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleUserClick = () => {
        // navigate("/cattleDashboard")
    }

    return (
        <div style={{ backgroundColor: "#FDFDFD" }} className='h-[calc(100vh_-_135px)]'>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Vaccination Report' />
                    </div>
                    <div className="d-flex space-x-5">
                        <DateFilter 
                            selectedDate={datefilter} 
                            change={(e) => setDatefilter(e)} 
                            placeholder="Choose Date Filter" 
                            value={datefilter} 
                        />
                        <SearchBox 
                            placeholder='Search Cattle' 
                            className='bg-[#F8F9FB]' 
                            value={searchTerm} 
                            onSearch={setSearchTerm} 
                        />
                        {/* <Filterbtn /> */}
                        
                        <CommonButton title='Export' onClick={handleExport} />
                    </div>
                </div>

                <Nav pills className="nav-success">
                    {["All", "Vaccinated", "Pending"].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>

                {isMobile ? (
                    <TableAccordion headings={headingsformobile} cattleData={vaccinationList} />
                ) : (
                    <div>
                        <ReactTable
                            headings={headings}
                            data={vaccinationList}
                            headingcolor="#D9D9D9"
                            handleAction={handleActionClick}
                            handleUser={handleUserClick}
                        />
                        {(vaccinationList.length > pageSize || paginationcount > 10) && (
                            <div className="flex justify-end mt-4">
                                <Pagination
                                    current={currentPage}
                                    total={paginationcount}
                                    pageSize={pageSize}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                    className="p-2 font-sans text-[#A0A0A0]"
                                    itemRender={(page, type, originalElement) => {
                                        if (type === "page") {
                                          return (
                                            <span
                                              className={`px-2 ${
                                                page === currentPage ? "font-bold text-black" : "font-normal"
                                              }`}
                                            >
                                              {page}
                                            </span>
                                          );
                                        }
                                        return originalElement;
                                      }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </FlexCol>
            <VaccinationReportViewForm 
                isOpen={vaccine} 
                toggle={handleActionClick} 
                selectedData={selectedData} 
            />
        </div>
    )
}

export default VaccinationReportMonitoring