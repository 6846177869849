import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Dropdownbtn from '../Common/Dropdownbtn'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import ReactTable from '../Common/Table'
import cowicon from "../../assets/Images/Cow icon.png"
import CommonButton from '../Common/Button'
import Cards from '../Common/Cards'
import CommonGraph from '../Common/CommonGraph'
import img from '../../assets/Images/Frame2.svg'
import RecordVaccine from '../Common/RecordVaccine'
import Commonbarchart from '../Common/Commonbarchart'
import vaccinechart from '../../assets/Images/vaccinechart.svg'
import VaccinationReport from './VaccinationReport'
import PendingVaccinations from './PendingVaccinations'
import Recordvaccine from '../Common/RecordVaccine'
import API, { action } from '../../Api'
import VaccinationReportChart from './VaccinationReportChart'
import DailyVaccinationChart from './DailyVaccinationChart'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { notification } from 'antd'
import { useLoader } from '../../LoaderContext'
function Vaccination() {


    const [showVaccinationReport, setShowVaccinationReport] = useState(false);
    const [dashboarddata, setDashboarddata] = useState([])
    const [pendingVaccination, setPendingVaccination] = useState([])
    const [id, setid] = useState()
    const [filter, setFilter] = useState("today")
    const navigate = useNavigate()
    const { setLoading } = useLoader();
    const options = [{ value: "today", label: "Today" }, { value: "this_month", label: "This Month" }, { value: "this_year", label: "This Year" }]
    const handleButtonClick = (item) => {
        // setShowVaccinationReport(true);
        if (item.label === "Dewormed") {
            navigate("/vaccination/deworming-report", { state: { tab: "2" } })
        }
        if (item.label === "Deworming Overdue") {
            navigate("/vaccination/deworming-report", { state: { tab: "4" } })
        }
        if (item.label === "Vaccination Overdue") {
            navigate("/vaccination/vaccination-report", { state: { tab: "4" } })
        }
        if (item.label === "Vaccinated") {
            navigate("/vaccination/vaccination-report", { state: { tab: "2" } })
        } else {

        }
    };


    const handleDewormingClick = () => {
        navigate("/vaccination/deworming-report")
    }
    const handleClickRecord = () => {
        navigate("/vaccination/vaccination-report")
    }

    const carddata = [
        {
            id: 1,
            cardColor: "primary",
            label: "Vaccinated",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter: dashboarddata.vaccinated_count || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Vaccination Overdue",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "16",
            counter: dashboarddata.due_vaccine_count || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Dewormed",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter: dashboarddata.dewormed_cattles || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Deworming Overdue",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: dashboarddata.due_deworm_count || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },




    ];

    const gradientColors = [
        "rgba(94.67, 212, 84, 0.55)", // Starting color
        "rgba(255, 255, 255, 0)"      // Ending color
    ];



    const getVaccinationDashboardData = async () => {
        setLoading(true);
        const result = await action(API.GET_VACCINATION_DASHBOARD, {
            filterType: filter
        });
        setLoading(false);
        console.log(result.response.data, 'get dashboard data');
        setDashboarddata(result.response.data);
    };

    useEffect(() => {
        getVaccinationDashboardData();
    }, [filter]);

    const getPendingVaccinationData = async () => {
        const result = await action(API.GET_PENDING_VACCINATION, {
            page: 1,
            pageCount: 10
        });
        console.log(result.response.data, 'get pending vaccination data');
        setPendingVaccination(result.response.data);
    };

    useEffect(() => {
        getPendingVaccinationData();
    }, []);



    const handleClick = async (cattleId) => {
        if (cattleId) {
            try {
                const result = await action(API.UPDATE_VACCINATION, {
                    id: cattleId,
                    status: "recorded"
                });
                notification.success({ message: result.response?.message, placement: "top" });
                getPendingVaccinationData(); // Refresh the pending vaccinations list
                getVaccinationDashboardData()
            } catch (error) {
                console.error("Error updating status:", error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top" });
            }
        }
    };

    const handleVaccinationDrawerClose = () => {
        getVaccinationDashboardData();
        getPendingVaccinationData();
    };

    return (


        <div style={{ backgroundColor: "#FDFDFD" }}>
            {!showVaccinationReport && (
                <FlexCol>
                    <div className="d-flex justify-content-between ">
                        <div className='d-flex space-x-2'>
                            <Heading title='Vaccination & Deworming' />
                            <Dropdownbtn style={{ backgroundColor: '#FAFAFA', color: '#5A5555', border: '1px #CCCBCB solid', paddingTop: '1px', paddingBottom: '1px', paddingLeft: "2px", paddingRight: "2px" }} options={options} onSelect={(value) => setFilter(value)} />
                        </div>
                        <div className='d-flex d-flex space-x-5'>
                            <CommonButton onClick={handleDewormingClick} title='Deworming Records' style={{ backgroundColor: "white", color: "#E4626F", border: " 1px #E4626F solid" }} />
                            <CommonButton onClick={handleClickRecord} title='Vaccination Records' style={{ backgroundColor: "white", color: "#E4626F", border: " 1px #E4626F solid" }} />
                        </div>
                    </div>


                    <Cards data={carddata} cardClick={handleButtonClick} />

                    <Row className="gx-3 gy-4">
                        <Col xl={8}>
                            <DailyVaccinationChart
                                title="Daily Vaccination Summary"
                                barColor="#FDBF26"
                                slopeColor="#FDBF26 "
                                gradientColors={["rgba(212, 182.13, 84, 0.55) ", "rgba(255, 255, 255, 0)"]}
                                img={img}
                                Selected={dashboarddata}
                            />
                        </Col>
                        <Col xl={4} >
                            <Recordvaccine onDrawerClose={handleVaccinationDrawerClose} />
                        </Col>
                    </Row>
                    <Row className="gx-3 gy-4">
                        <Col xl={5}>
                            {/* <Commonbarchart title="Monthly Vaccination Summary" bgcolor="#FDBF26" img={vaccinechart} data={dashboarddata} /> */}
                            <VaccinationReportChart cattleData={dashboarddata} />
                        </Col>
                        <Col xl={7} >
                            <PendingVaccinations cattleData={pendingVaccination} onCardClick={handleClick} />
                        </Col>
                    </Row>
                </FlexCol>
            )}
            {showVaccinationReport && <VaccinationReport />}

        </div>
    )
}


export default Vaccination