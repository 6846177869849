import React from 'react'
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';
import FlexCol from './FlexCol';
// import FeatherIcon from "feather-icons-react";
function Cards({ data = [], style = {}, cardClick,filter="" }) {

    const isLargeScreen = window.innerWidth >= 1440;
    const cardStyle = {
        padding: isLargeScreen ? '12px 18px' : '16px 24px',

        alignItems: 'center',
        borderRadius: '12px',

        backgroundColor: '#E4626F',
        width: '143px',
        height: "100%",
        display: 'flex', // Ensure flex properties work
        flexDirection: 'column', // Align children vertically
        ...style,
    };

    const carddata = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Orders",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "72",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Customers",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "52",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "My Balance",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "81",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 1,
            cardColor: "primary",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Orders",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "72",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Customers",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "52",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "My Balance",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "81",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
    ];
    const cardDataToDisplay = data.length > 0 ? data : carddata;
    return (



        <React.Fragment>
            <Row className="">
                {cardDataToDisplay.map((item, key) => (
                    <Col xs="12" sm="6" md="6" lg="6" xl='3' className="px-2 mb-3">
                        <Card className={`card-animate 2xl:pr-[32px] 2xl:pt-[24px] 2xl:pb-[24px] 2xl:pl-[32px] w-full h-full flex flex-col justify-between cursor-pointer 
                                        
                                        hover:border-red-500`}
                            style={{
                                cardStyle,
                                background: 'defaultColor',
                                color: item.label === 'Issue Cattle' ? '#C03744' : 'defaultColor',
                                borderRadius: 12, // Adds border radius
                            }}
                            onClick={() => cardClick(item)}
                        >
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium mb-0 text-[18px] md:text-[14px] xl:text-base 2xl:text-[18px] xxl-font sm:text-[10px] " style={{
                                            color: item.label === 'Issue Cattle' ? '#C03744' : '#898483',
                                            fontFamily: "Plus Jakarta Sans",
                                            wordWrap: 'break-word',
                                            fontWeight: 700,

                                        }}>
                                            {item.label}
                                        </p>
                                        <h2 className="ff-secondary fw-semibold" style={{
                                            fontFamily: "Plus Jakarta Sans",
                                            fontSize: "32px",
                                            fontWeight: 700,
                                            lineHeight: "120%",
                                            color: item.label === 'Issue Cattle' ? '#C03744' : 'defaultColor'
                                        }}>
                                            <span className="counter-value">
                                                {item.label === "Total Milking" ? (
                                                    <>
                                                        {item.counter}
                                                        <span className='text-[#898483] text-[24px] font-medium '> ltr</span>
                                                    </>
                                                ) : (
                                                    item.counter
                                                )}


                                            </span>
                                        </h2>
                                        <p className="mb-0 md:text-[12px] text-[16px] 2xl:text-[16px] " style={{
                                            fontFamily: "Plus Jakarta Sans",

                                            fontWeight: 700,
                                            lineHeight: "120%",
                                            color:'#898483',
                                        }}>
                                            <span className={`badge mb-0 md:text-[12px] text-[16px] 2xl:text-[20px] ${parseFloat(item.percentage) < 0 ? 'text-danger' : 'text-success'
                                                }`}
                                                style={{ color: '#898483' }}>
                                                {item.percentage}{(item.percentage)?"%":""}
                                            </span> {filter}
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
};

export default Cards