import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';

function CommonButton({ title = "", style = {}, onClick ,disabled = false }) {
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 360) {
        setFontSize('8px'); // Very small screens (phones)
      } else if (screenWidth <= 480) {
        setFontSize('8px'); // Small phones
      } else if (screenWidth <= 600) {
        setFontSize('8px'); // Medium phones
      } else if (screenWidth <= 768) {
        setFontSize('9px'); // Tablets and small screens
      } else if (screenWidth <= 1024) {
        setFontSize('10px'); // Medium tablets and small laptops
      } else if (screenWidth <= 1280) {
        setFontSize('11px'); // Laptops and small desktops
      } else if (screenWidth <= 1440) {
        setFontSize('12px'); // Larger laptops and desktops
      } else {
        setFontSize('18px'); // Very large screens
      }
    }

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultStyle = {
    borderRadius: '4px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: fontSize,
    fontStyle: 'normal',
    
    
    backgroundColor: "#16A34A",
    color: "#ffff",
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  return (
    <div>
      <Button color="#16A34A" style={{ ...defaultStyle, ...style }} onClick={onClick}  disabled={disabled}>
        {title}
      </Button>
    </div>
  );
}

export default CommonButton;
