import React, { useEffect, useState } from "react";
import CattleDashboardSideBar from "../Common/CattleDashboardSideBar";
import FlexCol from "../Common/FlexCol";
import Heading from "../Common/Heading";
import CommonButton from "../Common/Button";
import SearchBox from "../Common/SearchBox";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import BasicInformation from "./Tabs/BasicInformation";
import Vaccination from "./Tabs/Vaccination";
import Filterbtn from "../Common/Filterbtn";
import Breeding from "./Tabs/Breeding";
import Milking from "./Tabs/Milking";
import CommonGraph from "../Common/CommonGraph";
import icon from "../../assets/Images/Frame.svg";
import DietPlan from "./Tabs/DietPlan";
import Addcattle from "../Drawer/Addcattle";
import API, { action } from "../../Api";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import TableNodata from "../Common/TableNodata";
import { useLoader } from "../../LoaderContext";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";

function CattleDashboard() {
  const style = {
    color: " #898483",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "120%",
    padding: "0 5px",
  };
  const { setLoading } = useLoader();
  const [pillsTab, setpillsTab] = useState("basic-info");
  const pillsToggle = (tabId) => {
    if (pillsTab !== tabId) {
      setpillsTab(tabId);
    }
  };

  const [addcattle, setAddcattle] = useState(false);
  const handleclick = () => {
    setAddcattle(!addcattle);
    if (addcattle) {
      setCurrentPage(1); // Reset page to 1
      setCattleList([]); // Clear previous list
      getCattleList(); // Fetch updated list
    }
  };
  const [selectedCattle, setSelectedCattle] = useState({});
  const [cattleList, setCattleList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true); // Track if more data exists
  const [cattleCount , setCattleCount]= useState()
  const navigate= useNavigate()
  const handleCattleSelect = (cattleData) => {
    setSelectedCattle(cattleData);
    setpillsTab("basic-info")
    console.log("Selected Cattle:", cattleData);
  };
  console.log(selectedCattle, "selectedCattle");
  console.log(cattleList, "cattlelist");

  // Function to fetch and set cattle list
  const getCattleList = async () => {
    setLoading(true);
    try {
      const result = await action(API.CATTLE_LIST, {
        searchKey: searchTerm,
        page: currentPage,
        pageCount: 200,
        
      });
      setLoading(false);
      const newCattleList = result.response.data;
      setCattleCount(result.response?.count)
      if (newCattleList.length === 0) {
        setHasMoreData(false);
      } else {
        setHasMoreData(true);
        setCattleList([...newCattleList]); 
      
      }
    } catch (error) {
      console.error("Error fetching cattle list:", error);
    }
  };

  useEffect(() => {
    getCattleList(); // Fetch cattle list on component mount or when page changes
  }, [currentPage,searchTerm]);

  const filteredUserList = (cattleList || []).filter((user) => {
    const name = user.cattle_id || ""; // Prevent null error
    return name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleNextCattle = () => {
    const currentIndex = filteredUserList.findIndex(
      (cattle) => cattle.cattle_id === selectedCattle.cattle_id
    );

    // Check if there's a next cattle in the list
    if (currentIndex < filteredUserList.length - 1) {
      const nextCattle = filteredUserList[currentIndex + 1];
      handleCattleSelect(nextCattle); // Use existing handleCattleSelect method
    }
  };

  const handlePreviousCattle = () => {
    const currentIndex = filteredUserList.findIndex(
      (cattle) => cattle.cattle_id === selectedCattle.cattle_id
    );

    // Check if there's a previous cattle in the list
    if (currentIndex > 0) {
      const previousCattle = filteredUserList[currentIndex - 1];
      handleCattleSelect(previousCattle); // Use existing handleCattleSelect method
    }
  };


  const getTabs = () => {
    const tabs = [
      { id: "basic-info", label: "Basic Information", component: <BasicInformation Selected={selectedCattle} getCattleList={getCattleList} /> },
      { id: "vaccination", label: "Vaccination", component: <Vaccination Selected={selectedCattle} /> },
      { id: "treatments", label: "Treatments", component: <DietPlan Selected={selectedCattle} /> }
    ];

    // Add Breeding tab and Milking tab for cattle_type 1
    if (selectedCattle.cattle_type === 1) {
      tabs.splice(1, 0, {
        id: "breeding",
        label: "Breeding",
        component: <Breeding Selected={selectedCattle} />
      });

      tabs.splice(2, 0, {
        id: "milking",
        label: "Milking",
        component: <CommonGraph title="Daily Milk Report" img={icon} rounded="none" Selected={selectedCattle} />
      });
    }

    return tabs;
  };

  const handleculling =()=>{
    navigate('/cattleDashboard/cattleCulling')
  }


  return (
    // <div>
    //     <CattleDashboardSideBar/>
    // </div>
    <div
      style={{ backgroundColor: "#FDFDFD", minHeight: "calc(100vh - 135px)" }}
    >
      <FlexCol>
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="flex">
          <Heading title="Cattle Dashboard" />
          <Tooltip title={`Total Cattle Count: ${cattleCount}`}>
          <div className="bg-[#F8F9FB] px-2 py-1 rounded-full text-lg font-semibold text-[#16A34A] cursor-pointer ">
          {cattleCount}
        </div>
        </Tooltip>
        </div>
          <div className="d-flex space-x-5">
            <SearchBox
              placeholder="Search Cattle"
              className="bg-[#F8F9FB]"
              value={searchTerm}
              onSearch={setSearchTerm}
            />
            {/* <Filterbtn /> */}
            <CommonButton title="Cattle Culling" onClick={handleculling} style={{backgroundColor:"#ffff",color:"#16A34A",borderColor: "#16A34A"}} />
            <CommonButton title="Add Cattle" onClick={handleclick} />
          </div>
        </div>
        {cattleList.length === 0 || filteredUserList.length === 0 ? (
          <TableNodata />
        ) : (
          <Row className="gx-3 gy-4">
            <Col xl={3}>
              <CattleDashboardSideBar
                onCattleSelect={handleCattleSelect}
                cattleList={filteredUserList}
                page={setCurrentPage}
                selectedCattle={selectedCattle}
              />
            </Col>
            <Col xl={9}>
              <div>
                <div className="  bg-white rounded-xl border border-gray-300 2xl:p-6 p-4 w-full min-h-[500px]">
                  {/* {selectedCattle[0].map((cattledata)=>( */}
                  <CardBody>
                    <Row className="items-center">
                      <Col md="6">
                        <CardTitle
                          style={{
                            color: "#16A34A",
                            fontFamily: "Plus Jakarta Sans",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "120%",
                            textTransform: "capitalize",
                          }}
                        >
                          Cattle No. {selectedCattle.cattle_id}
                        </CardTitle>
                      </Col>
                      <Col md="6" className="text-right">
                        <div className="flex justify-end items-center space-x-4">
                          <span
                            onClick={handlePreviousCattle}
                            // onClick={handlePrevious}
                            className="cursor-pointer text-[#0A0B0A] flex items-center 2xl:text-[14px] lg:text-[12px] text-[8px] font-bold"
                          >
                            <ChevronLeftIcon className="shrink-0 w-[14px] h-[14px]  sm:w-[14px] sm:h-[14px]  md:w-[14px] md:h-[14px] lg:w-[16px] lg:h-[16px]  xl:w-[16px] xl:h-[16px]  2xl:w-[16px] 2xl:h-[16px] text-[#0A0B0A] inline-block font-bold" />
                            Previous
                          </span>

                          <span
                            onClick={handleNextCattle}
                            // onClick={handleNext}
                            className="cursor-pointer text-[#0A0B0A] flex items-center 2xl:text-[14px] lg:text-[12px] text-[8px] font-bold"
                          >
                            Next
                            <ChevronRightIcon className="shrink-0  w-[14px] h-[14px]  sm:w-[14px] sm:h-[14px]  md:w-[14px] md:h-[14px] lg:w-[16px] lg:h-[16px]  xl:w-[16px] xl:h-[16px]  2xl:w-[16px] 2xl:h-[16px] text-[#0A0B0A] inline-block font-bold " />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <div className="w-full h-px bg-gray-200 mt-2"></div>
                    <Row className="mt-2">
                      {/* <Col>
            <Row>
              <p style={{style}} md="1">Gender</p>
              <p  style={{style}} md="1">Breed</p>
              <p  style={{style}} md="1">Lactation</p>
              <p  style={{style}} md="1">Last Calving</p>
              <p  style={{style}} md="1">Days In Milking</p>
              <p  style={{style}} md="1">Expected Heat</p>
            </Row>
          </Col> */}
                      <div
                        className="d-flex align-items-center space-x-8"
                        style={{
                          color: "#898483",
                          fontFamily: "Plus Jakarta Sans",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "120%",
                          textTransform: "capitalize",
                        }}
                      >
                        <td>
                          <p className="fs-14 my-1 fw-normal">Gender</p>
                          <span style={{ color: "#0A0B0A", fontWeight: 600 }}>
                            {selectedCattle.gender || "--"}
                          </span>
                        </td>
                        {/* <td>
                                                <p className="fs-14 my-1 fw-normal">Breed</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>Jersey</span>
                                            </td> */}
                        {(selectedCattle.cattle_type === 1 ||
                          selectedCattle.cattle_type === 3) && (
                            <>
                              <td>
                                <p className="fs-14 my-1 fw-normal">Lactation</p>
                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>
                                  {selectedCattle.lactation || "--"}
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal">Last Calving</p>
                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>
                                  {(selectedCattle &&
                                    selectedCattle.last_calving_date &&
                                    selectedCattle.last_calving_date

                                  ) ||
                                    "--"}
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal">
                                  Days In Milking
                                </p>
                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>
                                  {"--"}
                                </span>
                              </td>
                              <td>
                                <p className="fs-14 my-1 fw-normal">
                                  Expected Daily Milking
                                </p>
                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>
                                  {selectedCattle.exp_daily_milk || "--"}
                                </span>
                              </td>
                            </>
                          )}
                        {/* <td>
                                                <p className="fs-14 my-1 fw-normal">Expected Heat</p>
                                                <span style={{ color: "#0A0B0A", fontWeight: 600 }}>Within 45 Days</span>
                                            </td> */}
                      </div>
                    </Row>

                    <Row className="mt-3">
                      <Nav pills className="nav-success mb-3">
                        {/* {[
                        "Basic Information",
                        "Breeding",
                        "Milking",
                        "Vaccination",
                        "Treatments",
                      ].map((tab, index) => ( */}
                        {getTabs().map((tab) => (
                          <NavItem key={tab.id}>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                color: "#0A0B0A",
                                backgroundColor: pillsTab === tab.id ? "#E3E3E3" : "transparent",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "calc(10px + 0.2vw)",
                                fontWeight: 600,
                                lineHeight: "120%",
                                textDecoration: "none",
                                textTransform: "capitalize",
                              }}
                              className={pillsTab === tab.id ? "active" : ""}
                              onClick={() => pillsToggle(tab.id)}
                            >
                              {tab.label} {/* Changed from {tab} to {tab.label} */}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Row>
                    {/* {pillsTab === "1" && (
                    <BasicInformation
                      Selected={selectedCattle}
                      getCattleList={getCattleList}
                    />
                  )}
                  {pillsTab === "2" && <Breeding Selected={selectedCattle} />}
                  {pillsTab === "3" && (
                    <CommonGraph
                      title="Daily Milk Report"
                      img={icon}
                      rounded="none"
                      Selected={selectedCattle}
                    />
                  )}
                  {pillsTab === "4" && (
                    <Vaccination Selected={selectedCattle} />
                  )}
                  {pillsTab === "5" && <DietPlan Selected={selectedCattle} />} */}
                    {getTabs().find(tab => tab.id === pillsTab)?.component}
                  </CardBody>
                  {/* ))} */}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </FlexCol>
      <Addcattle isOpen={addcattle} toggle={handleclick} />
    </div>
  );
}

export default CattleDashboard;
