// import React, { useEffect, useState } from 'react';
// import Login from './Components/Login/Login';
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import {  useNavigate } from 'react-router'
// import Header from './Components/Header/Header';
// import DashBoard from './Components/Dashboard/DashBoard';
// import SideBar from './Components/Common/SideBar';
// import Userdashboard from './Components/Dashboard/User Dashboard/Userdashboard';
// import DoctoreDashboard from './Components/Dashboard/DoctoreDashboard/DoctoreDashboard';
// import AdminDashboard from './Components/Dashboard/AdminDashbord/AdminDashboard';
// import CattleDashboard from './Components/CattleDashboard/CattleDashboard';
// import CattleReport from './Components/CattleReport/CattleReport';
// import FeedInventory from './Components/Feed Inventory/FeedInventory';
// import MilkReport from './Components/Milk Report/MilkReport';
// import ActivityLog from './Components/Activity Log/ActivityLog';
// import HealthMonitoring from './Components/Health Monitoring/HealthMonitoring';
// import UserMangement from './Components/User Management/UserMangement';
// import Vaccination from './Components/Vaccination/Vaccination';
// import MedicationReport from './Components/MedicationReport/MedicationReport';
// import VaccinationReport from './Components/Vaccination/VaccinationReport';
// import Breeding from './Components/Breeding/Breeding';
// import ReportsMonitoring from './Components/Reports Monitoring/ReportsMonitoring';
// import CattleHealthReport from './Components/Reports Monitoring/CattleHealthReport';
// import MilkingReport from './Components/Reports Monitoring/MilkingReport';
// import CattleBreedingReport from './Components/Reports Monitoring/CattleBreedingReport';
// import CattleFeedingReport from './Components/Reports Monitoring/CattleFeedingReport';
// import RoleList from './Components/User Management/RoleList';
// import AccountSettings from './Components/Settings/AccountSettings';
// import DeliveryReport from './Components/Breeding/DeliveryReport';
// import InseminationReport from './Components/Breeding/InseminationReport';
// import MilkingStatistics from './Components/Milk Report/MilkingStatistics';
// import PregnancyReport from './Components/Breeding/PregnancyReport';
// import DeliveredReport from './Components/Breeding/DeliveredReport';
// import DewormingReport from './Components/Vaccination/DewormingReport';
// import InseminationReportsMonitoring from './Components/Reports Monitoring/InseminationReportsMonitoring';
// import DeliveryReportsMonitoring from './Components/Reports Monitoring/DeliveryReportsMonitoring';
// import MilkReportMonitoring from './Components/Reports Monitoring/MilkReportMonitoring';
// import VaccinationReportMonitoring from './Components/Reports Monitoring/vacinationReportMonitoring';
// import CattleHealthReportsMonitoring from './Components/Reports Monitoring/CattleHealthReportsMonitoring';

// const styles = {
//   layout: {
//     display: 'flex',
//     minHeight: '100vh',
//     fontFamily: ' Plus Jakarta Sans', // Ensure the font is included
//   },
//   mainContent: {
//     flex: 1, // Take remaining space
//     marginLeft: '280px', // Match sidebar width
    
//     backgroundColor: 'white',
//   },
//   header: {
//     position: 'absolute',
//     top: 0,
//     left: '0px', // Start after sidebar
//     right: 0,
//     height: '70px', // Height of the header
//     zIndex: 999,
//     backgroundColor: 'white', // Optional: to keep the header white
//   },
//   content: {
//     padding: '2rem',
//     marginTop: '70px', // Space for the header
//     backgroundColor:'#FDFDFD'
//   },
// };


// export default function Router() {
//   const [loginData, setLoginData] = useState(
//     (localStorage.getItem("usertype"))
   
    
//   );


//   console.log((localStorage.getItem("usertype")),"ttt");

  
//   return (
//     <BrowserRouter>
//       {loginData?<div style={styles.layout}>
//         <SideBar />
//         <div  className="flex-1 ml-0 md:ml-56 sm:ml-0 lg:ml-52 xl:ml-56 2xl:ml-72 bg-white">
//           <div style={styles.header}>
//             <Header />
//           </div>
//           <div style={styles.content}>
//             <Routes>
//               {/* <Route path="/" element={<Login />} /> */}
//               <Route path="/header" element={<Header />} />
//               <Route path="/dashboard" element={<DashBoard />} />
//               <Route path="/userdashboard" element={<Userdashboard />} />
//               <Route path="/" element={<DoctoreDashboard />} />
//               <Route path="/adminDashboard" element={<AdminDashboard />} />
//               <Route path="/cattleDashboard" element={<CattleDashboard />} />
//               <Route path="/cattleReport" element={<CattleReport />} />
//               <Route path="/feedInventory" element={<FeedInventory/>} />
//               <Route path="/milkReport" element={<MilkReport/>} />
//               <Route path="/activityLog" element={<ActivityLog/>} />
//               <Route path="/healthMonitoring" element={<HealthMonitoring/>} />
//               <Route path="/userManagement" element={<UserMangement/>} />
              
                
//               <Route path="/vaccination" element={<Vaccination/>} />
//               <Route path="/healthMonitoring/medicationReport" element={<MedicationReport/>} />
//               <Route path="/vaccination/vaccination-report" element={<VaccinationReport />} />
//               <Route path="/vaccination/deworming-report" element={<DewormingReport />} />
//               <Route path="/breeding" element={<Breeding />} />
//               <Route path="/reportsMonitoring" element={<ReportsMonitoring />} />
//               <Route path="/reportsMonitoring/cattleHealthReport" element={<CattleHealthReport />} />
//               <Route path="/reportsMonitoring/milkingReport" element={<MilkingReport />} />
//               <Route path="/reportsMonitoring/cattleBreedingReport" element={<CattleBreedingReport />} />
//               <Route path="/reportsMonitoring/Deleveryreport" element={<DeliveryReportsMonitoring />} />
//               <Route path="/reportsMonitoring/Milkreport" element={<MilkReportMonitoring />} />
//               <Route path="/reportsMonitoring/Vaccinationreport" element={<VaccinationReportMonitoring />} />
//               <Route path="/reportsMonitoring/inseminationReport/report" element={<InseminationReportsMonitoring/>} />
//               <Route path="/reportsMonitoring/Healthreport" element={<CattleHealthReportsMonitoring />} />
//               <Route path="/cattleFeedingReport" element={<CattleFeedingReport />} />
//               <Route path="/userManagement/userRole" element={<RoleList />} />
//               <Route path="/accountSettings" element={<AccountSettings />} />
//               <Route path="/breeding/deliveryReport" element={<DeliveryReport/>} />
//               <Route path="/breeding/inseminationReport" element={<InseminationReport/>} />
//               <Route path="/milkReport/milkingStatistics" element={<MilkingStatistics/>} />
//               <Route path="/breeding/pregnancyReport" element={<PregnancyReport/>} />
//               <Route path="/breeding/deliveredReport" element={<DeliveredReport/>} />
              
//             </Routes>
//           </div>
//         </div>
//       </div>:
//       <Routes>
//               <Route path="/" element={<Login />} />
//       </Routes>
//               }
//     </BrowserRouter>
//   );
// }


import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import Login from './Components/Login/Login';
// ... other imports remain the same
import Header from './Components/Header/Header';
import DashBoard from './Components/Dashboard/DashBoard';
import SideBar from './Components/Common/SideBar';
import Userdashboard from './Components/Dashboard/User Dashboard/Userdashboard';
import DoctoreDashboard from './Components/Dashboard/DoctoreDashboard/DoctoreDashboard';
import AdminDashboard from './Components/Dashboard/AdminDashbord/AdminDashboard';
import CattleDashboard from './Components/CattleDashboard/CattleDashboard';
import CattleReport from './Components/CattleReport/CattleReport';
import FeedInventory from './Components/Feed Inventory/FeedInventory';
import MilkReport from './Components/Milk Report/MilkReport';
import ActivityLog from './Components/Activity Log/ActivityLog';
import HealthMonitoring from './Components/Health Monitoring/HealthMonitoring';
import UserMangement from './Components/User Management/UserMangement';
import Vaccination from './Components/Vaccination/Vaccination';
import MedicationReport from './Components/MedicationReport/MedicationReport';
import VaccinationReport from './Components/Vaccination/VaccinationReport';
import Breeding from './Components/Breeding/Breeding';
import ReportsMonitoring from './Components/Reports Monitoring/ReportsMonitoring';
import CattleHealthReport from './Components/Reports Monitoring/CattleHealthReport';
import MilkingReport from './Components/Reports Monitoring/MilkingReport';
import CattleBreedingReport from './Components/Reports Monitoring/CattleBreedingReport';
import CattleFeedingReport from './Components/Reports Monitoring/CattleFeedingReport';
import RoleList from './Components/User Management/RoleList';
import AccountSettings from './Components/Settings/AccountSettings';
import DeliveryReport from './Components/Breeding/DeliveryReport';
import InseminationReport from './Components/Breeding/InseminationReport';
import MilkingStatistics from './Components/Milk Report/MilkingStatistics';
import PregnancyReport from './Components/Breeding/PregnancyReport';
import DeliveredReport from './Components/Breeding/DeliveredReport';
import DewormingReport from './Components/Vaccination/DewormingReport';
import InseminationReportsMonitoring from './Components/Reports Monitoring/InseminationReportsMonitoring';
import DeliveryReportsMonitoring from './Components/Reports Monitoring/DeliveryReportsMonitoring';
import MilkReportMonitoring from './Components/Reports Monitoring/MilkReportMonitoring';
import VaccinationReportMonitoring from './Components/Reports Monitoring/vacinationReportMonitoring';
import CattleHealthReportsMonitoring from './Components/Reports Monitoring/CattleHealthReportsMonitoring';
import DewormingReportMonitoring from './Components/Reports Monitoring/DewormingReportsMonitoring';
import PregnancyReportMonitoring from './Components/Reports Monitoring/PregnancyReportMonitoring';
import ScrolltoTop from './Components/Common/ScrolltoTop';
import CattleCulling from './Components/CattleDashboard/CattleCulling';

// Protected Route wrapper component
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("usertype");
  const location = useLocation();

  if (!isAuthenticated) {
    // Redirect to login page while saving the attempted URL
    return <Navigate to="/" state={{ from: location }} replace />;
  }
const styles = {
  layout: {
    display: 'flex',
    minHeight: '100vh',
    fontFamily: ' Plus Jakarta Sans', // Ensure the font is included
  },
  mainContent: {
    flex: 1, // Take remaining space
    marginLeft: '280px', // Match sidebar width
    
    backgroundColor: 'white',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: '0px', // Start after sidebar
    right: 0,
    height: '70px', // Height of the header
    zIndex: 999,
    backgroundColor: 'white', // Optional: to keep the header white
  },
  content: {
    padding: '2rem',
    marginTop: '70px', // Space for the header
    backgroundColor:'#FDFDFD'
  },
};

  return (
    <div style={styles.layout}>
      <SideBar />
      <div className="flex-1 ml-0 md:ml-56 sm:ml-0 lg:ml-52 xl:ml-56 2xl:ml-72 bg-white">
        <div style={styles.header}>
          <Header />
        </div>
        <div style={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

// Login wrapper to handle authenticated users trying to access login page
const LoginWrapper = () => {
  const isAuthenticated = localStorage.getItem("usertype");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to the attempted URL or dashboard if already logged in
      const destination = location.state?.from?.pathname || "/dashboard";
      navigate(destination, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  return !isAuthenticated ? <Login /> : null;
};

export default function Router() {
  return (
    <BrowserRouter>
    <ScrolltoTop/>
      <Routes>
        {/* Public route - Login */}
        <Route path="/" element={<LoginWrapper />} />
        
        {/* Protected routes */}
        <Route path="/header" element={<ProtectedRoute><Header /></ProtectedRoute>} />
        {/* <Route path="/dashboardeee" element={<ProtectedRoute><DashBoard /></ProtectedRoute>} /> */}
        <Route path="/userdashboard" element={<ProtectedRoute><Userdashboard /></ProtectedRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><DoctoreDashboard /></ProtectedRoute>} />
        <Route path="/adminDashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
        <Route path="/cattleDashboard" element={<ProtectedRoute><CattleDashboard /></ProtectedRoute>} />
        <Route path="/cattleReport" element={<ProtectedRoute><CattleReport /></ProtectedRoute>} />
        <Route path="/feedInventory" element={<ProtectedRoute><FeedInventory /></ProtectedRoute>} />
        <Route path="/milkReport" element={<ProtectedRoute><MilkReport /></ProtectedRoute>} />
        <Route path="/activityLog" element={<ProtectedRoute><ActivityLog /></ProtectedRoute>} />
        <Route path="/healthMonitoring" element={<ProtectedRoute><HealthMonitoring /></ProtectedRoute>} />
        <Route path="/userManagement" element={<ProtectedRoute><UserMangement /></ProtectedRoute>} />
        <Route path="/vaccination" element={<ProtectedRoute><Vaccination /></ProtectedRoute>} />
        <Route path="/healthMonitoring/medicationReport" element={<ProtectedRoute><MedicationReport /></ProtectedRoute>} />
        <Route path="/vaccination/vaccination-report" element={<ProtectedRoute><VaccinationReport /></ProtectedRoute>} />
        <Route path="/vaccination/deworming-report" element={<ProtectedRoute><DewormingReport /></ProtectedRoute>} />
        <Route path="/breeding" element={<ProtectedRoute><Breeding /></ProtectedRoute>} />
        <Route path="/reportsMonitoring" element={<ProtectedRoute><ReportsMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/cattleHealthReport" element={<ProtectedRoute><CattleHealthReport /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/milkingReport" element={<ProtectedRoute><MilkingReport /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/cattleBreedingReport" element={<ProtectedRoute><CattleBreedingReport /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/Deleveryreport" element={<ProtectedRoute><DeliveryReportsMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/Milkreport" element={<ProtectedRoute><MilkReportMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/Vaccinationreport" element={<ProtectedRoute><VaccinationReportMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/Dewormingreport" element={<ProtectedRoute><DewormingReportMonitoring/></ProtectedRoute>} />
        <Route path="/reportsMonitoring/inseminationReport/report" element={<ProtectedRoute><InseminationReportsMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/PregnancyReport" element={<ProtectedRoute><PregnancyReportMonitoring /></ProtectedRoute>} />
        <Route path="/reportsMonitoring/Healthreport" element={<ProtectedRoute><CattleHealthReportsMonitoring /></ProtectedRoute>} />
        <Route path="/cattleFeedingReport" element={<ProtectedRoute><CattleFeedingReport /></ProtectedRoute>} />
        <Route path="/userManagement/userRole" element={<ProtectedRoute><RoleList /></ProtectedRoute>} />
        <Route path="/accountSettings" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} />
        <Route path="/breeding/deliveryReport" element={<ProtectedRoute><DeliveryReport /></ProtectedRoute>} />
        <Route path="/breeding/inseminationReport" element={<ProtectedRoute><InseminationReport /></ProtectedRoute>} />
        <Route path="/milkReport/milkingStatistics" element={<ProtectedRoute><MilkingStatistics /></ProtectedRoute>} />
        <Route path="/breeding/pregnancyReport" element={<ProtectedRoute><PregnancyReport /></ProtectedRoute>} />
        <Route path="/breeding/deliveredReport" element={<ProtectedRoute><DeliveredReport /></ProtectedRoute>} />
        <Route path="/cattleDashboard/cattleCulling" element={<ProtectedRoute><CattleCulling /></ProtectedRoute>} />
        {/* Catch all route - redirect to login */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}