// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import { Form, FormGroup, Label } from 'reactstrap';

// const DateSelector = () => {
//     const [selectedDate, setSelectedDate] = useState(null);

//     const handleChange = (date) => {
//         setSelectedDate(date);
//     };

//     return (
//         <Form>
//             <FormGroup>
//                 <Label for="datePicker">Select a Date</Label>
//                 <DatePicker
//                     selected={selectedDate}
//                     onChange={handleChange}
//                     dateFormat="MMMM d, yyyy"
//                     className="form-control"
//                     placeholderText="Select a date"
//                 />
//             </FormGroup>
//         </Form>
//     );
// };

// export default DateSelector;

import { DatePicker } from "antd";
import dayjs from "dayjs";

import React, { useEffect } from "react";
import { FaAsterisk } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import '../../assets/css/style.css'

export default function DateSelect({
  change = () => {},
  className,
  pickerType = "date",
  dateFormat = "YYYY-MM-DD",
  value = null,
  title = "",
  description = "",
  error = "",
  required = false,
  placeholder = "",
  placement = "",
  joiningDate,
  defaultPickerValue = null,
  dateofBirth = false,
  hideYear = false,
  fromDate,
  maxdate = false,
  disabled = false,
 disableBeforeDate = '',
 disabledFutureDate= false,
}) {
  const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
  const isSmallScreen = useMediaQuery({ maxWidth: 1439 });
  const disabledDate = (current) => {
    if (disabledFutureDate) {
      // Disable future dates
      return current && current.isAfter(dayjs().endOf('day'));
    }
    if (disableBeforeDate) {
      // Disable dates strictly before "disableBeforeDate"
      return current && current.isBefore(dayjs(disableBeforeDate).startOf('day'));
    }
    return false; // Default to no dates disabled
  };
  
  
  const disabledDateonlybefore = (current) => {
    // Disable dates strictly before "disableBeforeDate" but allow the exact date
    return current && current.isBefore(dayjs(disableBeforeDate).startOf('day'));
  };
  const disabledFutureDates = (current) => {
    // Disable dates strictly after today but allow today
    return current && current.isAfter(dayjs().endOf('day'));
  };

  useEffect(() => {
  }, [value]);

  // const disabledDate = (current) => {
  //   if (maxdate) {
  //     return current && current > dayjs().endOf("day");
  //   }
  //   if (joiningDate) {
  //     return current && current < dayjs(joiningDate, dateFormat).endOf("day");
  //   }
  //    else if (dateofBirth) {
  //     const eighteenYearsAgo = dayjs().subtract(18, "year").endOf("day");
  //     return current && current > eighteenYearsAgo;
  //   } else if (fromDate) {
  //     return (
  //       current &&
  //       current < dayjs(fromDate, dateFormat).subtract(1, "day").endOf("day")
  //     );
  //   }
  //   if (disableBeforeDate) {
  //     return current && current < dayjs(disableBeforeDate, dateFormat).startOf("day");
  //   }
  //   return false;
  // };
  


  const defaultPickerValueToUse = defaultPickerValue
    ? dayjs(defaultPickerValue, dateFormat)
    : dayjs();

  const customMonthPicker = () => (
    <DatePicker
      value={value ? dayjs(value, "MM") : null}
      picker="month"
      format="MM"
      onChange={(date, dateString) => {
        change(date.format("MM"));
      }}
      status={error ? "error" : ""}
      disabledDate={disabledDate} 
      disableDateOnlyBefore ={disabledDateonlybefore}
      // disabledFutureDates ={disabledFutureDate}
      placement={placement}
      size={isSmallScreen ? "default" : "large"}
      className={`w-full ${error ? "border-rose-400" : ""} ${
        hideYear ? "hide-year-picker" : ""
      }`}
      style={
        error
          ? {
              boxShadow:
                "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }
          : {}
      }
     
      placeholder={placeholder}
      defaultPickerValue={defaultPickerValueToUse}
      dropdownClassName="hide-year-picker"
      disabled={disabled} 
    />
  );

  const defaultDatePicker = () => (
    <DatePicker
      value={value ? dayjs(value, dateFormat) : null}
      picker={pickerType}
      format={dateFormat}
      onChange={(date, dateString) => {
        change(dateString);
      }}
      status={error ? "error" : ""}
      // placement={placement}  // if give placement the dropdown date are displaying in the other side of the window.
      size={isSmallScreen ? "default" : "large"}
      className={`w-full border rounded-lg ${error ? "border-rose-400" : ""}`}
      style={
        error
          ? {
              boxShadow:
                "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }
          : {}
      }
      
      placeholder={placeholder}
      defaultPickerValue={defaultPickerValueToUse}
      disabled={disabled} 
      disabledDate={disabledDate}
      disableDateOnlyBefore ={disabledDateonlybefore}
      // disabledFutureDates ={disabledFutureDate}
    />
  );

  return (
    <div
      className={`flex flex-col ${
        title ? "gap-2" : "gap-0 items-center"
      } ${className}`}
    >
      <div className="flex items-center dark:text-white gap-0.5">
        {title && (
          <label htmlFor="" className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
            {title}
          </label>
        )}
        {required && <FaAsterisk className="text-[6px] text-rose-600" />}
      </div>
      <span>{hideYear ? customMonthPicker() : defaultDatePicker()}</span>
      {error && (
        <p className="flex justify-start items-center mt-2 my-1 mb-0 text-[10px] text-red-600">
          <span className="text-[10px] pl-1">{error}</span>
        </p>
      )}
      {description && (
        <p className="2xl:text-sm text-xs font-normal opacity-70 dark:text-white">
          {description}
        </p>
      )}
    </div>
  );
}
