import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Cards from '../Common/Cards'
import CommonGraph from '../Common/CommonGraph'
import Commonbarchart from '../Common/Commonbarchart'
import { Col, Row } from 'reactstrap'
import Reportsbarchart from '../Common/Reportsbarchart'
import { useNavigate } from 'react-router'
import API, { action } from '../../Api'
import ReportCard from '../Common/ReportCard'
import LoadingSection from '../Common/LoadingSection'
import { useLoader } from '../../LoaderContext'

function ReportsMonitoring() {
    const [cardCount, setCardCount] = useState({});
    const [milkcardCount, setMilkCardCount] = useState({});
    const [medcardCount, setMedCardCount] = useState({});
    const [chartData, setChartData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);
    const [pregnantData, setPregnantData] = useState([])
    const [mIlkchartData, setMilkchartData] = useState([])
    const [mIlkchartCategories, setMilkchartCategories] = useState([])
    const [medicationchartData, setMedicationchartData] = useState([])
    const [medicationchartCategories, setMedicationchartCategories] = useState([])
    const [medicationchartgivenData, setMedicationchartgivenData] = useState([])
    const navigate = useNavigate();
    const { setLoading } = useLoader();
    const getMonthsForCurrentPeriod = () => {
        const currentMonth = new Date().getMonth(); // 0-11
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Determine which section we're in (0 for Jan-Jun, 1 for Jul-Dec)
        const currentSection = currentMonth < 6 ? 0 : 1;
        
        // Get the appropriate 6 months based on the current section
        const months = [];
        const startIdx = currentSection * 6; // 0 for Jan-Jun, 6 for Jul-Dec
        
        // Get 6 months for the current section
        for (let i = 0; i < 6; i++) {
            months.push(monthNames[startIdx + i]);
        }

        return {
            months,
            startIdx,
            currentSection
        };
    };

    const getCardCount = async () => {
        setLoading(true);
        const result = await action(API.GET_PREGNANCY_COUNT, {
            timestamp: '',
        });
        setLoading(false);
        if (result?.response?.data) {
            const data = result.response.data;
            setCardCount(data);

            const { months, startIdx } = getMonthsForCurrentPeriod();

            // Map inseminated and pregnant data
            const inseminatedData = months.map((month, index) => {
                const monthIndex = startIdx + index + 1; // Convert to 1-based index for API
                const matchingData = data.graphData.find(item => item.month === monthIndex);
                return matchingData ? matchingData.inseminated : 0;
            });

            const pregnantData = months.map((month, index) => {
                const monthIndex = startIdx + index + 1; // Convert to 1-based index for API
                const matchingData = data.graphData.find(item => item.month === monthIndex);
                return matchingData ? matchingData.pregnant : 0;
            });

            setChartCategories(months);
            setChartData(inseminatedData);
            setPregnantData(pregnantData);
        }
    };

    const getMilkCount = async () => {
        const result = await action(API.GET_MILKING_COUNT, {
            timestamp: '',
        });

        if (result?.response?.data) {
            const data = result.response.data;
            setMilkCardCount(data);

            const { months, startIdx } = getMonthsForCurrentPeriod();

            // Map milk data
            const milkData = months.map((month, index) => {
                const monthIndex = startIdx + index + 1; // Convert to 1-based index for API
                const matchingData = data.montly_milking_report.find(item => item.month === monthIndex);
                return matchingData ? matchingData.totalLiters : 0;
            });

            setMilkchartCategories(months);
            setMilkchartData(milkData);
        }
    };

    const getMedicationCount = async () => {
        const result = await action(API.GET_MEDICATION_COUNT, {
            timestamp: '',
        });

        if (result?.response?.data) {
            const data = result.response.data;
            setMedCardCount(data);

            const { months, startIdx } = getMonthsForCurrentPeriod();

            // Map medication data
            const assigned = months.map((month, index) => {
                const monthIndex = startIdx + index + 1; // Convert to 1-based index for API
                const matchingData = data.monthly_report.find(item => item.month === monthIndex);
                return matchingData ? matchingData.diagnosed : 0;
            });

            const given = months.map((month, index) => {
                const monthIndex = startIdx + index + 1; // Convert to 1-based index for API
                const matchingData = data.monthly_report.find(item => item.month === monthIndex);
                return matchingData ? matchingData.cured : 0;
            });

            setMedicationchartCategories(months);
            setMedicationchartData(assigned);
            setMedicationchartgivenData(given);
        }
    };

    useEffect(() => {
        getMedicationCount();
        getCardCount()
        getMilkCount()
    }, []);



    const data = [
        {
            id: 1,
            cardColor: 'primary',
            label: 'Inseminated',
            badge: 'ri-arrow-right-up-line',
            badgeClass: 'success',
            percentage: '+16.24',
            counter: cardCount.inseminated_count || 0,
            link: 'View net earnings',
            bgcolor: 'primary',
            icon: 'bx bx-dollar-circle',
            decimals: 2,
            prefix: '$',
            suffix: 'k',
        },
        {
            id: 2,
            cardColor: 'secondary',
            label: 'Pregnant',
            badge: 'ri-arrow-right-down-line',
            badgeClass: 'danger',
            percentage: '-3.57',
            counter: cardCount.pregnant_count || 0,
            link: 'View all orders',
            bgcolor: 'primary',
            icon: 'bx bx-shopping-bag',
            decimals: 0,
            prefix: '',
            separator: ',',
            suffix: '',
        },
    ];
    const Milkdata = [
        {
            id: 1,
            cardColor: 'primary',
            label: 'Total Milking',
            badge: 'ri-arrow-right-up-line',
            badgeClass: 'success',
            percentage: '+16.24',
            counter: milkcardCount.total_milking || 0,
            link: 'View net earnings',
            bgcolor: 'primary',
            icon: 'bx bx-dollar-circle',
            decimals: 2,
            prefix: '$',
            suffix: 'k',
        },
        {
            id: 2,
            cardColor: 'secondary',
            label: 'Dry Cattle',
            badge: 'ri-arrow-right-down-line',
            badgeClass: 'danger',
            percentage: '-3.57',
            counter: milkcardCount.dry_cattle || 0,
            link: 'View all orders',
            bgcolor: 'primary',
            icon: 'bx bx-shopping-bag',
            decimals: 0,
            prefix: '',
            separator: ',',
            suffix: '',
        },
    ]; const Meddata = [
        {
            id: 1,
            cardColor: 'primary',
            label: 'Issue Cattle',
            badge: 'ri-arrow-right-up-line',
            badgeClass: 'success',
            percentage: '+16.24',
            counter: medcardCount.issue_cattle || 0,
            link: 'View net earnings',
            bgcolor: 'primary',
            icon: 'bx bx-dollar-circle',
            decimals: 2,
            prefix: '$',
            suffix: 'k',
        },
        {
            id: 2,
            cardColor: 'secondary',
            label: 'In Medication',
            badge: 'ri-arrow-right-down-line',
            badgeClass: 'danger',
            percentage: '-3.57',
            counter: medcardCount.in_medication_count || 0,
            link: 'View all orders',
            bgcolor: 'primary',
            icon: 'bx bx-shopping-bag',
            decimals: 0,
            prefix: '',
            separator: ',',
            suffix: '',
        },
    ];
    const healthreport = () => {
        navigate('/reportsMonitoring/cattleHealthReport');
    };
    const milkReport = () => {
        navigate('/reportsMonitoring/milkingReport');
    };
    const pregnancyReport = () => {
        navigate('/reportsMonitoring/cattleBreedingReport');
    };
    const feedingReport = () => {
        navigate('/cattleFeedingReport');
    };


    const cardClick = (item) => {
        if (item.label === 'Issue Cattle') {
            navigate('/reportsMonitoring/Healthreport', { state: { tab: "2" } });
        }
        else if (item.label === 'Inseminated') {
            navigate('/reportsMonitoring/inseminationReport/report', { state: { tab: "2" } });// Pass tab as part of state
        }
        else if (item.label === 'In Medication') {
            navigate('/reportsMonitoring/Healthreport', { state: { tab: "3" } });
        }
        else if (item.label === 'Not on Medication') {
            navigate('/reportsMonitoring/Healthreport', { state: { tab: "3" } });
        } else if (item.label === 'Pregnant') {
            navigate("/reportsMonitoring/PregnancyReport")
        }
        else if (item.label === 'Total Milking') {
            navigate("/reportsMonitoring/Milkreport")
        }
        else {

        }
    }



    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <Row className="gx-4 gy-4">
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Pregnancy Analytics' />

                            </div>
                            <ReportCard data={data} cardClick={cardClick} />
                            <Reportsbarchart title={"Pregnancy Report"} subtitle={"Monthly overview of pregnancies to manage herd reproduction."} bgcolor="#FDBF26" btnbgcolor="#FDBF26" btntitle="View Report" titleColor="#FDBF26" handleclick={pregnancyReport} chartCategories={chartCategories}
                                chartData={chartData} pregnantData={pregnantData} bgcolor2='#FEEAB7' name1='Inseminated' name2='Pregnant' />
                        </FlexCol>
                    </div>
                </Col>
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Milking Analytics' />

                            </div>
                            <ReportCard data={Milkdata} cardClick={cardClick} />
                            <Reportsbarchart title={"Milking Report"} subtitle={"Track monthly milk production trends to monitor output efficiency"} bgcolor="#0EA5E9" btnbgcolor="#0EA5E9" btntitle="View Report" titleColor="#0EA5E9" handleclick={milkReport} chartCategories={mIlkchartCategories} chartData={mIlkchartData} name1='Milked' name2="Expected" bgcolor2={'#ACE2FA'} />
                        </FlexCol>
                    </div>
                </Col>
            </Row>
            <Row className="gx-4 gy-4 mt-4">
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Health Analytics' />

                            </div>
                            <ReportCard data={Meddata} cardClick={cardClick} />
                            <Reportsbarchart title={"Health Report"} subtitle={"Track Feeding to keep your herd protected."} bgcolor="#E4626F" btnbgcolor="#E4626F" btntitle="View Report" titleColor="#E4626F" handleclick={healthreport} chartCategories={medicationchartCategories} chartData={medicationchartData} name1='Recovered' name2="Reported" bgcolor2={'#FFA7B0'} pregnantData={medicationchartgivenData} />
                        </FlexCol>
                    </div>
                </Col>
                <Col xl={6}>
                    <div>
                        <FlexCol>


                            <div className="d-flex justify-content-between">

                                <Heading title='Feeding Analytics' />

                            </div>
                                {/* <ReportCard data={data} cardClick={cardClick} loading={true} />
                                <Reportsbarchart title={"Feeding Report"} subtitle={"Track Feeding to keep your herd protected."} bgcolor="#16A34A" btnbgcolor="#16A34A" btntitle="View Report" titleColor="#16A34A" handleclick={feedingReport} /> */}
                                <LoadingSection/>
                        </FlexCol>
                    </div>
                </Col>
            </Row>
        </div>


    )
}

export default ReportsMonitoring