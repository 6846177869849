import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import StockInventoryGraph from '../Dashboard/AdminDashbord/Stockinventorygraph/StockInventoryGraph'
import ReportMonitoringComparisonGraph from '../Common/ReportMonitoringComparisonGraph'
import Cards from '../Common/Cards'
import API, { action } from '../../Api'
import { useNavigate } from 'react-router'

function CattleHealthReport() {

    const [healthReport, setHealthReport] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [expectedData, setExpectedData] = useState([])

    
    const navigate = useNavigate()

    const handleVaccinationClick =()=>{
       navigate("/reportsMonitoring/Vaccinationreport")
    }
    const handleDiagnosisClick =()=>{
        navigate("/reportsMonitoring/Healthreport")
     }
     const handleButtonClick = (item) => {
        // setShowVaccinationReport(true);
        if (item.label === "Dewormed"  ) {
            navigate("/reportsMonitoring/Dewormingreport", { state: { tab: "2" } })
        } 
        if (item.label === "Deworming Overdue"  ) {
            navigate("/reportsMonitoring/Dewormingreport", { state: { tab: "3" } })
        }
        if (item.label === "Vaccination Overdue"  ) {
            navigate("/reportsMonitoring/Vaccinationreport", { state: { tab: "3" } })
        }
        if (item.label === "Vaccinated"  ) {
            navigate("/reportsMonitoring/Vaccinationreport", { state: { tab: "2" } })
        }else {
            
        }
    };

    const cardClick = (item) => {
        if (item.label === 'Total Issues' ) {
          navigate('/reportsMonitoring/Healthreport',{ state: { tab: "2" } }); 
        }
        else if (item.label === 'Critical'){
          navigate('/reportsMonitoring/Healthreport', { state: { tab: "5" } });// Pass tab as part of state
        }
        else if (item.label === 'Medications'){
          navigate('/reportsMonitoring/Healthreport', { state: { tab: "3" } });
        }
        else if ( item.label === 'Not in Medication') {
          navigate('/reportsMonitoring/Healthreport', { state: { tab: "4" } });
        }
        else {
            navigate('/reportsMonitoring/Healthreport', { state: { tab: "6" } });
        }
      }

    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Vaccinated",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter: healthReport.vaccination_data?.total_vaccination || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Vaccination Overdue",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "-3.57",
            counter: healthReport.vaccination_data?.pending_vaccination || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Dewormed",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter:healthReport.vaccination_data?.total_deworming || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Deworming Overdue",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: healthReport.vaccination_data?.pending_deworming || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]

    const data2 = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Issues",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter: healthReport.diagnosis_data?.total_issue || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Medications",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "-3.57",
            counter: healthReport.diagnosis_data?.medication_count || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Not in Medication",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter:healthReport.diagnosis_data?.not_on_medication_count || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Recovered",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: healthReport.diagnosis_data?.recovered_count || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]
        const getAllMonthNames = () => {
            return Array.from({ length: 12 }, (_, i) => 
                new Date(2024, i).toLocaleString('default', { month: 'short' })
            );
        };
        
        const getHealthReport = async () => {
            const result = await action(API.GET_HEALTH_REPORT, {
                start_Date: null,
                end_Date: null,
                filterType: null,
            });
            const responseData = result.response.data;
            setHealthReport(responseData);
        
            // Get all month names
            const allMonths = getAllMonthNames();
            
            // Create maps for both pregnant and inseminated data
            const pregnantDataMap = new Map(
                responseData?.monthly_report?.map(item => [
                    item.month - 1,  // Convert to 0-based index
                    item.assigned
                ])
            );
        
            const inseminatedDataMap = new Map(
                responseData?.monthly_report?.map(item => [
                    item.month - 1,  // Convert to 0-based index
                    item.given
                ])
            );
        
            // Create actual data array (pregnant) with all months
            const actualData = allMonths.map((monthName, index) => ({
                name: monthName,
                value: pregnantDataMap.get(index) || 0
            }));
        
            // Create expected data array (inseminated) with all months
            const expectedValues = allMonths.map((monthName, index) => ({
                name: monthName,
                value: inseminatedDataMap.get(index) || 0
            }));
        
            setCurrentData(actualData);
            setExpectedData(expectedValues);
        };
        
        
        useEffect(() => {
            getHealthReport();
        }, []);

  return (
    <div style={{ backgroundColor: "#FDFDFD" }}>
    <FlexCol>
        <div className="flex flex-col sm:flex-row justify-between">
            <Heading title='Cattle Health Report' />
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                <div className="flex space-x-5">
                    {/* <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" /> */}
                    {/* <Filterbtn /> */}
                </div>
                <div className="flex space-x-5 sm:mt-4">
                   
                    {/* <CommonButton title="Export"  /> */}
                </div>
            </div>
        </div>
        <ReportMonitoringComparisonGraph title="Comparison"   CategoryName="Assigned" CategoryName2="Given" currentData={currentData}
                    availableData={expectedData}/>
        <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Vaccination Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#FDBF26] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                      
                            {/* <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#FDBF26", border: "1px #FDBF26  solid", }} onClick={handleVaccinationClick} /> */}
                        
                    </div>
                </div>
                <Cards data={data} cardClick={handleButtonClick} />
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Diagnosis Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#E4626F] 2xl:text-[32px]" />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">

                      

                            {/* <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#E4626F", border: "1px #E4626F solid", }} onClick={handleDiagnosisClick} /> */}
                       
                    </div>
                </div>
                <Cards data={data2} cardClick={cardClick} />
        </FlexCol>
        
        </div>
  )
}

export default CattleHealthReport