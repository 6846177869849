import React, { useEffect, useState } from 'react'
import CommonButton from '../Common/Button'
import Filterbtn from '../Common/Filterbtn'
import SearchBox from '../Common/SearchBox'
import Heading from '../Common/Heading'
import FlexCol from '../Common/FlexCol'
import { Nav, NavItem, NavLink } from 'reactstrap'
import ReactTable from '../Common/Table'
import CreateRole from '../Drawer/CreateRole'
import API, { action } from '../../Api'
import { useLoader } from '../../LoaderContext'

function RoleList() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [roledata, setRoledata] = useState([])
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { setLoading } = useLoader();
    // const toggleDrawer = () => {
    //     setDrawerOpen(!drawerOpen)
    //     getUserRoleList()
    // };

    const toggleDrawer = () => {
        setDrawerOpen(prevState => {

            if (prevState) {
                getUserRoleList()
            }
            return !prevState;
        });
    };


    const handleActionClick = (userData) => {

        setSelectedUserId(userData);
        setDrawerOpen(true);
    };



    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "User Role",
            value: "UserRole",


        },
        // {
        //     id: 3,
        //     title: "User",
        //     value: "User",

        // },

        // {
        //   id: 4,
        //   title: t("Created_On"),
        //   value: "createdOn",
        //   dataIndex: "createdOn",
        //   sorter: (a, b) => {
        //     const dateA = new Date(a.createdOn);
        //     const dateB = new Date(b.createdOn);
        //     return dateA.getTime() - dateB.getTime();
        //   },
        //   sortOrder: "ascent",
        // },

        // {
        //     id: 6,
        //     title: "Status",
        //     value: "Status",


        // },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]


    const data = [
        {
            SlNo: "01",
            UserRole: "Supervisor",
            User: "User 1",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "02",
            UserRole: "Supervisor",
            User: "User 2",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "03",
            UserRole: "Supervisor",
            User: "User 3",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "04",
            UserRole: "Supervisor",
            User: "User 4",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "05",
            UserRole: "Supervisor",
            User: "User 5",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "06",
            UserRole: "Supervisor",
            User: "User 6",

            Status: {
                "value": "active",
                "toggle": true
            },



        },
        {
            SlNo: "07",
            UserRole: "Supervisor",
            User: "User 7",

            Status: {
                "value": "active",
                "toggle": true
            },



        },


    ];
    const [tab, setTab] = useState("1");
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };


    const getUserRoleList = async (page = currentPage, pageSize = 60) => {
        setLoading(true);
        const result = await action(API.GET_ROLE, {
            searchKey: searchTerm,
            page: page,
            pageCount: pageSize
        });
        setLoading(false);
        console.log(result.response.data, "getrolelist data")
        // setEmployeeLeave(result.result);
        setRoledata(result.response.data)
        const formattedData = result.response.data.map((item, index) => ({
            SlNo: (index + 1).toString().padStart(2, '0'), // Format SlNo
            UserRole: item.role_name
            , // Adjust according to your API response structure
            User: item.role_name,
            id: item.id,
            privilege: item.module_privileges
            , // Adjust according to your API response structure
            Status: {
                value: item.status, // Adjust according to your API response structure
                toggle: true // or any logic to set toggle
            },
            action: "Edit"
        }))

        setRoledata(formattedData);
    };



    useEffect(() => {
        getUserRoleList()
        console.log(roledata, 'roledata');

    }, [searchTerm, currentPage, pageSize])

    // const filteredUserList = (roledata || []).filter(user => {
    //     const userId = user.UserRole || ""; // Prevent null error

    //     return (
    //         userId.toLowerCase().includes(searchTerm.toLowerCase())

    //     )
    // });

    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Role List' />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        <div className="flex space-x-5">
                            <SearchBox placeholder="Search Role" className="bg-[#F8F9FB]" value={searchTerm}
                                onSearch={setSearchTerm} />
                            {/* <Filterbtn /> */}
                        </div>
                        <div className="flex space-x-5 sm:mt-4">
                            <CommonButton title="Create Role" onClick={toggleDrawer} className="border border-[#16A34A] text-[#16A34A] bg-none" />

                        </div>
                    </div>
                </div>
                <Nav pills className="nav-success ">
                    {["All",].map((tabs, index) => (
                        <NavItem key={index}>
                            <NavLink
                                style={{
                                    cursor: "pointer",
                                    color: "#0A0B0A",
                                    backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    lineHeight: "120%",
                                    textDecoration: 'none' // Optional underline for active tab
                                }}
                                className={tab === String(index + 1) ? "active" : ""}
                                onClick={() => pillsToggle(String(index + 1))}
                            >
                                {tabs}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                {tab === "1" && <ReactTable headings={headings} data={roledata} handleAction={handleActionClick} />}
                {/* {tab === "2" && <ReactTable headings={headings} data={Inseminated_Data} />}
{tab === "3" && <ReactTable headings={headings} data={HeatedCattle_Data} />}
{tab === "4" && <ReactTable headings={headings} data={Pregnant_Data} />}
        <ReactTable headings={headings} data={data} headingcolor="#E3E3E3" /> */}
            </FlexCol>
            <CreateRole isOpen={drawerOpen} toggle={toggleDrawer} selectedUserId={selectedUserId} />

        </div>
    )
}

export default RoleList