import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import API, { action } from "../../Api";
import * as Yup from "yup";
import {
  FormGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import FlexCol from "../Common/FlexCol";
import { Flex, notification } from "antd";
import FormInput from "../Common/FormInput";
import CommonButton from "../Common/Button";
import PasswordInput from "../Common/PasswordInput";

function ChangePassword({ isOpen, toggle, user }) {
  const [loading, setLoading] = useState(false);
  const [id, setid] = useState(user);

  console.log(id, "userrrr");
  console.log(user, "userrrr");
  // const userId = user.userId || null;

  // console.log(userId,"userrrridd");
  useEffect(() => {
    setid(user);
  }, [user]);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
      // userid: Array.isArray(user) && user.length > 0 ? user[0].UserID : null,
      // userid:user?.UserID
      id: id,
    },
    validationSchema: Yup.object({
      // currentPassword: Yup.string().required('current password is required'),
      password: Yup.string().required("New password is required"),
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      // contactNumber: Yup.string().required('Contact Number is required'),
    }),
    onSubmit: async (values) => {
      console.log(values, "values");
      setLoading(true);
      try {
        if (values.currentPassword === values.password) {
          // formik.setFieldError('newPassword', 'New password must not match with old password');
          // return;
        }
        if (values.password !== values.confirmPassword) {
          // Handle error if passwords do not match
          formik.setFieldError("confirmpassword", "Passwords must match");
          return;
        }
        await action(API.UPDATE_PASSWORD, {
          user_id: id,
          password: values.confirmPassword,
        });
        // Optionally reset the form or close the offcanvas
        notification.success({
          message: "Password updated successfully!",
          placement: "top",
        });
        formik.resetForm();
        toggle1(); // Close the offcanvas
      } catch (error) {
        console.error("Error creating user:", error);
        // Handle the error as needed
        notification.error({
          message: error.response?.data?.response?.message,
          placement: "top",
        });
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });
  const toggle1 = () => {
    formik.resetForm();
    toggle();
  };
  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={toggle1}
      direction="end"
      className="w-full md:!w-[320px] 2xl:!w-[480px] flex flex-col h-full"
    >
      <FlexCol className="h-full flex flex-col">
        <OffcanvasHeader className="flex-shrink-0 p-4">
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              Update Password
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
              Enter new password and confirm password.
            </div>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody className="flex-1 overflow-y-auto min-h-0">
          <form onSubmit={formik.handleSubmit} className="h-full flex flex-col">
            <FormGroup className="flex-1 overflow-y-auto min-h-0">
              <FlexCol className="h-full" gap={20}>
                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                  <PasswordInput
                    title="New Password"
                    placeholder="New Password"
                    type="password"
                    value={formik.values.password}
                    change={(e) => formik.setFieldValue("password", e)}
                    error={formik.touched.password && formik.errors.password}
                    required={true}
                  />
                  <PasswordInput
                    title="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    value={formik.values.confirmPassword}
                    change={(e) => formik.setFieldValue("confirmPassword", e)}
                    error={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    required={true}
                  />
                </Flex>
              </FlexCol>
            </FormGroup>

            <div className="mt-auto sticky bottom-0 left-0 right-0 flex justify-end gap-1 bg-white">
              <CommonButton
                style={{
                  backgroundColor: "#fff",
                  color: "#726C6C",
                  border: "1px #CCCBCB solid",
                  marginRight: "8px",
                  fontSize: "12px",
                }}
                onClick={toggle1}
                outline
                title="Cancel"
                disabled={loading}
              />
              <CommonButton
                title="Change Password"
                style={{ fontSize: "12px" }}
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default ChangePassword;
