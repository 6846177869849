import React, { useEffect, useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import SearchBox from '../Common/SearchBox'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import ReportMonitoringComparisonGraph from '../Common/ReportMonitoringComparisonGraph'
import Cards from '../Common/Cards'
import InseminationReport from '../Breeding/InseminationReport'
import DeliveryReport from '../Breeding/DeliveryReport'
import API, { action } from '../../Api'
import { useNavigate } from 'react-router'
import Dropdownbtn from '../Common/Dropdownbtn'

function CattleBreedingReport() {
    const [pregnancyReport, setPregnancyReport] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [expectedData, setExpectedData] = useState([])
    const [filter, setFilter] = useState(null)
    const navigate = useNavigate()
    const data = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Inseminations",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter: pregnancyReport.inseminatin_data?.total_insemination_count || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Successful",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "-3.57",
            counter: pregnancyReport.inseminatin_data?.successfull_insemination_count || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Reinseminations",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter:pregnancyReport.inseminatin_data?.re_inseminated_count || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Failed",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: pregnancyReport.inseminatin_data?.failed_insemination_count || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]
    const Deliverydata = [
        {
            id: 5,
            cardColor: "primary",
            label: "Total Pregnancy",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+16.24",
            counter: pregnancyReport.delivery_data?.total_pregnant_count || 0,
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 6,
            cardColor: "secondary",
            label: "Successful",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            // percentage: "-3.57",
            counter: pregnancyReport.delivery_data?.successfull_delivery_count || 0,
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 7,
            cardColor: "success",
            label: "Pending",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            // percentage: "+29.08",
            counter: pregnancyReport.delivery_data?.pending_delivery_count || 0,
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 8,
            cardColor: "info",
            label: "Delivered Calf",
            badgeClass: "muted",
            // percentage: "+0.00",
            counter: pregnancyReport.delivery_data?.calf_count || 0,
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },]
    const [inseminationReport, setInseminationReport] = useState(false)
    const [deliveryReport, setDeliveryReport] = useState(false)
    const handleInseminationReport = () => {
        navigate("/reportsMonitoring/inseminationReport/report")
    }
    const handleDilveryReport = () => {
        navigate("/reportsMonitoring/Deleveryreport")
    }


    const getAllMonthNames = () => {
        return Array.from({ length: 12 }, (_, i) =>
            new Date(2024, i).toLocaleString('default', { month: 'short' })
        );
    };

    const getbreedingReport = async () => {
        const result = await action(API.GET_PREGNANCY_REPORT, {
            start_Date: null,
            end_Date: null,
            filterType: "today",
        });
        const responseData = result.response.data;
        setPregnancyReport(responseData);

        // Get all month names
        const allMonths = getAllMonthNames();

        // Create maps for both pregnant and inseminated data
        const pregnantDataMap = new Map(
            responseData?.monthly_report?.map(item => [
                item.month - 1,  // Convert to 0-based index
                item.pregnant
            ])
        );

        const inseminatedDataMap = new Map(
            responseData?.monthly_report?.map(item => [
                item.month - 1,  // Convert to 0-based index
                item.inseminated
            ])
        );

        // Create actual data array (pregnant) with all months
        const actualData = allMonths.map((monthName, index) => ({
            name: monthName,
            value: pregnantDataMap.get(index) || 0
        }));

        // Create expected data array (inseminated) with all months
        const expectedValues = allMonths.map((monthName, index) => ({
            name: monthName,
            value: inseminatedDataMap.get(index) || 0
        }));

        setCurrentData(actualData);
        setExpectedData(expectedValues);
    };


    useEffect(() => {
        getbreedingReport();
    }, [filter]);



    const cardClick = (item) => {
     if (item.label === 'Total Inseminations' || (item.label === 'Pending' && item.id === 3)) {
            navigate('/reportsMonitoring/inseminationReport/report');// Pass tab as part of state
        }
        else if (item.label === 'Successful') {
            navigate('/reportsMonitoring/PregnancyReport');// Pass tab as part of state
        }
        else if (item.label === 'Reinseminations') {
            navigate('/reportsMonitoring/inseminationReport/report', { state: { tab: "3" } });// Pass tab as part of state
        }
        else if (item.label === 'Failed') {
            navigate('/reportsMonitoring/inseminationReport/report', { state: { tab: "4" } });// Pass tab as part of state
        }
       
    }
    const cardClick2 = (item) => {
        if (item.label === 'Issue Cattle') {
            navigate('/healthMonitoring');
        }
        else if (item.label === 'Total Pregnancy') {
            navigate('/reportsMonitoring/PregnancyReport');// Pass tab as part of state
        }
        else if (item.label === 'Pending') {
            navigate('/reportsMonitoring/PregnancyReport');// Pass tab as part of state
        }
        else {
            navigate('/reportsMonitoring/Deleveryreport');
        }
    }

    const options = [
        {
            id: 1,
            text: "Today",
            value: "today"
        },
        {
            id: 2,
            text: "This Month",
            value: "this_month"
        },
        {
            id: 3,
            text: "This Year",
            value: "this_year"
        }
    ]

    const handleSelect = (optionText) => {

        // console.log(optionText,'ttt');


        const selectedOption = options.find(opt => opt.text == optionText);


        setFilter(selectedOption?.value);
    };
    console.log(filter, "ttt");
    return (
        <div style={{ backgroundColor: "#FAFAFA" }}>
            {(!inseminationReport && !deliveryReport) && (
                <FlexCol>
                    <div className="flex flex-col sm:flex-row justify-between">
                        <div className='flex gap-2'>
                            <Heading title='Cattle Breeding Report' />
                            {/* <Dropdownbtn title='' style={{ backgroundColor: "#FAFAFA", color: "#5A5555", border: '1px #CCCBCB solid' }} options={options.map(option => option.text)} onSelect={handleSelect} /> */}
                        </div>
                        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                            <div className="flex space-x-5">
                                {/* <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" /> */}
                                {/* <Filterbtn /> */}
                            </div>
                            <div className="flex space-x-5 sm:mt-4">

                                {/* <CommonButton title="Export" /> */}
                            </div>
                        </div>
                    </div>
                    <ReportMonitoringComparisonGraph title="Comparison" CategoryName="Pregnant" CategoryName2="Inseminated" currentData={currentData}
                        availableData={expectedData} />
                    <div className="flex flex-col sm:flex-row justify-between">
                        <Heading title='Insemination Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#6728F3] 2xl:text-[32px]" />
                        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">



                            {/* <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#6728F3", border: "1px #6728F3 solid", }} onClick={handleInseminationReport} /> */}

                        </div>
                    </div>
                    <Cards data={data} cardClick={cardClick} />
                    <div className="flex flex-col sm:flex-row justify-between">
                        <Heading title='Delivery Report' className="md:text-xl sm:text-md text-[18px] font-semibold text-[#FDBF26] 2xl:text-[32px]" />
                        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">



                            {/* <CommonButton title="View All Reports" style={{ backgroundColor: "none", color: "#FDBF26", border: "1px #FDBF26 solid", }} onClick={handleDilveryReport} /> */}

                        </div>
                    </div>
                    <Cards data={Deliverydata} cardClick={cardClick2} />
                </FlexCol>
            )}
            {inseminationReport && <InseminationReport />}
            {deliveryReport && <DeliveryReport />}
        </div>
    )
}

export default CattleBreedingReport