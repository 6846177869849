import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Dropdown, Menu, Avatar } from 'antd';
import { DownOutlined, UserOutlined, BellOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import avatar1 from "../../assets/Images/dummy user image.webp";
import API, { action } from '../../Api';
import { replace } from 'formik';

const ProfileDropdown = () => {

    const [userName, setUserName] =  useState(
        (localStorage.getItem("name")) );
        const [email,setEmail]= useState(
            (localStorage.getItem("email")) );

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const navigate = useNavigate();

    // Logout function
    const handleLogout = () => {
        localStorage.removeItem("usertype"); // Clear userType from localStorage
        localStorage.clear();
        sessionStorage.clear();
        navigate("/"); // Redirect to login page
        window.location.reload();

        
    };

    const handleaccountsettings = () => {
        navigate('/accountSettings')
    }
     // useNavigate() can be used because this is inside <BrowserRouter>

   const token = localStorage.getItem('usertype');
    
    // Function to check token expiration
    // const checkTokenExpiration = () => {
    //   const tokenExpiration = localStorage.getItem('tokenExpiration');
      
    //   if (tokenExpiration) {
    //     const currentTime = Date.now();
    //     if (currentTime > tokenExpiration) {
    //       // Token expired, perform logout
    //       localStorage.removeItem('usertype');
    //       localStorage.removeItem('name');
    //       localStorage.removeItem('email');
    //       localStorage.removeItem('mobile');
    //       localStorage.removeItem('userid');
    //       localStorage.removeItem('modulePrivileges');
    //       localStorage.removeItem('tokenExpiration');
          
    //       // Redirect to login page
    //       navigate("/");
    //     }
    //   }
    // };
  
    // Set a timer to log the user out before the token expires
    const setLogoutTimer = () => {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      
      if (tokenExpiration) {
        const timeout = tokenExpiration - Date.now() - 1000; // 1 second before expiration
        if (timeout > 0) {
          setTimeout(() => {
            // Token expired, perform logout
            localStorage.removeItem('usertype');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('mobile');
            localStorage.removeItem('userid');
            localStorage.removeItem('modulePrivileges');
            localStorage.removeItem('tokenExpiration');
            
         
            // Redirect to login page
            navigate("/");
            
            window.location.reload();
            
          }, timeout);
        }
      }
    };
  
    // useEffect(() => {
    //   // Check token expiration on app load
    //   checkTokenExpiration();
    //   // Set the logout timer
    //   setLogoutTimer();
     
    // }, [navigate]);

  //   const TokenValidation = async () => {
  //     try {
  //         const result = await action(API.TOKEN_VALIDATION, { token:token });
  
  //         console.log(result.status.code, "token validation");
  
  //         if (result.status.code !== 200) {
  //             console.warn('Token is invalid or expired. Logging out.');
  //             handleLogout();
  //         } else if (result.status.code === 200) {
  //             console.log('Token is valid.');
  //         } else {
  //             console.warn(`Unexpected status code: ${result.status.code}`);
  //         }
  //     } catch (error) {
  //         console.error('Error fetching token validation data:', error.message || error);
  //         // Optionally handle specific errors like network failure here
  //     }
  // };

  // useEffect(() => {
  //   TokenValidation();
     
  // }, []);

  const validateToken = async () => {
    const token = localStorage.getItem("usertype"); // Replace with your token storage logic
 
    if (!token) {
      console.log("No token found. Redirecting to login.");
      return;
    }
 
    try {
      const response = await action(API.TOKEN_VALIDATION, { token });
      if (response.data.status.code === 200) {
        console.log(response.data.response.message); // Token is valid
      }
    } catch (error) {
      if (error.response) {
        console.error(error.response.data.response.message);
        if (error.response.data.response.message === "Token expired, please log in again.") {
          console.log("Redirecting to login...");
          // Perform redirection to login
        }
        window.location.reload();
        handleLogout()
       
      } else {
        console.error("Error validating token:", error.message);
      }
    }
  };
 
  useEffect(() => {
    validateToken();
  }, []);

  const getInitial = () => {
    return userName ? userName.charAt(0).toUpperCase() : '?';
};
    const menu = (
      <Menu className='font-plus-jakarta-sans'>
        <Menu.Item key="welcome" disabled>
          <span>Welcome {userName}!</span>
        </Menu.Item>
        <Menu.Divider />
        {/* <Menu.Item key="profile" onClick={() => navigate('/profile')} icon={<UserOutlined />}>
          Profile
        </Menu.Item>
        <Menu.Item key="settings" onClick={handleaccountsettings} icon={<SettingOutlined />}>
          Account Settings
        </Menu.Item>
        <Menu.Item key="notifications" icon={<BellOutlined />}>
          Notification Settings
        </Menu.Item>
        <Menu.Divider /> */}
        <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    );
  
    return (
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className="flex items-center cursor-pointer">
        {/* <Avatar src={avatar1} size="large" /> */}
        <Avatar 
                    style={{ 
                        backgroundColor: '#DBFAE7',
                        verticalAlign: 'middle',
                        color:'#16A34A',
                        fontFamily:'Plus Jakarta Sans',
                        fontSize:'18px',
                    }}
                    size="large"
                >
                    {getInitial()}
                </Avatar>
        <div className="hidden lg:block text-left ml-2">
          <span className="text-[#09090B] text-[14px] font-[500] font-plus-jakarta-sans block">{userName}</span>
          <span className="text-[#71717A] text-[12px] font-[400] font-plus-jakarta-sans">{email}</span>
        </div>
        <DownOutlined className="ml-2 text-xs" />
      </div>
    </Dropdown>
    );
  };
  export default ProfileDropdown;