import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import FormInput from "../Common/FormInput";
import CommonButton from "../Common/Button";
import FlexCol from "../Common/FlexCol";
import { Flex } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup"; // You can use Yup for validation
import API, { action } from "../../Api";
import DropdownButton from "../Common/DropdownButton";
import { notification } from "antd";
import PasswordInput from "../Common/PasswordInput";

function CreateUser({ isOpen, toggle, selectedUserId }) {
  const [id, setId] = useState(selectedUserId);
  const [loading, setLoading] = useState(false);
  const [roledata, setRoledata] = useState([]);
  const [error, setErrors] = useState("");

  useEffect(() => {
    setId(selectedUserId);
  }, [selectedUserId]);

  // Modified to properly map role ID when setting initial values
  useEffect(() => {
    if (selectedUserId && selectedUserId.length > 0) {
      const userData = selectedUserId[0];

      // Find the matching role ID from roledata
      const userRoleId = roledata.find(
        (role) => role.label.toLowerCase() === userData.Role?.toLowerCase()
      )?.value;

      formik.setValues({
        userName: userData.UserID,
        email: userData.email,
        userRole: userRoleId, // Set the role ID instead of role name
        password: "",
        rePassword: "",
        id: userData.userId,
      });
    }
  }, [selectedUserId, roledata]); // Added roledata as dependency

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userName: "",
      email: "",
      userRole: "",
      password: "",
      rePassword: "",
      id: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Username is required."),
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Email is required."),
      userRole: Yup.string().required("User Role is required."),
      password: id
        ? Yup.string()
        : Yup.string().required("Password is required."),
      rePassword: id
        ? Yup.string()
        : Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match.")
            .required("Re-enter Password is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const customEmailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
        const numericOnlyRegex = /^\d+@/;

        if (values.email !== values.email.toLowerCase()) {
          setErrors("Email must be in lowercase only.");
        } else if (!customEmailRegex.test(values.email)) {
          setErrors("Please enter a valid email address.");
        } else if (numericOnlyRegex.test(values.email)) {
          setErrors("Email cannot start with numbers only.");
        } else {
          if (id) {
            await action(API.UPDATE_USER, {
              id: selectedUserId[0].userId,
              full_name: values.userName,
              privileges: values.permissions,
              role_id: values.userRole, // This will now always be the ID
              email: values.email,
            });
            notification.success({
              message: "User updated successfully!",
              placement: "top",
            });
          } else {
            await action(API.CREATE_USER, {
              full_name: values.userName,
              password: values.password,
              role_id: values.userRole,
              email: values.email,
            });
            notification.success({
              message: "User created successfully!",
              placement: "top",
            });
          }
          formik.resetForm();
          toggle();
          setErrors("");
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: error.response?.data?.response?.message,
          placement: "top",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const getRoleList = async () => {
    try {
      const result = await action(API.GET_ROLE, {
        page: 1,
        pageCount: 60,
        searchKey: "",
      });
      const roles = result.response.data.map((role) => ({
        label: role.role_name,
        value: role.id,
      }));
      setRoledata(roles);
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const customToggle = () => {
    setId(null);
    formik.resetForm();
    toggle();
  };
  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={customToggle}
      direction="end"
      className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
    >
      <FlexCol className="p-4">
        <OffcanvasHeader>
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              {id ? " User Updation" : " User Creation"}
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
              Enter user details and permissions.
            </div>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <FlexCol gap={20}>
                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                  <FormInput
                    title="Username"
                    placeholder="Enter Username"
                    value={formik.values.userName}
                    change={(e) => formik.setFieldValue("userName", e)}
                    error={formik.touched.userName && formik.errors.userName}
                    required={true}
                  />
                  <FormInput
                    title="Email"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    change={(e) => {
                      formik.setFieldValue("email", e);
                    }}
                    error={
                      (formik.touched.email && formik.errors.email) || error
                    }
                    required={true}
                  />
                  <DropdownButton
                    title="User Role"
                    placeholder="Select User Role"
                    value={formik.values.userRole}
                    change={(e) => formik.setFieldValue("userRole", e)}
                    error={formik.touched.userRole && formik.errors.userRole}
                    options={roledata}
                    required={true}
                    SelectName="Select User Role"
                  />
                  {!id && (
                    <>
                      <PasswordInput
                        title="Enter Password"
                        placeholder="Password"
                        type="password"
                        value={formik.values.password}
                        change={(e) => formik.setFieldValue("password", e)}
                        error={
                          formik.touched.password && formik.errors.password
                        }
                        required={true}
                        autoComplete="new-password"
                      />
                      <PasswordInput
                        title="Re Enter Password"
                        placeholder="Password"
                        type="password"
                        value={formik.values.rePassword}
                        change={(e) => formik.setFieldValue("rePassword", e)}
                        error={
                          formik.touched.rePassword && formik.errors.rePassword
                        }
                        required={true}
                        repeatFeild={true}
                      />
                    </>
                  )}
                </Flex>
              </FlexCol>
            </FormGroup>

            <div className="flex justify-between mt-8">
              <CommonButton
                style={{
                  backgroundColor: "none",
                  color: "#726C6C",
                  border: "1px #CCCBCB solid",
                  marginRight: "8px",
                  fontSize: "12px",
                }}
                onClick={customToggle}
                outline
                title="Cancel"
                disabled={loading}
              />
              <CommonButton
                title={id ? " Update User" : " Create User"}
                style={{ fontSize: "12px" }}
                type="submit"
                disabled={loading}
              />
            </div>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default CreateUser;
