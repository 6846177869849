import React from 'react'
import Heading from '../../Common/Heading'
import Cards from '../../Common/Cards'
import Graph from '../../Common/Graph'
import FlexCol from '../../Common/FlexCol'
import MyTask from '../../Common/MyTask'
import { Col, Row } from 'reactstrap'

function Userdashboard() {
    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <Heading
                    title='Cattle  Statistics' />
                <Cards />
                {/* <div className="grid grid-cols-16 gap-4 ">
       <div className="col-span-12 xl:col-span-8">
       <Graph/>
        </div>
        <div className="col-span-8 xl:col-span-8">
        <MyTask/>
        </div> 
         </div> */}
                <Row>
                    <Col xl={7}>
                        <Graph title='Milk Production' />
                    </Col>
                    <MyTask />
                </Row>

            </FlexCol>
        </div>
    )
}

export default Userdashboard