import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import TextArea from '../Common/TextArea';
import DateSelect from '../Common/DateSelector';
import { Flex, notification } from 'antd';
import FlexCol from '../Common/FlexCol';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import API, { action } from '../../Api';
import * as Yup from 'yup';
import { Trash2 } from 'lucide-react';
import Label from '../Common/Label';
function RecordDelivery({ isOpen, toggle, data }) {
    console.log(data, "ooo");

    const tabs = ['Male', 'Female'];
    const [loading, setLoading] = useState(false);

    const isDisabled = !!data?.[0]?.deleveryDate;

    const validationSchema = Yup.object().shape({
        deliveryDate: Yup.date().required('Delivery Date is required'),
        calves: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.string().required('Calf ID is required'),
                    weight: Yup.number()
                        .typeError('Weight must be a number')
                        .positive('Weight must be a positive number')
                        .required('Calf Weight is required'),
                    gender: Yup.string().oneOf(['Male', 'Female'], 'Gender is required'),
                })
            )
            .required('At least one calf is required'),
        // description: Yup.string().required('Description is required'),
    });
    const formik = useFormik({
        validationSchema,

        initialValues: {
            deliveryDate: '',
            calves: [{ id: '', weight: '', gender: 'Male' }],
            description: '',
            parentCattleId: '',  // Using a fallback value if data.CattleID is undefined
            id: '',

        },
        onSubmit: async (values) => {
            console.log(values);
            // Handle form submission here
            setLoading(true);
            try {
                // Perform the async action here
                const result = await action(API.ADD_DELIVERY, {

                    id: data?.[0]?.inseminationId,  // Ensure id is never undefined
                    parent_cattle_id: data?.[0]?.CattleID,
                    delivery_date: values.deliveryDate,
                    delivery_notes: values.description,
                    calves: values.calves.map((calf) => ({
                        calf_id: calf.id,
                        gender: calf.gender,
                        weight: calf.weight,
                    }))

                });
                notification.success({ message: result?.response?.message, placement: "top", });
                // Reset the form and close the offcanvas after submission
                formik.resetForm();
                customToggle();  // Assuming `toggle` is defined to close the offcanvas
            } catch (error) {
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
                // console.error(id ? 'Error updating role:' : 'Error creating role:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        }
    });


    useEffect(() => {
        if (data?.[0]?.status==="delivered") {
            // Map the nested calf array from the first object
            const calvesData = data[0]?.calf?.map(calf => ({
                id: calf.cattle_id || '',
                gender: calf.gender?.charAt(0).toUpperCase() + calf.gender?.slice(1) || 'Male', // Capitalize gender
                weight: calf.weight || ''
            })) || [{ id: '', weight: '', gender: 'Male' }];

            formik.setFieldValue("deliveryDate", data?.[0]?.deleveryDate || "");
            formik.setFieldValue("calves", calvesData);
        }
    }, [data]);


    const handleAddCalf = () => {
        formik.setFieldValue('calves', [
            ...formik.values.calves,
            { id: '', weight: '', gender: 'Male' },
        ]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedCalves = [...formik.values.calves];
        updatedCalves[index][field] = value;
        formik.setFieldValue('calves', updatedCalves);
    };
    const customToggle = () => {
        formik.resetForm({
            values: {
                deliveryDate: '',
                calves: [{ id: '', weight: '', gender: 'Male' }],
                description: '',
                parentCattleId: '',
                id: '',
            },
        });
        toggle();
    };

    const handleDeleteCalf = (indexToDelete) => {
        const updatedCalves = formik.values.calves.filter((_, index) => index !== indexToDelete);
        formik.setFieldValue('calves', updatedCalves);
    };
    return (
        <Offcanvas
            isOpen={isOpen}
            toggle={customToggle}
            direction="end"
            className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
        >
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Delivery
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Add delivery data and notes.
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <FormGroup>
                        {!isDisabled ? (
                            <FlexCol className={""} gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">
                                    <DateSelect
                                        title="Delivery Date"
                                        placeholder="Choose Delivery Date"
                                        value={formik.values.deliveryDate}
                                        change={(e) => formik.setFieldValue('deliveryDate', e)}
                                        error={formik.touched.deliveryDate && formik.errors.deliveryDate}
                                        required={true}
                                        disabledFutureDate={true}
                                        disabled={isDisabled}
                                    />
                                    {formik.values.calves?.map((calf, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                gap: 20,
                                                display: 'flex',
                                            }}
                                        >
                                            {!data?.[0]?.deleveryDate ? (
                                                <>
                                                    <div className="text-[#16A34A] flex justify-between text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                                        Calf Gender
                                                        {!isDisabled && formik.values.calves.length > 1 && (

                                                            <Trash2 className="w-3 h-3 text-red-500 cursor-pointer" onClick={() => handleDeleteCalf(index)} />

                                                        )}
                                                    </div>
                                                    <div className="flex gap-2">
                                                        {tabs.map((tab) => (
                                                            <div
                                                                key={tab}
                                                                onClick={() => handleInputChange(index, 'gender', tab)}
                                                                className={`p-2 rounded-lg cursor-pointer ${calf.gender === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'}
                                                             flex justify-center items-center`}
                                                            >
                                                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words font-plus-jakarta-sans">
                                                                    {tab}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                <FormInput
                                                    title="Calf Gender"
                                                    placeholder="Enter Calf Id"
                                                    value={data?.[0]?.gender}
                                                    // change={(e) => handleInputChange(index, 'id', e)}
                                                    disabled={isDisabled}
                                                    required={true}
                                                />
                                            )}
                                            <FormInput
                                                title="New Calf ID"
                                                placeholder="Enter New Calf ID"
                                                value={calf.id}
                                                change={(e) => handleInputChange(index, 'id', e)}
                                                error={formik.touched.calves?.[index]?.id && formik.errors.calves?.[index]?.id}
                                                required={true}
                                                disabled={isDisabled}
                                            />

                                            <FormInput
                                                title="Calf Weight"
                                                type='number'
                                                placeholder="Enter Calf Weight"
                                                value={calf.weight}
                                                change={(e) => handleInputChange(index, 'weight', e)}
                                                required={true}
                                                maxLength={4}
                                                disabled={isDisabled}
                                                error={formik.touched.calves?.[index]?.weight && formik.errors.calves?.[index]?.weight}
                                            />

                                        </div>
                                    ))}
                                    {!isDisabled ? (
                                        <>
                                            <CommonButton
                                                title="Add Another Calf"
                                                style={{ fontSize: '10px' }}
                                                onClick={handleAddCalf}
                                                disabled={isDisabled}
                                            />

                                            <TextArea
                                                title="Description"
                                                placeholder="Add Description"
                                                value={formik.values.description}
                                                change={(e) => formik.setFieldValue('description', e)}
                                                // error={formik.touched.description && formik.errors.description}
                                                // required={true}
                                            />
                                        </>
                                    ) : (
                                        null
                                    )}

                                </Flex>
                            </FlexCol>
                        ) : (
                            <>
                                <Label title='Delivery Date' value={formik.values.deliveryDate} />

                                {formik.values.calves.map((calf, index) => {
                                    console.log(formik?.values?.calves?.length, "length");

                                    return (
                                        <div key={index}>
                                            <Label title="New Calf ID" value={calf.id} />
                                            <Label title="Calf Gender" value={calf.gender} />
                                            <Label title="Calf Weight" value={calf.weight} />
                                            {index < formik.values.calves.length - 1 && <hr />}
                                        </div>
                                    );
                                })}


                            </>
                        )}
                    </FormGroup>
                    <div className="flex justify-between mt-8">

                        <CommonButton
                            style={{
                                fontSize: '12px',
                                backgroundColor: 'none',
                                color: '#726C6C',
                                border: '1px #CCCBCB solid ',
                                marginRight: '8px',
                            }}
                            onClick={customToggle}
                            outline
                            title="Discard"
                            disabled={loading}
                        />
                        {!isDisabled ? (

                            <CommonButton
                                title="Save Delivery"
                                style={{ fontSize: '12px' }}
                                onClick={() => formik.handleSubmit()}
                                disabled={loading}
                            />

                        ) : (
                            null
                        )}
                    </div>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default RecordDelivery;
