import React, { useState } from 'react'
import vaccine from "../../assets/Images/vaccine.png"
import CommonButton from './Button'
import RecordVaccine from '../Drawer/RecordVaccine'
import RecordDeworming from '../Drawer/RecordDeworming'
function Recordvaccine({ onDrawerClose }) {
  const [recordvaccine, setRecordvaccine] = useState(false)
  const [deworming, setDeworming] = useState(false)
  const handledeworming = () => {
    setDeworming(prevState => {

      if (prevState) {
        onDrawerClose()
      }
      return !prevState;
    });

  }
  const handleclick = () => {
    setRecordvaccine(prevState => {

      if (prevState) {
        onDrawerClose()
      }
      return !prevState;
    });
  }
  return (
    <div className="w-full h-full px-4 sm:px-8 md:px-16 lg:px-28 py-4 md:py-8 bg-gradient-to-br from-red-200/50 to-white/30 rounded-xl border-1 border-[#FFA7B0] flex flex-col justify-start items-center gap-7">
      <div className="flex flex-col justify-start items-center gap-1">
        <div className="text-red-600 text-sm sm:text-[24px] lg:text-[24px] 2xl:text-[32px] font-semibold leading-9 sm:leading-10 font-Plus Jakarta Sans">
          Record Vaccine
        </div>
        <div className="w-[237px] text-center text-gray-600 text-sm sm:text-xs 2xl:text-[14px] leading-3 sm:leading-6 font-Plus Jakarta Sans">
          Track vaccinations to keep your herd protected.
        </div>
      </div>
      <img className="w-[220px] h-[220px]" src={vaccine} />
      <div className="w-full h-auto flex flex-col items-start gap-2">
        <div className="flex w-full items-center justify-center gap-4">
          {/* <div className="flex items-center justify-center h-9 px-6 sm:px-7 py-2 border border-red-400 rounded-lg flex-1">
            <div className="text-red-600 text-xs leading-3">
              Record Deworming
            </div>
          </div>
          <div className="flex items-center justify-center w-full h-9 px-6 sm:px-7 py-2 bg-red-600 rounded-lg flex-1">
            <div className="text-white text-[5px]  font-plus-jakarta-sans">
              Record Vaccination
            </div>
          </div> */}
          <CommonButton title='Record Deworming' style={{ backgroundColor: "white", color: "#E4626F", fontSize: "12px", border: "1px #E4626F solid " }} onClick={handledeworming} />
          <CommonButton title='Record Vaccination' style={{ backgroundColor: "#E4626F", fontSize: "12px", width: "100%" }} onClick={handleclick} />
        </div>
      </div>
      <RecordVaccine isOpen={recordvaccine} toggle={handleclick} />
      <RecordDeworming isOpen={deworming} toggle={handledeworming} />
    </div>

  )
}

export default Recordvaccine