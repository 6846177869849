import axios from "axios";


const API = {
    // HOST: "http://192.168.29.122:8080/",
    // HOST : "https://api.jaivathottam.com/",
    HOST: process.env.REACT_APP_MAIN_URL,
    TOKEN_VALIDATION : {url: "api/validation", method: "POST"},
    GET_ROLE: { url: "api/get_role", method: "POST" },
    LOGIN: { url: "api/auth/signin", method: "POST" },
    CREATE_USER: { url: "api/auth/signup", method: "POST" },
    USER_LIST: { url: "api/user_list", method: "POST" },

    GET_ROLE_PERMISSION: { url: "api/get_modules", method: "GET" },
    ADD_ROLE_AND_PERMISSION: { url: "api/add_role", method: "POST" },
    UPDATE_PASSWORD: { url: "api/change_user_password", method: "POST" },
    UPDATE_ROLE: { url: "api/update_role", method: "POST" },
    UPDATE_STATUS: { url: "api/update_status", method: "POST" },
    UPDATE_USER: { url: "api/update_user", method: "POST" },


    //CATTLE BOARD
    CATTLE_LIST: { url: "api/get_cattles", method: "POST" },
    ADD_CATTLE: { url: "api/add_cattle", method: "POST" },
    GET_CATTLETYPE: { url: "api/get_cattle_type", method: "GET" },
    UPDATE_CATTLE: { url: "api/update_cattle", method: "POST" },
    GET_CATTLE_DETAILS: { url: "api/get_cattle_details", method: "POST" },
    GET_CATTLE_FILTER_GENDER: { url: "api/cattle_filter", method: "POST" },
    ADD_BREEDING_STATE:{url:"api/add_new_state", method: "POST"},
    GET_CATTLE_CULLING_LIST:{url:"api/get_all/cattles", method: "POST"},
    ADD_CULLING:{url:"api/save_culling", method: "POST"},



    //DIAGNOSIS
    ADD_DIAGNOSIS: { url: "api/add_diagnosis", method: "POST" },
    GET_DIAGNOSIS_LIST: { url: "api/get_diagnosis_list", method: "POST" },
    GET_CATTLE_ID: { url: "api/get_cattle_ids", method: "POST" },
    GET_MEDICATION_LIST: { url: "api/get_medication_list", method: "POST" },
    UPDATE_DIAGNOSIS_STATUS: { url: "api/update_diagnosis_status", method: "POST" },
    UPDATE_MEDICATION_STATUS: { url: "api/update_medication_status", method: "POST" },
    ADD_REVIEW: { url: "api/add_review", method: "POST" },
    DELETE_REMARKS: { url: "api/delete_review", method: "POST" },
    




    //BREEDING
    ADD_INSEMINATION: { url: "api/add_insemination", method: "POST" },
    GET_INSEMINATION: { url: "api/get_insemination", method: "POST" },
    MARK_PREGNANCY: { url: "api/mark_as_pregnant", method: "POST" },
    ADD_DELIVERY: { url: "api/add_delivery", method: "POST" },
    UPDATE_INSEMINATION: { url: "api/update_insemination_status", method: "POST" },

    GET_CATTLE_BREEDING_DATA: { url: "api/get_cattle_breeding_data", method: "POST" },
    GET_CATTLE_MILKING_DATA: { url: "api/get_cattle_milking_data", method: "POST" },
    GET_CATTLE_VACCINATION_DATA: { url: "api/get_cattle_vaccination_data", method: "POST" },
    GET_CATTLE_MEDICATION_DATA: { url: "api/get_cattle_medication_data", method: "POST" },
    GET_CATTLE_PREGNANCY_DATA: { url: "api/get_pregnancy", method: "POST" },



    //Breeding Dashboard  
    PENDING_CATTLE_LIST: { url: "api/pending_cattle_list", method: "POST" },
    INSEMINATION_REPORT: { url: "api/status_count", method: "POST" },
    DELIVERY_REPORT: { url: "api/delivery_report_count", method: "POST" },
    UPCOMMING_CATTLE_LIST: { url: "api/upcomming_cattle_list", method: "POST" },




    //MILKING
    ADD_MILKING: { url: "api/add_milking", method: "POST" },
    GET_MILKING: { url: "api/get_milking", method: "POST" },
    GET_TOP_CATTLE_DATA: { url: "api/top_cattle_data", method: "POST" },
    GET_MILK_DASHBOARD_DATA: { url: "api/get_dashboard_data", method: "POST" },


    //Vaccination
    ADD_VACCINATION: { url: "api/add_vaccination", method: "POST" },
    GET_VACCINATION: { url: "api/get_vaccination", method: "POST" },
    GET_VACCINATION_DASHBOARD: { url: "api/get_vaccination/dashboard_data", method: "POST" },
    GET_PENDING_VACCINATION: { url: "api/get_pending_vaccination", method: "POST" },
    UPDATE_VACCINATION: { url: "api/update_vaccination", method: "POST" },
    ADD_DEWORMING: { url: "api/add_deworm", method: "POST" },
    GET_DEWORMING: { url: "api/get_deworm", method: "POST" },
    UPDATE_DEWORMING: { url: "api/update_deworm", method: "POST" },

    //Dashboard
    GET_DASHBOARD_COUNT: { url: "api/get_dasboard_data", method: "POST" },
    GET_MEDICATION_TODAY_LIST: { url: "api/get_medication_today", method: "POST" },
    GET_VACCINATION_TODAY_LIST: { url: "api/get_vaccination_today", method: "POST" },
    GET_SICK_CATTLE_YEARLY_COUNT: { url: "api/yearly_diagnose_count", method: "POST" },
    GET_MONTHLY_DIAGNOSE_COUNT_COUNT: { url: "api/monthly_diagnose_count", method: "POST" },
    GET_DASHBOARD_CALENDAR_DATA: { url: "api/daily_report_dashboard", method: "POST" },



    //Reports
    GET_PREGNANCY_COUNT: { url: "api/pregnancy_report", method: "POST" },
    GET_MILKING_COUNT: { url: "api/milking_report", method: "POST" },
    GET_MEDICATION_COUNT: { url: "api/health_report", method: "POST" },
    GET_PREGNANCY_REPORT: { url: "api/pregnancy_detail_report", method: "POST" },
    GET_MILKING_REPORT: { url: "api/milking_detail_report", method: "POST" },
    GET_HEALTH_REPORT: { url: "api/health_detail_report", method: "POST" },
    GET_INSEMINATION_REPORT_EXPORT: { url: "api/insemination/report/download", method: "POST" },
    GET_PREGNANCY_REPORT_EXPORT: { url: "api/pregnancy/report/download", method: "POST" },
    GET_DELIVERY_REPORT_EXPORT: { url: "api/delivery/report/download", method: "POST" },
    GET_MILKING_REPORT_EXPORT: { url: "api/milking/report/download", method: "POST" },
    GET_VACCINATION_REPORT_EXPORT: { url: "api/vaccination/report/download", method: "POST" },
    GET_DEWORMING_REPORT_EXPORT: { url: "api/deworm/report/download", method: "POST" },
    GET_DIAGNOSIS_REPORT_EXPORT: { url: "api/diagnosis/report/download", method: "POST" },





    //File uploader
    ADD_CATTLE_IMAGE: { url: "api/upload/cattle_image", method: "POST" },
};



export default API;

// const token = JSON.parse(localStorage.getItem("LoginData"));
// const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwicm9sZSI6IkFkbWluIiwicm9sZV9pZCI6MSwiaWF0IjoxNzMwMDg5NDA0LCJleHAiOjE3MzAxNzU4MDR9.M5frwbppXeh0XFlT-m6px7GsxsA-8knDHV91SCdZe80`;
const token = (localStorage.getItem("usertype"));

const BASE_URL = `${API.HOST}`;

const action = async (endpoint, payload = {}, { headers = {} } = {}) => {
    const { url, method } = endpoint;
    const fullUrl = `${BASE_URL}${url}`;

    const defaultHeaders = {
        ' x-access-token': token,
        ...headers,
    };

    try {
        let response;
        switch (method) {
            case "GET":
                response = await axios.get(fullUrl, { params: payload, headers: defaultHeaders });
                break;
            case "POST":
                response = await axios.post(fullUrl, payload, { headers: defaultHeaders });
                break;
            case "PUT":
                response = await axios.put(fullUrl, payload, { headers: defaultHeaders });
                break;
            case "DELETE":
                response = await axios.delete(fullUrl, { headers: defaultHeaders, payload });
                break;
            default:
                throw new Error(`Unsupported request method: ${method}`);
        }
        return response.data;
    } catch (error) {
        console.error("API Request Error:", error);
        throw error;
    }
};

export { action };


