import React, { useEffect, useState } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

function Dropdownbtn({ title = "", options = [], style = {}, onSelect }) {
  const [selectedLabel, setSelectedLabel] = useState("");

  useEffect(() => {
    // Set the first option as the selected option when options are available
    if (options.length > 0) {
      setSelectedLabel(options[0].label);
      if (onSelect) {
        onSelect(options[0].value); // Notify parent about the default selection
      }
    }
  }, []);

  const handleSelect = (option) => {
    setSelectedLabel(option.label); // Update the selected label
    if (onSelect) {
      onSelect(option.value); // Pass the value when an option is clicked
    }
  };

  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 360) {
        setFontSize('5px'); // Very small screens (phones)
      } else if (screenWidth <= 480) {
        setFontSize('5px'); // Small phones
      } else if (screenWidth <= 600) {
        setFontSize('5px'); // Medium phones
      } else if (screenWidth <= 768) {
        setFontSize('6px'); // Tablets and small screens
      } else if (screenWidth <= 1024) {
        setFontSize('10px'); // Medium tablets and small laptops
      } else if (screenWidth <= 1280) {
        setFontSize('10px'); // Laptops and small desktops
      } else if (screenWidth <= 1440) {
        setFontSize('10px'); // Larger laptops and desktops
      } else {
        setFontSize('14px'); // Very large screens
      }
    }

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultStyle = {
    borderRadius: '8px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: fontSize,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '70%',
    backgroundColor: "#16A34A",
    color: "#fff",
  };

  const menu = (
    <Menu
      items={options.map((option, index) => ({
        key: index,
        label: (
          <div
            onClick={() => handleSelect(option)}
            style={{ cursor: 'pointer' }}
          >
            {option.label}
          </div>
        ),
      }))}
    />
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button
        style={{ ...defaultStyle, ...style }}
        onClick={(e) => e.preventDefault()}
      >
        {selectedLabel || title} <DownOutlined />
      </Button>
    </Dropdown>
  );
}

export default Dropdownbtn;
