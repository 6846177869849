import React, { useState, useEffect } from "react";
import {
  Col,
  FormGroup,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import FlexCol from "../Common/FlexCol";
import CommonButton from "../Common/Button";
import FormInput from "../Common/FormInput";
import { Flex, notification } from "antd";
import TextArea from "../Common/TextArea";
import DateSelect from "../Common/DateSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import API, { action } from "../../Api";
import DropdownButton from "../Common/DropdownButton";
import { MdOutlineDeleteOutline } from "react-icons/md";
import Label from "../Common/Label";

function DiagnoseCattle({ isOpen, toggle, selectedData }) {
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);

  const [medicines, setMedicines] = useState([
    { name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" },
  ]); // Change slot to an array
  const [cattleid, setCattleid] = useState([]);
  const tabs = ["Foot & Mouth", "Other"];
  const medicineNeededOptions = ["Yes", "No"];
  const medicineSlotOptions = ["Any", "Morning", "Noon", "Evening"];
  const isDisabled = !!selectedData;
  const isdisabled = !!selectedData?.[0]?.cured_notes;
  
  const id = localStorage.getItem('userid');
  const handleAddMedicine = () => {
    const newMedicine = {
      name: "",
      dosage: "",
      slots: ["Any"],
      startDate: "",
      endDate: "",
    }; // Change slot to an array
    setMedicines([...medicines, newMedicine]);
    formik.setFieldValue("medicines", [...medicines, newMedicine]);
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };

  const handleSlotChange = (index, slot) => {
    const updatedMedicines = [...medicines];
    let currentSlots = updatedMedicines[index].slots || [];

    if (slot === "Any") {
      // If "Any" is selected, clear all other slots
      currentSlots = currentSlots.includes("Any") ? [] : ["Any"];
    } else {
      // If another slot is selected, remove "Any" and toggle the current slot
      currentSlots = currentSlots.filter((s) => s !== "Any");
      if (currentSlots.includes(slot)) {
        currentSlots = currentSlots.filter((s) => s !== slot); // Deselect the slot
      } else {
        currentSlots.push(slot); // Select the slot
      }
    }
    if (currentSlots.length === 0) {
      currentSlots = ["Any"];
    }
    updatedMedicines[index].slots = currentSlots;
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };


  const formik = useFormik({
    initialValues: {
      issueType: "Foot & Mouth",
      issueDate: "",
      cattleId: "",
      severity: "normal",
      medicineNeeded: "No",
      modeOfAdministration: "",
      medicines: medicines,
      other: "",
    },
    validationSchema: Yup.object({
      issueType: Yup.string().required("Issue type is required"),
      issueDate: Yup.string().required("Issue date is required"),
      cattleId: Yup.string().required("Cattle id is required"),
      severity: Yup.string().required("Severity is required"),
      medicineNeeded: Yup.string().required("Required"),
      other: Yup.string().when('issueType', {
        is: 'Other',
        then: () => Yup.string().required('Other issue is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      // modeOfAdministration: Yup.string().required("Required").max(200, "Description is too long"),
      medicines: Yup.array().when('medicineNeeded', {
        is: 'Yes',
        then: () => Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required('Medicine name is required'),
            // dosage: Yup.string()
            //   .required('Dosage is required'),
            // slots: Yup.string()
            //   .required('Medicine slot is required'),
            startDate: Yup.string()
              .required('Start date is required'),
            endDate: Yup.string()
              .required('End date is required')
              .test('date-validation', 'End date must be after start date',
                function (value) {
                  const { startDate } = this.parent;
                  if (!startDate || !value) return true;
                  return new Date(value) > new Date(startDate);
                }),
          })
        ).min(1, 'At least one medicine is required'),
        otherwise: () => Yup.array()
      }),
    }),
    onSubmit: async (values) => {
      // console.log(values, "values");
      // if (selectedData && selectedData.length > 0) {
      //   formik2.handleSubmit(); // Submit formik2
      // } else {
      //   setLoading(true);
        try {

          // Map the medicines to the API format
          const formattedMedicines = values.medicines.map((medicine) => ({
            start_date: medicine.startDate,
            end_date: medicine.endDate,
            mode_of_administration: values.modeOfAdministration, // Added from form values
            medicine_name: medicine.name,
            dosage: medicine.dosage,
            //   slot: medicine.slots.join(", "),
            slot: medicine.slots,
          }));

          const result = await action(API.ADD_DIAGNOSIS, {
            issue_type: values.issueType,
            issue_date: values.issueDate,
            cattle_id: values.cattleId,
            severity: values.severity,
            need_medicine: values.medicineNeeded,
            created_by: id, // Replace with actual user ID if available
            medicines: formattedMedicines,
            other_issue: values.other
          });
          notification.success({
            message: result?.response?.message,
            placement: "top",
          });
          formik.resetForm();
          setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
          toggle(); // Close the offcanvas
        } catch (error) {
          console.error("Error creating diagnosis:", error);
          notification.error({
            message: error.response?.data?.response?.message,
            placement: "top",
          });
        } finally {
          setLoading(false); // Stop loading
        }
      }
    // },
  });

  const customToggle = () => {
    // setId(null);
    formik.resetForm(); // Set id to null
    setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
    toggle(); // Call the original toggle function
  };
  const severityOptions = [
    { value: "critical", label: "Critical" },
    { value: "normal", label: "Normal" },
    { value: "major", label: "Major" },
  ];

  const getCattleId = async () => {
    try {
      const result = await action(API.GET_CATTLE_ID, {
        searchKey: searchKey,
        page: 1,
        pageCount: 60,
      });
      const cattleID = result.response.data.map((id) => ({
        label: id.cattle_id, // Display name
        value: id.cattle_id, // Unique identifier
      }));
      setCattleid(cattleID); // Set data with both label and value

      console.log(cattleID); // Check if data is in correct format
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    getCattleId();
    console.log(cattleid, "cattleid");
  }, [searchKey]);


  useEffect(() => {
    if (selectedData && selectedData[0] && selectedData.length > 0) {
      // Set basic fields
      formik.setFieldValue("issueDate", selectedData[0]?.issue_date);
      formik.setFieldValue("severity", selectedData[0]?.severity);
      formik.setFieldValue("cattleId", selectedData[0]?.CattleID);
      formik.setFieldValue("issueType", selectedData[0]?.issue_type);
      formik.setFieldValue("medicineNeeded", selectedData[0]?.need_medicine);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("modeOfAdministration", selectedData[0]?.mode_of_administration);
      formik.setFieldValue("slot", selectedData[0]?.slot);
      formik2.setFieldValue("curednotes", selectedData[0]?.cured_notes);
      formik2.setFieldValue("cureddate", selectedData[0]?.cured_date);
      formik2.setFieldValue("remarks", selectedData[0]?.remark);

      // Set medicines
      if (selectedData[0]?.medicines && Array.isArray(selectedData[0].medicines)) {
        const medicinesFromData = selectedData[0].medicines.map((med) => {
          // Convert slot string to array as needed by your component
          let slotsArray = [];
          if (med.slot) {
            // Handle slot which could be a string (from API) or already an array
            if (typeof med.slot === 'string') {
              // Convert to lowercase for case-insensitive comparison
              const slotLower = med.slot.toLowerCase();
              if (slotLower === 'any') {
                slotsArray = ['Any'];
              } else if (slotLower === 'morning') {
                slotsArray = ['Morning'];
              } else if (slotLower === 'noon') {
                slotsArray = ['Noon'];
              } else if (slotLower === 'evening') {
                slotsArray = ['Evening'];
              } else if (slotLower.includes(',')) {
                // Handle comma-separated slots
                slotsArray = med.slot.split(',').map(s => s.trim());
              } else {
                // Default case
                slotsArray = ['Any'];
              }
            } else if (Array.isArray(med.slot)) {
              // If it's already an array, use it
              slotsArray = med.slot;
            }
          } else {
            // Default to 'Any' if no slot
            slotsArray = ['Any'];
          }
  
          return {
            name: med.medicine_name || "",
            dosage: med.dosage || "",
            slots: slotsArray,
            startDate: med.medication_date || "",
            endDate: med.end_date || "",
          };
        });
        
        setMedicines(medicinesFromData);
        formik.setFieldValue("medicines", medicinesFromData);
      }
    }
  }, [selectedData]);

  console.log(selectedData, "hhh");



  const formik2 = useFormik({
    initialValues: {
      id: "",
      curednotes: "",
      cureddate: "",
     
    },
    validationSchema: Yup.object({
      curednotes: Yup.string().required("Cured Note is required"),
      cureddate: Yup.string().required("Cured Date is required"),

    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {


        const result = await action(API.UPDATE_DIAGNOSIS_STATUS, {
          id: selectedData[0]?.id,
          cured_notes: values.curednotes,
          cured_date: values.cureddate,
          remark:values.remarks

        });
        notification.success({
          message: result?.response?.message,
          placement: "top",
        });
        formik2.resetForm();
        setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
        toggle(); // Close the offcanvas
      } catch (error) {
        console.error("Error creating diagnosis:", error);
        notification.error({
          message: error.response?.data?.response?.message,
          placement: "top",
        });
        // Handle the error as needed
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });


  const handleDeleteMedicine = (indexToDelete) => {

    const updatedMedicines = medicines.filter((_, index) => index !== indexToDelete);
    setMedicines(updatedMedicines);
    formik.setFieldValue("medicines", updatedMedicines);
  };

  const handleIssueTypeChange = (newIssueType) => {
    if (!isDisabled) {
      // Reset form but preserve the new issue type
      formik.resetForm({
        values: {
          ...formik.initialValues,
          issueType: newIssueType,
        }
      });
      // Reset medicines state
      setMedicines([{ name: "", dosage: "", slots: ["Any"], startDate: "", endDate: "" }]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (selectedData && selectedData.length > 0) {
      formik2.handleSubmit(); // Submit formik2
    } else {
      formik.handleSubmit();
    }
  };
  return (
    <Offcanvas
      isOpen={isOpen}
      toggle={customToggle}
      direction="end"
      className="w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto"
    >
      <FlexCol className="p-4">
        <OffcanvasHeader>
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              {formik2.values.cureddate ? "Diagnose Report" : "Diagnose Cattle"}
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">

              {formik2.values.cureddate ? "Diagnose Details and Summary" : " Enter symptoms and treatment."}
            </div>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              {!selectedData ? (
                <FlexCol gap={20}>
                  <Flex className="grid grid-cols-1 gap-[30px] items-center">
                    <div
                      style={{
                        flexDirection: "column",
                        gap: 14,
                        display: "flex",
                      }}
                    >

                      {selectedData ? (
                        <FormInput
                          title="Issue Type"
                          placeholder="Issue Type"
                          value={formik.values.issueType}
                          change={(e) => formik.setFieldValue("issueType", e)}
                          error={formik.touched.issueType && formik.errors.issueType}
                          disabled={isDisabled}
                          required={true}
                        />
                      ) : (

                        <>

                          <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                            Issue Type
                          </div>
                          <div className="flex gap-2">
                            {tabs.map((tab) => (
                              <div
                                key={tab}
                                onClick={() => !isDisabled && handleIssueTypeChange(tab)}
                                className={`p-2 rounded-lg cursor-pointer ${formik.values.issueType === tab
                                  ? "bg-[#E3E3E3]"
                                  : "bg-transparent"
                                  } flex justify-center items-center`}
                              >
                                <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                  {tab}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    {formik.values.issueType === "Other" && (
                      <FormInput
                        title="Other Issues"
                        placeholder="Enter Other Issues"
                        value={formik.values.other}
                        change={(e) => formik.setFieldValue("other", e)}
                        error={formik.touched.other && formik.errors.other}
                        disabled={isDisabled}
                        required={true}
                      />
                    )}
                    <DateSelect
                      title="Issue Date"
                      placeholder="Choose Issue Date"
                      value={formik.values.issueDate}
                      change={(e) => formik.setFieldValue("issueDate", e)}
                      error={formik.touched.issueDate && formik.errors.issueDate}
                      disabledFutureDate={true}
                      disabled={isDisabled}
                      required={true}
                    />

                    <DropdownButton
                      title="Cattle ID"
                      placeholder="Select Cattle ID"
                      value={formik.values.cattleId}
                      options={cattleid}
                      onSearch={setSearchKey}
                      change={(e) => formik.setFieldValue("cattleId", e)}
                      error={formik.touched.cattleId && formik.errors.cattleId}
                      disabled={isDisabled}
                      required={true}
                      SelectName='Select Cattle ID'
                    />

                    <DropdownButton
                      title="Severity"
                      placeholder="Critical"
                      value={formik.values.severity}
                      options={severityOptions}
                      change={(e) => formik.setFieldValue("severity", e)}
                      error={formik.touched.severity && formik.errors.severity}
                      disabled={isDisabled}
                      required={true}
                      SelectName='Select Severity'
                    />
                    {(!selectedData || selectedData.length === 0) && (
                      <div
                        style={{
                          flexDirection: "column",
                          gap: 14,
                          display: "flex",
                        }}
                      >
                        <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                          Need Medicine
                        </div>
                        <div className="flex gap-2">
                          {medicineNeededOptions.map((option) => (
                            <div
                              key={option}
                              onClick={() =>
                                formik.setFieldValue("medicineNeeded", option)
                              }
                              className={`p-2 rounded-lg cursor-pointer ${formik.values.medicineNeeded === option
                                ? "bg-[#E3E3E3]"
                                : "bg-transparent"
                                } flex justify-center items-center`}
                            >
                              <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                {option}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {formik.values.medicineNeeded === "Yes" &&

                      medicines.map((medicine, index) => (
                        <div
                          key={index}
                          style={{
                            flexDirection: "column",
                            gap: 20,
                            display: "flex",
                          }}
                        >
                          {(!selectedData || selectedData.length === 0) && (
                            <>
                              <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans flex justify-between items-center">
                                Medicine Slot
                                {medicines.length > 1 && (
                                  <div onClick={() => handleDeleteMedicine(index)} className="justify-end text-red-500 text-[16px] cursor-pointer ">
                                    <MdOutlineDeleteOutline />
                                  </div>
                                )}
                              </div>
                              <div className="flex gap-2">
                                {medicineSlotOptions.map((slot) => (
                                  <div
                                    key={slot}
                                    onClick={() => handleSlotChange(index, slot)}
                                    className={`p-2 rounded-lg cursor-pointer ${medicine.slots.includes(slot) ? "bg-[#E3E3E3]" : "bg-transparent"
                                      } flex justify-center items-center`}
                                  >
                                    <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                                      {slot}
                                    </div>
                                  </div>
                                ))}
                              </div>

                            </>
                          )}
                          <FormInput
                            title="Medicine Name"
                            placeholder="Enter Medicine Name"
                            value={medicine.name}
                            change={(e) => handleMedicineChange(index, "name", e)}
                            error={
                              formik.touched.medicines &&
                              formik.errors.medicines?.[index]?.name
                            }
                            disabled={isDisabled}
                            required={true}
                          />
                          <FormInput
                            title="Dosage (mg/bwt)"
                            placeholder="Enter Dosage"
                            type="number"
                            value={medicine.dosage}
                            change={(e) => handleMedicineChange(index, "dosage", e)}
                            error={
                              formik.touched.medicines &&
                              formik.errors.medicines?.[index]?.dosage
                            }
                            disabled={isDisabled}
                            // required={true}
                            maxLength={4}
                          />

                          <DateSelect
                            title="Start Date"
                            placeholder="Choose Start Date"
                            value={medicine.startDate}
                            change={(e) =>
                              handleMedicineChange(index, "startDate", e)
                            }
                            error={
                              formik.touched.medicines &&
                              formik.errors.medicines?.[index]?.startDate
                            }
                            disabled={isDisabled}
                            required={true}
                          // disableBeforeDate={new Date().toISOString().split('T')[0] || ""}
                          />
                          <DateSelect
                            title="End Date"
                            placeholder="Choose End Date"
                            value={medicine.endDate}
                            change={(e) =>
                              handleMedicineChange(index, "endDate", e)
                            }
                            error={
                              formik.touched.medicines &&
                              formik.errors.medicines?.[index]?.endDate
                            }
                            disabled={isDisabled}
                            required={true}
                            disableBeforeDate={formik.values.medicines?.[index]?.startDate || ""}
                          />
                        </div>
                      ))}
                    {formik.values.medicineNeeded === "Yes" && (
                      <>
                        {(!selectedData || selectedData.length === 0) && (
                          <CommonButton title="Add Medicine" onClick={handleAddMedicine} />
                        )}





                        {(!isDisabled || selectedData?.[0]?.mode_of_administration) && (
                          <TextArea
                            title="Mode of Administration"
                            placeholder="Enter mode of administration"
                            value={formik.values.modeOfAdministration}
                            change={(e) =>
                              formik.setFieldValue("modeOfAdministration", e)
                            }
                            error={
                              formik.touched.modeOfAdministration &&
                              formik.errors.modeOfAdministration
                            }
                            disabled={isDisabled}

                          />
                        )}
                      </>
                    )}

                  </Flex>
                </FlexCol>
              ) : (
                <>
                  <Label title='Issue Type' value={formik.values.issueType || ''} />

                  <Label title='Issue Date' value={formik.values.issueDate || ''} />
                  <Label title='Cattle ID' value={formik.values.cattleId || ''} />
                  <Label title='Severity' value={formik.values.severity
                    || ''} />
                  <Label title='Cattle' value={formik.values.cattleId
                    || ''} />
                  {formik.values.medicines.map((medicine, index) => {
                    return (
                      <div key={index}>
                         {index < formik.values.medicines.length - 1 && <hr />}
                        <Label title='Medicine Name' value={medicine.name || '--'} />
                        <Label title='Dosage (mg/bwt)' value={medicine.dosage || '--'} />
                        <Label title='Slots' value={Array.isArray(medicine.slots) ? medicine.slots.join(', ') : '--'} />
                        <Label title='Start Date' value={medicine.startDate || '--'} />
                        <Label title='End Date' value={medicine.endDate || '--'} />
                       
                      </div>
                    );
                  })}
                  <Label title='Mode of Administration' value={formik.values.modeOfAdministration || '--'} />
                  {selectedData?.[0]?.cured_notes && (
                    <>

                      <Label title='Cured Date' value={formik2.values.cureddate || '--'} />
                      <Label title='Cured Notes' value={formik2.values.curednotes || '--'} />
                      
                    </>
                  )}
                </>
              )}
              {selectedData && selectedData.length > 0 && !selectedData?.[0]?.cured_notes && (
                <>
                  <FlexCol gap={20}>
                    <Flex className="grid grid-cols-1 gap-[30px] items-center">
                      <div
                        style={{
                          flexDirection: "column",
                          gap: 14,
                          display: "flex",
                        }}
                      >
                        <DateSelect
                          title="Cured Date"
                          placeholder="Choose Cured Date"
                          value={formik2.values.cureddate || ""}
                          change={(e) => formik2.setFieldValue("cureddate", e)}
                          error={
                            formik2.touched.cureddate &&
                            formik2.errors.cureddate
                          }
                          disableBeforeDate={formik.values.issueDate}
                          disabled={isdisabled}
                          required={true}
                        />
                        <TextArea
                          title="Cured Notes"
                          placeholder="Enter Cured Notes"
                          value={formik2.values.curednotes || ""}
                          change={(e) => formik2.setFieldValue("curednotes", e)}
                          error={
                            formik2.touched.curednotes &&
                            formik2.errors.curednotes
                          }
                          disabled={isdisabled}
                          required={true}

                        />
                        
                      </div>
                    </Flex>
                  </FlexCol>
                </>
              )}
            </FormGroup>
            <Col className="flex justify-end mt-5 gap-2">
              {!selectedData?.[0]?.cured_notes && (
                <CommonButton title="Submit" type="submit" disabled={loading} />
              )}
              <CommonButton title="Cancel" type="button" onClick={toggle} disabled={loading} />
            </Col>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default DiagnoseCattle;
