import React, { useState } from "react";
import { CgMenuLeft } from "react-icons/cg";
import ProfileDropdown from "../Common/ProfileDropdown";
import Notification from "../Common/Notification";
import SearchBox from "../Common/SearchBox";
import logo from "../../../src/assets/Images/image 6.png";
import SideBar from "../Common/SideBar";

export default function Header() {
  const [hamburger, setHamburger] = useState(false);

  const toggleHamburger = () => {
    setHamburger(!hamburger);
  };

  return (
    <>
      <nav className="bg-white z-[999] w-full grid grid-cols-3 items-center px-4 lg:px-5 h-[70px] dark:bg-[#111626] border-b border-[#E3E3E3] fixed top-0 left-0">
        {/* Logo Section for large screens */}
        <div className="hidden lg:block lg:col-span-1">
          <img src={logo} alt="Brand Logo" className="w-[120px]" />
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="flex lg:hidden items-center col-span-1">
          <button onClick={toggleHamburger} aria-label="Toggle Sidebar">
            <CgMenuLeft size={30} />
          </button>
        </div>

        {/* SearchBox, Notification, ProfileDropdown */}
        <div className="flex items-center justify-end space-x-4 lg:space-x-8 col-span-2 lg:col-span-2">
          {/* Hide components if hamburger is open on mobile */}
          <div className={`${hamburger ? 'hidden' : 'flex'} lg:flex w-full justify-end items-center space-x-4 lg:space-x-8 col-span-2 lg:col-span-2`}>
          <div className="flex-[0.6]">
              {/* <SearchBox placeholder="Search Features" className="bg-[#F8F9FB]" /> */}
            </div>
            <div >
              {/* <Notification /> */}
            </div>
            <div>
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar for Mobile */}
      <SideBar isOpen={hamburger} onClose={toggleHamburger} />
    </>
  );
}
