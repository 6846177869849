import { Provider } from "react-redux";
import "./App.css";
import "../src/assets/css/style.css"
import Router from "./Router";
import { store } from "./slices/store";
import { ConfigProvider } from "antd";


// document.addEventListener("DOMContentLoaded", () => {
//   const pixelRatio = window.devicePixelRatio || 1; // Default to 1 if devicePixelRatio is undefined
//   const zoomLevel = Math.round(pixelRatio * 100); // Calculate zoom level percentage

//   // Reset zoom level for specific cases, e.g., 125%
//   let initialScale = 1 / pixelRatio;
//   if (zoomLevel === 125) {
//     initialScale = 1; // Normalize zoom for 125% zoom level
//   }

//   document
//     .querySelector("meta[name=viewport]")
//     .setAttribute(
//       "content",
//       `width=device-width, initial-scale=${initialScale}`
//     );
// });



function App() {
  return (
    <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#16A34A',
       

        // Alias Token
        // colorBgContainer: '#f6ffed',
      },
    }}
  >
    <Provider store={store}>
      <div className="App">
        <Router />
      </div>
    </Provider>
    </ConfigProvider>
  );
}

export default App;
