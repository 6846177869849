import React, { useState } from 'react'
import cartoonCow from "../../assets/Images/Cartoon Cow Character.png"
import CommonButton from './Button'
import MultipleMilkEntries from '../Drawer/MultipleMilkEntries'
function RecordMilk({handleclick}) {
  const [recordMilk, setRecordMilk] = useState(false)
  // const handleclick = () => setRecordMilk(!recordMilk)
  return (
    <div className="w-full h-full px-4 sm:px-8 md:px-16 lg:px-28 py-4 md:py-8 rounded-xl border-1 border-[#ACE2FA]  flex flex-col items-center gap-6 md:gap-7" style={{
      background: 'linear-gradient(140deg, rgba(172, 226, 250, 0.20) 0%, rgba(255, 255, 255, 0) 100%)'
    }}>
      <div className="flex flex-col items-center gap-2">
        <div className="text-[#0EA5E9] text-sm sm:text-2xl font-semibold leading-9 sm:leading-10 font-Plus Jakarta Sans">
          Record Milk
        </div>
        <div className="w-full sm:w-60 text-center text-gray-600 text-sm sm:text-xs leading-3 sm:leading-6 font-Plus Jakarta Sans">
          Capture today’s milk yield for precise tracking.
        </div>
      </div>
      <img src={cartoonCow} alt="Sack Bag" className="w-48 sm:w-54 h-auto" />
      <div className="w-full h-auto flex flex-col items-start gap-2">
        <div className=" w-full items-center justify-center gap-4">

          <CommonButton title='Record Milk' style={{
            backgroundColor: "#0EA5E9",
            fontSize: "12px",
            width: "100%",
            height: "2.25rem", // h-9 is 2.25rem
            paddingLeft: "1.5rem", // px-6 is 1.5rem
            paddingRight: "1.5rem",
            paddingTop: "0.5rem", // py-2 is 0.5rem
            paddingBottom: "0.5rem",
            ...(window.innerWidth >= 640 && { paddingLeft: "1.75rem", paddingRight: "1.75rem" }) // sm:px-7 is 1.75rem
          }} onClick={handleclick} />
        </div>
      </div>
      {/* <MultipleMilkEntries isOpen={recordMilk} toggle={handleclick} /> */}
    </div>
  )
}

export default RecordMilk