import React from 'react'
import FlexCol from '../../Common/FlexCol'
import Heading from '../../Common/Heading'
import Cards from '../../Common/Cards'
import { ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import Graph from '../../Common/Graph'
import PieChart from '../../Common/PieChart'
import MyTask from '../../Common/MyTask'
import StockInventoryGraph from './Stockinventorygraph/StockInventoryGraph'
import { FaChevronDown } from 'react-icons/fa'

function AdminDashboard() {
    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>

                <div className="d-flex justify-content-between align-items-center">
                    <Heading title='Revenue  Statistics' />
                    <ButtonGroup>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="button" className="btn d-flex align-items-center " style={{
                                color: '#898483',
                                textAlign: 'right',
                                fontFamily: 'Plus Jakarta Sans", sans-serif',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '120%'
                            }} id="dropdownMenuButton">
                                All Time  <FaChevronDown className='ml-1' />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>Menu item</DropdownItem>
                                <DropdownItem>Menu item</DropdownItem>
                                <DropdownItem>Menu item</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>


                </div>


                <Cards />
                {/* <div className="grid grid-cols-16 gap-4 ">
   <div className="col-span-12 xl:col-span-8">
   <Graph/>
    </div>
    <div className="col-span-8 xl:col-span-8">
    <MyTask/>
    </div> 
     </div> */}
                <Row>
                    <Col xl={7}>
                        <Graph title='Cattle Health Statistics' />
                    </Col>


                    <MyTask />

                </Row>
                {/* <Row style={{ marginTop: "5px" }}>
  <Col >
  <PieChart />
  </Col>
</Row> */}

            </FlexCol>
            <FlexCol>
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Heading title='Cattle  Statistics' />


                </div>


                <Cards />
                {/* <div className="grid grid-cols-16 gap-4 ">
   <div className="col-span-12 xl:col-span-8">
   <Graph/>
    </div>
    <div className="col-span-8 xl:col-span-8">
    <MyTask/>
    </div> 
     </div> */}
                <Row>
                    <Col xl={7}>
                        <PieChart />
                    </Col>
                    <Col xl={5}>

                        <StockInventoryGraph title='Stock Inventory' />
                    </Col>

                </Row>

            </FlexCol>
        </div>
    )
}
export default AdminDashboard