import React from 'react'
import CountUp from 'react-countup';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';

function ActivityLogCard({data=[],style={}}) {
    const cardStyle = {
        padding: '16px 24px',
       
        alignItems: 'center',
        borderRadius: '12px',
        border: '1px solid var(--Neutrals-Neutrals100, #E3E3E3)',
        background: '#FFF',
        width: '143px',
        height: "100%",
        display: 'flex', // Ensure flex properties work
        flexDirection: 'column', // Align children vertically
   ...style,
    };
    const carddata = [
        {
            id: 1,
            cardColor: "#16A34A",
            label: "Total Cattle",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "100",
            link: "View net earnings",
            bgcolor: "#16A34A",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Orders",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "72",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Customers",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "52",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "My Balance",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "81",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        
    ];
    const cardDataToDisplay = data.length > 0 ? data : carddata;
  return (
    <React.Fragment>
            <Row className="g-3">

                {cardDataToDisplay.map((item, key) => (
                    <>

                        <Col md={3} className=''>
                            <Card className=" 2xl:pr-[32px] 2xl:pt-[24px] 2xl:pb-[24px] 2xl:pl-[32px]  w-full h-full flex flex-col justify-between" >
                                <CardBody >
                                <div style={{ color: '#898483', fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', lineHeight: '14.80px', wordWrap: 'break-word', marginBottom: '8px' }}>
                                    Daily Activity log
                                        </div>
                                    <div className="">
                                   
                                        <div>
                                            <p className="fw-medium text-muted mb-2 " style={{
                                                fontFamily: "Plus Jakarta Sans",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: 700,
                                                lineHeight: "120%"
                                            }}>{item.label}</p>
                                           
                                           <div >
                                                <Progress value={25} style={{ height: '10px',backgroundColor: item.cardColor }}  color={item.cardColor}  />
                                            </div>

                                            {/* <div >
                                                <Progress value={25} style={{ height: '10px' }} />
                                            </div> */}


                                        </div>
                                         
                                       
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>


                    </>
                ))}

            </Row>


        </React.Fragment>
  )
}

export default ActivityLogCard