import React, { useState } from 'react'
import sackBag from "../../assets/Images/sack bag.png"
import CommonButton from './Button';
import GroupFeedingLog from '../Drawer/GroupFeedingLog';
function FeedCattle() {
  const [feedCattle,setFeedCattle]=useState(false)
  const handleclick=()=> setFeedCattle(!feedCattle)
  return (
    <div className="w-full h-full px-4 sm:px-8 md:px-16 lg:px-28 py-4 md:py-8 bg-gradient-to-br from-green-200 via-green-100 to-white rounded-xl border-1 border-[#B7F5CE] flex flex-col items-center gap-6 md:gap-7">
      <div className="flex flex-col items-center gap-2">
      <div className="text-[#16A34A] text-sm sm:text-2xl font-semibold leading-9 sm:leading-10 font-Plus Jakarta Sans">
          Feed Cattle
        </div>
        <div className="w-[237px] text-center text-[#726C6C] text-sm sm:text-xs leading-3 sm:leading-6 font-Plus Jakarta Sans">
          Track Feeding to keep your herd Healthy.
        </div>
      </div>
      <img src={sackBag} alt="Sack Bag" className="w-48 sm:w-54 h-auto" />
      <div className="w-full sm:w-84 h-14 p-2 flex flex-col items-start gap-2">
        <div className="flex items-center w-full">
          {/* <div className="flex items-center justify-center w-full h-9 px-6 sm:px-7 py-2 bg-green-700 rounded-lg">
            <div className="text-white text-sm font-semibold leading-3">
              Feed Cattle
            </div>
          </div> */}
           <div className="w-full h-auto flex flex-col items-start gap-2">
        <div className=" w-full items-center justify-center gap-4">
        
          <CommonButton title='Feed Cattle' style={{
            backgroundColor: "#16A34A",
            fontSize: "12px",
            // fontFamily:"sa",
            width: "100%",
            height: "2.25rem", // h-9 is 2.25rem
            paddingLeft: "1.5rem", // px-6 is 1.5rem
            paddingRight: "1.5rem",
            paddingTop: "0.5rem", // py-2 is 0.5rem
            paddingBottom: "0.5rem",
            ...(window.innerWidth >= 640 && { paddingLeft: "1.75rem", paddingRight: "1.75rem" }) // sm:px-7 is 1.75rem
          }} onClick={handleclick} />
        </div>
      </div>
        </div>
      </div>
      <GroupFeedingLog isOpen={feedCattle} toggle={handleclick}/>
    </div>
  );
}
 


export default FeedCattle 