import React, { useEffect, useState } from 'react';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlexCol from '../Common/FlexCol';
import { Flex, notification } from 'antd';
import DateSelect from '../Common/DateSelector';
import FormInput from '../Common/FormInput';
import CommonButton from '../Common/Button';
import MultiSelector from '../Common/MultiSelector';
import API, { action } from '../../Api';
import Label from '../Common/Label';
import DropdownButton from '../Common/DropdownButton';
import { MdOutlineDeleteOutline } from 'react-icons/md';

function MultipleMilkEntries({ isOpen, toggle, selectedCattle }) {
    const [activeTab, setActiveTab] = useState('Heat');
    const tabs = ['morning', 'evening', 'both'];
    const [cattleid, setCattleid] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const id = localStorage.getItem('userid');
    const [loading, setLoading] = useState(false);
    const [cattleEntries, setCattleEntries] = useState([
        { id: '', quantity: '' }
    ]);
    console.log(selectedCattle, "selectedCattle");

    const isDisabled = !!selectedCattle;
    const handleAddCattleEntry = () => {
        const newEntry = { id: '', quantity: '' };
        setCattleEntries([...cattleEntries, newEntry]);
        formik.setFieldValue("cattleEntries", [...cattleEntries, newEntry]);
    };

    // Function to update a cattle entry
    const handleEntriesChange = (index, field, value) => {
        const updatedEntries = [...cattleEntries];
        updatedEntries[index][field] = value;
        setCattleEntries(updatedEntries);
        formik.setFieldValue("cattleEntries", updatedEntries);
    };

    // Function to delete a cattle entry
    const handleDeleteEntry = (indexToDelete) => {
        if (cattleEntries.length > 1) {
            const updatedEntries = cattleEntries.filter((_, index) => index !== indexToDelete);
            setCattleEntries(updatedEntries);
            formik.setFieldValue("cattleEntries", updatedEntries);
        }
    };

    const getFilteredCattleOptions = (currentIndex) => {

        const selectedIds = cattleEntries
            .filter((entry, idx) => idx !== currentIndex && entry.id)
            .map(entry => entry.id);

        // Filter out the already selected IDs from the options
        return cattleid.filter(option => !selectedIds.includes(option.value));
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            milkingSlot: 'morning',
            milkingDate: '',
            // cattle: selectedCattle && selectedCattle.length > 0 ? [selectedCattle[0].CattleID] : [],
            // quantity: '',
            cattle: cattleEntries
        },
        validationSchema: Yup.object({
            milkingDate: Yup.date().required('Milking Date is required'),
            // cattle: !selectedCattle || selectedCattle.length === 0
            //     ? Yup.array().min(1, 'Select at least one cattle').required('Cattle selection is required')
            //     : Yup.array(), 
            // quantity: Yup.number().required('Quantity is required').positive('Quantity must be greater than zero'),
            cattleEntries: !selectedCattle || selectedCattle.length === 0
                ? Yup.array().of(
                    Yup.object().shape({
                        id: Yup.string().required('Cattle ID is required'),
                        quantity: Yup.number().required('Quantity is required').positive('Quantity must be greater than zero')
                    })
                ).min(1, 'At least one cattle entry is required')
                : Yup.array(),
        }),
        onSubmit: async (values) => {
            console.log(values, "values")
            setLoading(true);
            try {
                const cattleId = values.cattle.length > 0 ? values.cattle : [selectedCattle[0]?.CattleID];
                await action(API.ADD_MILKING, {
                    cattle_id: values.cattleEntries,
                    milking_date: values.milkingDate,
                    // quantity: values.quantity,

                    slot: values.milkingSlot,

                    created_by: id
                });
                notification.success({ message: "Record milk added successfully!", placement: "top", });


                formik.resetForm();
                setCattleEntries([{ id: '', quantity: '' }])
                toggle();
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
            } finally {
                setLoading(false);
            }
        },
    });


    const toggle1 = () => {
        formik.resetForm()
        setCattleEntries([{ id: '', quantity: '' }])
        toggle()
    }
    useEffect(() => {
        if (selectedCattle && selectedCattle.length > 0) {


            console.log(selectedCattle[0].milking_date, "milkingDate");


            formik.setFieldValue("milkingSlot", selectedCattle[0].slot || 'morning');
            formik.setFieldValue("milkingDate", selectedCattle[0].milking_date || ""); // Date object is now set
            formik.setFieldValue("quantity", selectedCattle[0].quantity || '');
        }
    }, [selectedCattle]);

    const getCattleId = async () => {
        try {
            console.log(formik.values.cattle, "lklk");

            const result = await action(API.GET_CATTLE_FILTER_GENDER, {
                searchKey: searchKey,
                page: 1,
                pageCount: 60,
                type: 2

            });
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id,
                value: id.cattle_id
            }));
            setCattleid(cattleID);
        } catch (error) {
            console.error("Error fetching cattle data:", error);
        }
    };

    useEffect(() => {

        getCattleId();

    }, [searchKey]);

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className="w-full md:!w-[400px] 2xl:!w-[480px] max-h-full overflow-y-auto">
            <FlexCol className={"p-4"}>
                <OffcanvasHeader>
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            Record Milk
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Log  Daily  Milk  Production
                        </div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            {!isDisabled ? (
                                <FlexCol className={""} gap={20}>
                                    <Flex className="grid grid-cols-1 gap-[10px] items-center">
                                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                                            <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                                                Milking Slot
                                            </div>
                                            <div className="flex gap-2">
                                                {tabs.map((tab) => (
                                                    <div
                                                        key={tab}
                                                        onClick={() => !isDisabled && formik.setFieldValue('milkingSlot', tab)}
                                                        // Update Formik value when tab clicked
                                                        className={`p-2 rounded-lg cursor-pointer ${formik.values.milkingSlot === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                                                    >
                                                        <div className={`text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words capitalize font-plus-jakarta-sans`}>
                                                            {tab}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Milking Date Picker */}
                                        <DateSelect
                                            title="Milking Date"
                                            placeholder="Choose Milking Date"
                                            value={formik.values.milkingDate}
                                            change={value => formik.setFieldValue('milkingDate', value)}
                                            error={formik.touched.milkingDate && formik.errors.milkingDate}
                                            disabled={isDisabled}
                                            required={true}
                                            disabledFutureDate={true}
                                        />

                                        {/* Cattle Selector */}
                                        {selectedCattle && selectedCattle.length > 0 ? (
                                            <FormInput
                                                title="Select Cattles"
                                                placeholder="1865"
                                                value={selectedCattle[0].CattleID}
                                                onChange={value => formik.setFieldValue('cattle', value)}
                                                disabled="true"

                                            />
                                        ) : (
                                            <>
                                                {cattleEntries.map((entry, index) => (
                                                    <div key={index} className='flex gap-4 items-end'>
                                                        <div className='w-44'>
                                                            <DropdownButton
                                                                title="Select Cattle"
                                                                placeholder="Select Cattle"
                                                                options={getFilteredCattleOptions(index)}
                                                                value={entry.id}
                                                                onSearch={setSearchKey}
                                                                required={true}
                                                                change={(value) => handleEntriesChange(index, 'id', value)}
                                                                error={formik.touched.cattleEntries && formik.errors.cattleEntries?.[index]?.id}
                                                            />
                                                        </div>
                                                        <div className='w-20'>
                                                            <FormInput
                                                                title="Quantity"
                                                                placeholder="Enter Quantity"
                                                                type='number'
                                                                value={entry.quantity}
                                                                change={(e) => handleEntriesChange(index, 'quantity', e)}
                                                                error={formik.touched.cattleEntries && formik.errors.cattleEntries?.[index]?.quantity}
                                                                required={true}
                                                                maxLength={4}
                                                            />
                                                        </div>
                                                        <div className="flex items-center h-10 mt-6">
                                                            {index === 0 ? (
                                                                <CommonButton
                                                                    title="+"
                                                                    onClick={handleAddCattleEntry}
                                                                    style={{ fontSize: "10px" }}
                                                                />
                                                            ) : (
                                                                // <div className="text-red-500 text-[16px] cursor-pointer" onClick={() => handleDeleteEntry(index)}>
                                                                   <CommonButton
                                                                    title="-"
                                                                    onClick={() => handleDeleteEntry(index)}
                                                                    style={{ fontSize: "10px", backgroundColor:"red" }}
                                                                />
                                                                // </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* Add Cattle Entry Button */}

                                            </>
                                        )}
                                        {/* Quantity Input */}
                                        {/* <FormInput
                                            title="Quantity"
                                            placeholder="Enter Quantity"
                                            type='number'
                                            value={formik.values.quantity}
                                            change={formik.handleChange('quantity')}
                                            error={formik.touched.quantity && formik.errors.quantity}
                                            disabled={isDisabled}
                                            required={true}
                                            maxLength={4}
                                        /> */}

                                    </Flex>
                                </FlexCol>
                            ) : (
                                <>
                                    <Label title=' Milking Slot' value={formik.values.milkingSlot} />
                                    <Label title='Milking Date' value={formik.values.milkingDate} />
                                    <Label title='Selected Cattles' value={selectedCattle?.[0]?.CattleID} />
                                    <Label title='Quantity' value={formik.values.quantity} />
                                </>
                            )}
                        </FormGroup>

                        <div className="flex justify-between mt-8">
                            {isDisabled ? (
                                <CommonButton
                                    style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                                    onClick={toggle1}
                                    title="Cancel"
                                />
                            ) : (
                                <>
                                    <CommonButton
                                        style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                                        onClick={toggle1}
                                        outline
                                        title="Discard"
                                        disabled={loading}
                                    />
                                    <CommonButton
                                        type="submit"
                                        title="Save"
                                        style={{ fontSize: "12px" }}
                                        disabled={loading}
                                    />
                                </>
                            )}
                        </div>
                    </form>
                </OffcanvasBody>
            </FlexCol>
        </Offcanvas>
    );
}

export default MultipleMilkEntries;
