import React, { useState } from 'react';
import FlexCol from '../Common/FlexCol';
import Heading from '../Common/Heading';
import SearchBox from '../Common/SearchBox';
import { Input } from 'reactstrap';

// Initial notifications data
const initialNotifications = [
    {
        title: 'Allow Notification',
        description: 'Receive alerts & Notifications for Farm Management',
        isEnabled: true,
    },
    {
        title: 'Cattle Health Alerts',
        description: 'Receive alerts for health check reminders and Health incidents',
        isEnabled: true,
    },
    {
        title: 'Breeding Notifications',
        description: 'Get updates on breeding cycles, Delivery schedules, and pregnancy confirmations.',
        isEnabled: true,
    },
    {
        title: 'Milk Production Updates',
        description: 'Stay informed about daily milk yield, quality reports',
        isEnabled: true,
    },
    {
        title: 'Vaccination & Deworming Alerts',
        description: 'Receive alerts for Vaccination reminders and Deworming Alerts',
        isEnabled: true,
    },
    {
        title: 'Insemination Alerts',
        description: 'Receive alerts for health check reminders and Health incidents',
        isEnabled: true,
    },
    {
        title: 'Feed Management Alerts',
        description: 'Receive reminders for feed inventory levels, Replenishment, and nutrition updates',
        isEnabled: true,
    },
    {
        title: 'General Updates',
        description: 'Receive alerts & Notification of General Updates',
        isEnabled: true,
    },
];
function handleToggle(item) {
    // Handle toggle logic here
    console.log('Toggle status for item:', item);
    // You can add any logic here, like making an API call to update the status
  }
// Notification Item component
const NotificationItem = ({ title, description, isEnabled, onToggle }) => {
    return (
        <div className="flex items-center justify-between border-b border-[#E3E3E3] gap-6 mb-6">
            <div className="flex flex-col justify-start gap-1 w-full ">
                <div className="text-[#0A0B0A] 2xl:text-lg  md:text-[16px] sm:text-xl text-xs   font-semibold leading-[21.6px] break-words font-plus-jakarta-sans">
                    {title}
                </div>
                <div className="text-[#726C6C] 2xl:text-lg  md:text-[16px] sm:text-xl text-xs font-normal leading-[21.6px] break-words mb-2 font-plus-jakarta-sans">
                    {description}
                </div>
            </div>
            <div className="form-check form-switch">
                          <Input className="form-check-input " type="checkbox" role="switch" id="SwitchCheck4" defaultChecked="true" onChange={() => handleToggle()} style={{ backgroundColor: "#16A34A", border: "none" }} />
                          <span className="slider" />
                        </div>
        </div>
    );
};

// Tab Content component
const TabContent = ({ activeTab, notifications, toggleNotification }) => {
    switch (activeTab) {
        case 'profile':
            return <div>Profile Content</div>;
        case 'notifications':
            return (
                <div style={{ backgroundColor: '#FDFDFD' }}>
                    {notifications.map((notification, index) => (
                        <NotificationItem 
                            key={index} 
                            title={notification.title} 
                            description={notification.description} 
                            isEnabled={notification.isEnabled} 
                            onToggle={() => toggleNotification(index)} // Pass the toggle handler
                        />
                    ))}
                </div>
            );
        case 'alertManagement':
            return <div>Alert Management Content</div>;
        case 'password':
            return <div>Password Content</div>;
        default:
            return <div>Select a tab</div>;
    }
};

// Main Account Settings component
function AccountSettings() {
    const [activeTab, setActiveTab] = useState('notifications'); // Default active tab
    const [notifications, setNotifications] = useState(initialNotifications); // State for notifications

    // Toggle notification function
    const toggleNotification = (index) => {
        setNotifications((prevNotifications) => {
            const newNotifications = [...prevNotifications];
            newNotifications[index].isEnabled = !newNotifications[index].isEnabled; // Toggle isEnabled
            return newNotifications;
        });
    };

    const tabs = [
        { label: 'Profile', value: 'profile' },
        { label: 'Notifications', value: 'notifications' },
        { label: 'Alert Management', value: 'alertManagement' },
        { label: 'Password', value: 'password' },
    ];

    return (
        <div style={{ backgroundColor: "#FDFDFD", width: '100%' }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Account Settings' />
                    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-5">
                        <div className="flex space-x-5">
                            <SearchBox placeholder="Search Cattle" className="bg-[#F8F9FB]" />
                        </div>
                    </div>
                </div>
                <div className="flex mt-4">
                    {/* Vertical Tabs */}
                    <div style={{ flexDirection: 'column', display: 'flex', gap: 12 }}>
                        {tabs.map(tab => (
                            <div 
                                key={tab.value}
                                style={{
                                    alignSelf: 'stretch',
                                    padding: '12px 16px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    color: activeTab === tab.value ? '#16A34A' : '#726C6C',
                                    backgroundColor: activeTab === tab.value ? '#DBFAE7' : 'transparent',
                                    borderLeft: activeTab === tab.value ? '2px #16A34A solid' : 'none',
                                    fontWeight: '600',
                                    fontSize: 16,
                                    fontFamily: 'Plus Jakarta Sans',
                                }}
                                onClick={() => setActiveTab(tab.value)}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>
                    {/* Content Area */}
                    <div className="flex-1 pl-8">
                        <TabContent activeTab={activeTab} notifications={notifications} toggleNotification={toggleNotification} />
                    </div>
                </div>
            </FlexCol>
        </div>
    );
}

export default AccountSettings;
