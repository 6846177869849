import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import ReportChart from '../Reports Monitoring/ReportChart';

function ReportMonitoringComparisonGraph({ 
  title, 
  CategoryName, 
  CategoryName2, 
  availableData, // New prop for available data
  currentData,   // New prop for current data
  onPeriodChange // Optional prop for period change handler
}) {
    const [chartData, setChartData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);
    const [chartcolor, setChartColor] = useState([]);

    // Set chart colors based on category
    useEffect(() => {
        switch(CategoryName) {
            case "Assigned":
                setChartColor(['#E4626F', '#FFA7B0']);
                break;
            case "Milked":
                setChartColor(['#0EA5E9', '#fff']);
                break;
            case "Pregnant":
                setChartColor(['#FDBF26', '#FEEAB7']);
                break;
            case "Stock":
                setChartColor(['#16A34A', '#DBFAE7']);
                break;
            default:
                setChartColor(['#1f77b4', '#ff7f0e']);
        }
    }, [CategoryName]);

    // Update chart data when props change
    useEffect(() => {
        if (currentData?.length > 0 || availableData?.length > 0) {
            let formattedData;
            
            if (CategoryName === "Milked") {
                // Single series for Milked category
                formattedData = [
                    {
                        name: CategoryName,
                        type: 'bar',
                        data: currentData.map(item => item.value),
                    }
                ];
            } else {
                // Comparison view for other categories
                formattedData = [
                    {
                        name: CategoryName,
                        type: 'bar',
                        data: currentData.map(item => item.value),
                    },
                    {
                        name: CategoryName2,
                        type: 'bar',
                        data: availableData.map(item => item.value),
                    }
                ];
            }

            const categories = [...new Set([...availableData, ...currentData].map(item => item.name))];

            setChartData(formattedData);
            setChartCategories(categories);
        }
    }, [currentData, availableData, CategoryName, CategoryName2]);

    const handlePeriodChange = (pType) => {
        if (onPeriodChange) {
            onPeriodChange(pType);
        }
    };
    const chartOptions = {
        tooltip: {
          style: {
            color: '#000000' // Black tooltip text
          }
        }
      };

    return (
        <React.Fragment>
            <div className="2xl:p-6 p-2 bg-white rounded-xl border border-gray-300 text-black font-plus-jakarta-sans">
                <CardBody className="p-0 pb-2">
                    <div className="w-100">
                        <div className="border-0 align-items-center d-flex ml-5">
                            <h4 className="card-title mb-0 flex-grow-1 mt-2" style={{
                                color: "#495057",
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}>{title}</h4>
                        </div>
                        <div dir="ltr">
                            <ReportChart
                                series={chartData}
                                dataColors='["--vz-secondary", "--vz-primary", "--vz-primary-rgb, 0.50"]'
                                categories={chartCategories}
                                barColors={chartcolor}
                            />
                        </div>
                    </div>
                </CardBody>
            </div>
        </React.Fragment>
    );
}

export default ReportMonitoringComparisonGraph;