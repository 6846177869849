import React, { useState } from 'react'
import FlexCol from '../Common/FlexCol'
import Heading from '../Common/Heading'
import Cards from '../Common/Cards'
import Graph from '../Common/Graph'
import StockInventoryGraph from '../Dashboard/AdminDashbord/Stockinventorygraph/StockInventoryGraph'
import ReactTable from '../Common/Table'
import Filterbtn from '../Common/Filterbtn'
import CommonButton from '../Common/Button'
import { Color } from 'antd/es/color-picker'
import { Col, Row } from 'reactstrap'
import FeedCattle from '../Common/FeedCattle'
import PieChart from '../Common/PieChart'
import FieldAlertlist from './FieldAlertlist'
import NewStockEntry from '../Drawer/NewStockEntry'
import NewFeed from '../Drawer/NewFeed'

function FeedInventory() {
    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Item Name",
            value: "itemName",


        },
        {
            id: 3,
            title: "Stock",
            value: "stock",

        },

        // {
        //   id: 4,
        //   title: t("Created_On"),
        //   value: "createdOn",
        //   dataIndex: "createdOn",
        //   sorter: (a, b) => {
        //     const dateA = new Date(a.createdOn);
        //     const dateB = new Date(b.createdOn);
        //     return dateA.getTime() - dateB.getTime();
        //   },
        //   sortOrder: "ascent",
        // },
        {
            id: 5,
            title: "Last Updated",
            value: "lastUpdated",


        },
        {
            id: 5,
            title: "Action",
            value: "action",


        },



    ]

    const data = [
        {
            SlNo: "1",
            itemName: "Daily Brand 01",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "2",
            itemName: "Special Brand03",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "3",
            itemName: "Special Brand03",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "4",
            itemName: "Daily Brand 01",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "5",
            itemName: "Daily Brand 01",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "6",
            itemName: "Special Brand03",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },
        {
            SlNo: "7",
            itemName: "Daily Brand 01",
            stock: "562 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },

        {
            SlNo: "8",
            itemName: "Special Brand03",
            stock: "567 kg",
            lastUpdated: "06-09-2024",
            action: "Add Stock",

        },


    ];
    const Inseminated_Data = [{
        SlNo: "1",
        cattleId: "Cattle No.1856",
        lactationNumber: "2",
        lastCalvingDate: "06-09-2024",
        heatType: "Natural",
        heatDate: "11-10-2024",
        status: "Inseminated"
    },
    {
        SlNo: "2",
        cattleId: "Georgie Winters",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Artificial",  // Added a value for Heat Type
        heatDate: "11-10-2024",         // Placeholder if not available
        status: "Pregnant"
    },
    ]
    const HeatedCattle_Data = [{
        SlNo: "1",
        cattleId: "Cattle No.1856",
        lactationNumber: "2",
        lastCalvingDate: "06-09-2024",
        heatType: "Natural",
        heatDate: "11-10-2024",
        status: "Inseminated"
    },
    {
        SlNo: "2",
        cattleId: "Georgie Winters",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Artificial",  // Added a value for Heat Type
        heatDate: "11-10-2024",         // Placeholder if not available
        status: "Pregnant"
    },
    {
        SlNo: "4",
        cattleId: "Justin Maier",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Natural",     // Added a value for Heat Type
        heatDate: "06-09-2024",         // Placeholder if not available
        status: "Inseminated"
    },
    ]
    const Pregnant_Data = [{
        SlNo: "1",
        cattleId: "Cattle No.1856",
        lactationNumber: "2",
        lastCalvingDate: "06-09-2024",
        heatType: "Natural",
        heatDate: "11-10-2024",
        status: "Inseminated"
    },
    {
        SlNo: "2",
        cattleId: "Georgie Winters",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Artificial",  // Added a value for Heat Type
        heatDate: "11-10-2024",         // Placeholder if not available
        status: "Pregnant"
    },
    {
        SlNo: "4",
        cattleId: "Justin Maier",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Natural",     // Added a value for Heat Type
        heatDate: "06-09-2024",         // Placeholder if not available
        status: "Inseminated"
    },
    ]
    const Milking_Data = [{
        SlNo: "1",
        cattleId: "Cattle No.1856",
        lactationNumber: "2",
        lastCalvingDate: "06-09-2024",
        heatType: "Natural",
        heatDate: "11-10-2024",
        status: "Inseminated"
    },
    {
        SlNo: "2",
        cattleId: "Georgie Winters",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Artificial",  // Added a value for Heat Type
        heatDate: "11-10-2024",         // Placeholder if not available
        status: "Pregnant"
    },
    {
        SlNo: "4",
        cattleId: "Justin Maier",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Natural",     // Added a value for Heat Type
        heatDate: "06-09-2024",         // Placeholder if not available
        status: "Inseminated"
    },
    {
        SlNo: "2",
        cattleId: "Georgie Winters",
        lactationNumber: "1",  // Added a value for Lactation Number
        lastCalvingDate: "06-09-2024", // Placeholder if not available
        heatType: "Artificial",  // Added a value for Heat Type
        heatDate: "11-10-2024",         // Placeholder if not available
        status: "Pregnant"
    },
    ]
    
    const carddata = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Stock",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+16.24",
            counter: "1500 ",
            link: "View net earnings",
            bgcolor: "primary",
            icon: "bx bx-dollar-circle",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Monthly Need",
            badge: "ri-arrow-right-down-line",
            badgeClass: "danger",
            percentage: "-3.57",
            counter: "850 ",
            link: "View all orders",
            bgcolor: "primary",
            icon: "bx bx-shopping-bag",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Daily Need",
            badge: "ri-arrow-right-up-line",
            badgeClass: "success",
            percentage: "+29.08",
            counter: "85 ",
            link: "See details",
            bgcolor: "primary",
            icon: "bx bx-user-circle",
            decimals: 2,
            prefix: "",
            suffix: "M"
        },
        {
            id: 4,
            cardColor: "info",
            label: "Average  cost per day",
            badgeClass: "muted",
            percentage: "+0.00",
            counter: "5146",
            link: "Withdraw money",
            bgcolor: "primary",
            icon: "bx bx-wallet",
            decimals: 2,
            prefix: "$",
            suffix: "k"
        },
    ]
    const [stock,setStock]=useState(false)
    const [feeds,setFeeds]=useState(false)
    const addStock = () => setStock(!stock)
    const handleclick=()=> setFeeds(!feeds)
    return (
        <div style={{ backgroundColor: "#FDFDFD" }}>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <Heading title='Feed Inventory' />
                    <div className="d-flex space-x-5">
                        <CommonButton title="Add New Feed"  style={{ backgroundColor: "none", color: "#16A34A", border: "1px #16A34A solid" , }}  onClick={handleclick} />
                        <CommonButton title="Add Stock" onClick={addStock}  />
                    </div>
                </div>
                <Cards data={carddata} />
                <Row className="gx-3 gy-4">
                    <Col xl={8} >
                        <StockInventoryGraph title='Stock Inventory' />
                    </Col>
                    <Col xl={4}>
                        <FeedCattle />
                    </Col>
                </Row>
                <Row className="gx-3 gy-4">
                    <Col xl={6}>
                        <PieChart title="Feed Distribution Report" />
                    </Col>
                    <Col xl={6}>
                    <FieldAlertlist title="Low Feed Alerts"bgcolor="#16A34A"/>
                    </Col>
                    {/* <ReactTable headings={headings} data={data} headingcolor="#E3E3E3" /> */}
                </Row>
            </FlexCol>
            <NewStockEntry isOpen={stock} toggle={addStock}/>
            <NewFeed isOpen={feeds} toggle={handleclick}/>
        </div>
    )
}

export default FeedInventory