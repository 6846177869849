import React, { useEffect, useState } from "react";
import { FaEdit, FaKey, FaEye } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import {
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import CommonButton from "./Button";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoPencil } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { Pagination, Spin, Switch } from "antd";
import NoDataComponent from "./NoDataComponent";
import TableNodata from "./TableNodata";
// import { FaEdit, FaEye, FaLock } from 'react-icons/fa';

function ReactTable({
  headings,
  data,
  headingcolor = "#FEEAB7",
  handleAction,
  handlePassword,
  handleToggle,
  handleEDit,
  handleUseView,
  pagination,
  fetchData,
  handleUser,
  onPageChange,
  handleSecondaryAction,
  textcolor = '#0A0B0A',
  Loader
}) {
  console.log(Loader, "loader");

  const location = useLocation();
  const path = location.pathname;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Set your items per page here
  const [loading, setLoading] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    console.log(currentPage, "pagenumber");

    // Store the current page in localStorage whenever it changes
    localStorage.setItem("tablePage", currentPage);

    // Check if the page was reloaded
  }, [currentPage, path]);

  const capitalizeFirstLetter = (text) => {
    if (!text) return text; // Return the value as is if it's null or empty

    // Convert the input to a string if it's not already
    text = String(text);

    // Remove underscores and replace with spaces
    text = text.replace(/_/g, " ");

    // Capitalize the first letter of each word if it's alphabetic
    return text.replace(
      /\b[a-zA-Z]+\b/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Handle page change
  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  const handlePageChange = async (pageNumber) => {
    console.log(pageNumber, "number");

    setLoading(true); // Start loading
    setCurrentPage(pageNumber);
    sessionStorage.setItem("tablePage", pageNumber);

    if (fetchData) {
      await fetchData(pageNumber, itemsPerPage); // Fetch new data
    }
    setLoading(false); // End loading
  };

  // Get current items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data.length > 0 ? data : [];

  return (
    <div className="table-responsive table-card  min-h-64  ">
      {/* <Spin spinning={Loader} size="large"> */}
      <Table className="align-middle table-nowrap mb-0 ">
        <thead>
          <tr>
            {headings.map((heading) => (
              <th
                key={heading.id}
                scope="col"
                style={{
                  color: textcolor,
                  backgroundColor: headingcolor,
                  fontSize: "12px",
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: "700",
                  lineHeight: "19.20px",
                  wordWrap: "break-word",
                }}
              >
                <div style={{ display: "flex" }}>
                  {heading.icon && (
                    <img
                      src={heading.icon}
                      alt={heading.title || "Icon"}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "8px",
                      }} // Adjust size as needed
                    />
                  )}
                  {heading.title && (
                    <span>{capitalizeFirstLetter(heading.title)}</span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            color: "#4F4D55",
            fontSize: "12px",
            fontFamily: "Plus Jakarta Sans",
            fontWeight: "700",
            lineHeight: "19.20px",
            wordWrap: "break-word",
          }}
        >
          {currentItems.length === 0 ? (
            <tr>
              <td
                colSpan={headings.length}
                style={{
                  textAlign: "center",
                  color: "#A0A0A0",
                  fontFamily: "sans-serif",
                }}
              >
                {/* <NoDataComponent/> */}
                <TableNodata />
              </td>
            </tr>
          ) : (
            currentItems.map((item, index) => (
              <tr key={index}>
                {headings.map((heading) => {
                  console.log(item.Status, "statusss");
                  const value = item[heading.value]; // Accessing data using the correct value key

                  if (heading.title === "Action") {
                    return (
                      <td key={heading.id}>
                        {value && value.dots ? (
                          <div>
                            <ButtonGroup>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="button"
                                  className="btn  btn-sm"
                                  style={{ backgroundColor: "#0EA5E9" }}
                                >
                                  <i>
                                    {" "}
                                    <BsThreeDotsVertical
                                      style={{ color: "white" }}
                                    />
                                  </i>
                                </DropdownToggle>
                                <DropdownMenu className="text-[#0A0B0A] text-[9px] font-base break-words">
                                  <DropdownItem
                                    className=" !flex !items-center text-[#0A0B0A] text-xs font-semibold break-words font-plus-jakarta-sans  "
                                    onClick={() => {
                                      console.log(
                                        "Password change clicked for userdd:",
                                        item.userId
                                      );
                                      handleEDit([
                                        {
                                          userId: item.userId,
                                          Role: item.Role,
                                          UserID: item.UserID,
                                          email: item.email,
                                        },
                                      ]);
                                    }}
                                  >
                                    <div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans">
                                      <GoPencil className="text-[#ffff]" />
                                    </div>
                                    Edit Details
                                  </DropdownItem>

                                  <DropdownItem
                                    className="text-[#0A0B0A] text-xs font-semibold break-words !flex !items-center font-plus-jakarta-sans"
                                    onClick={() => {
                                      console.log(
                                        "Password change clicked for user:",
                                        item.userId
                                      );
                                      handlePassword(item.userId);
                                    }}
                                  >
                                    <div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans">
                                      <MdOutlineLock className="text-[#ffff]" />{" "}
                                    </div>
                                    Change Password
                                  </DropdownItem>
                                  <DropdownItem
                                    className="text-[#0A0B0A] text-xs font-semibold break-words !flex !items-center"
                                    onClick={() => {
                                      handleUseView([
                                        {
                                          userId: item.userId,
                                          Role: item.Role,
                                          UserID: item.UserID,
                                          email: item.email,
                                          PhoneNumber: item.PhoneNumber,
                                          status: item.status,
                                        },
                                      ]);
                                    }}
                                  >
                                    <div className="p-1.5 bg-green-600 rounded flex justify-center items-center inline-flex mr-2 font-plus-jakarta-sans">
                                      <FaEye className="text-[#ffff]" />
                                    </div>{" "}
                                    View User Details
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </ButtonGroup>
                          </div>
                        ) : (
                          <CommonButton
                            // style={{ backgroundColor: 'rgba(254, 234, 183, 0.50)', color: "#2B2928", fontSize: "12px" }}
                            // title={item.action}
                            // onClick={() => handleAction(item)}
                            style={{
                              backgroundColor:
                                value === "Diagnose" &&
                                  path.includes("/breeding/inseminationReport")
                                  ? "#16A34A"
                                  : value === "Diagnose"
                                    ? "#E4626F"
                                    : value === "View Report" &&
                                      path.includes("/healthMonitoring")
                                      ? "#fff"
                                      : value === "View Report" &&
                                        path.includes("/reportsMonitoring/Healthreport")
                                        ? "#fff"
                                        : value === "View Report" &&
                                          path.includes("/breeding/deliveredReport")
                                          ? "#E4626F"
                                          : value === "View Report" &&
                                            path.includes("reportsMonitoring/Deleveryreport")
                                            ? "#E4626F"
                                            : value === "View Report" &&
                                              path.includes("/breeding/inseminationReport")
                                              ? "#DBFAE7"
                                              : value === "View Report" &&
                                                path.includes("/milkReport/milkingStatistics")
                                                ? "none"
                                                : value === "View Report" &&
                                                  path.includes("/reportsMonitoring/Milkreport")
                                                  ? "none"
                                                  : value === "View Report" &&
                                                    path.includes("/vaccination/vaccination-report")
                                                    ? "#fff"
                                                    : value === "View Report" &&
                                                      path.includes("reportsMonitoring/Vaccinationreport")
                                                      ? "#fff"
                                                      : value === "View Report" &&
                                                        path.includes("/vaccination/deworming-report")
                                                        ? "#fff"
                                                        : value === "View Report" &&
                                                          path.includes("/reportsMonitoring/Dewormingreport")
                                                          ? "#fff"
                                                          : value === "View Report"
                                                            ? "#804BF5"
                                                            : value === "View Reports"
                                                              ? "#DBFAE7"
                                                              : value === "Deworm"
                                                                ? "#804BF5"
                                                                : value === "Vaccinate"
                                                                  ? "#804BF5"
                                                                  : value === "Give Medicine"
                                                                    ? "#E4626F"
                                                                    : value === "Add Milking"
                                                                      ? "#0EA5E9"
                                                                      : value === "View Milk report"
                                                                        ? "none"
                                                                        : value === "Take Delivery"
                                                                          ? "#E4626F"
                                                                          : value === "Diagnose"
                                                                            ? "#16A34A"
                                                                            : value === "View"
                                                                              ? "#E4626F"
                                                                              : value === "View Cattle"
                                                                                ? "#E6DBFD"
                                                                                : value === "Culling"
                                                                                  ? "#6728F3"
                                                                                  : value === "Edit"
                                                                                    ? "#ffff"
                                                                                    : // value === 'View Report' ? '#DBFAE7' :

                                                                                    "none", // default color
                              color:
                                value === "Diagnose" &&
                                  path.includes("/breeding/inseminationReport")
                                  ? "#fff"
                                  : value === "Diagnose"
                                    ? "#ffff"
                                    : value === "View Cattle"
                                      ? "#2D0781"
                                      : value === "View Reports"
                                        ? "#16A34A"
                                        : value === "View Milk report"
                                          ? "#0EA5E9"
                                          : value === "Deworm"
                                            ? "#FAFAFA"
                                            : value === "Edit"
                                              ? "#16A34A"
                                              : value === "Take Delivery"
                                                ? "#fff"
                                                : value === "Add Milking"
                                                  ? "#ffff"
                                                  : value === "Give Medicine"
                                                    ? "#ffff"
                                                    : // value === 'View Report' ? "#ffff" :
                                                    // value === 'View Report' ? "#E4626F" :
                                                    value === "Vaccinate"
                                                      ? "#FAFAFA"
                                                      : value === "View Report" &&
                                                        path.includes(
                                                          "/vaccination/vaccination-report"
                                                        )
                                                        ? "#804BF5"
                                                        : value === "View Report" &&
                                                          path.includes(
                                                            "reportsMonitoring/Vaccinationreport"
                                                          )
                                                          ? "#804BF5"
                                                          : value === "View Report" &&
                                                            path.includes(
                                                              "/vaccination/deworming-report"
                                                            )

                                                            ? "#804BF5"
                                                            : value === "View Report" &&
                                                              path.includes(
                                                                "/reportsMonitoring/Dewormingreport"
                                                              )

                                                              ? "#804BF5"
                                                              : value === "View Report" &&
                                                                path.includes("/milkReport/milkingStatistics")
                                                                ? "#0EA5E9"
                                                                : value === "View Report" &&
                                                                  path.includes("/reportsMonitoring/Milkreport")
                                                                  ? "#0EA5E9"
                                                                  : value === "View Report" &&
                                                                    path.includes("/breeding/inseminationReport")
                                                                    ? "#16A34A"
                                                                    : value === "View Report" &&
                                                                      path.includes("/healthMonitoring")
                                                                      ? "#E4626F"
                                                                      : value === "View Report" &&
                                                                        path.includes("/reportsMonitoring/Healthreport")
                                                                        ? "#E4626F"
                                                                        : "#fff", // default text color
                              fontSize: "10px",
                              minWidth: "90px",
                              border:
                                value === "View Report" &&
                                  path.includes("/healthMonitoring")
                                  ? "1px #E4626F solid"
                                  : value === "View Report" &&
                                    path.includes("/reportsMonitoring/Healthreport")
                                    ? "1px #E4626F solid"

                                    : value === "View Report" &&
                                      path.includes("vaccination")
                                      ? "1px #804BF5 solid"
                                      : value === "View Report" &&
                                        path.includes("medicationReport")
                                        ? "1px #E4626F solid"
                                        : value === "View Report" &&
                                          path.includes("/breeding/inseminationReport")
                                          ? "none"
                                          : value === "View Report" &&
                                            path.includes("/vaccination/vaccination-report")
                                            ? "1px #804BF5 solid"
                                            : value === "View Report" &&
                                              path.includes("reportsMonitoring/Vaccinationreport")
                                              ? "1px #804BF5 solid"
                                              : value === "View Report" &&
                                                path.includes("/vaccination/deworming-report")
                                                ? "1px #804BF5 solid"
                                                : value === "View Report" &&
                                                  path.includes("/reportsMonitoring/Dewormingreport")
                                                  ? "1px #804BF5 solid"
                                                  : value === "View Report" &&
                                                    path.includes("/milkReport/milkingStatistics")
                                                    ? "1px #0EA5E9 solid"
                                                    : value === "View Report" &&
                                                      path.includes("/reportsMonitoring/Milkreport")
                                                      ? "1px #0EA5E9 solid"
                                                      : value === "View Milk report"
                                                        ? "1px #0EA5E9 solid"
                                                        : value === "Edit"
                                                          ? "1px #16A34A solid"
                                                          : value === "Take Delivery"
                                                            ? "1px #E4626F solid"
                                                            : value === "View Report"
                                                              ? "1px #E4626F solid"
                                                              : "none",
                            }}
                            title={item.action}
                            onClick={() =>
                              handleAction([
                                {
                                  id: item.id || "",
                                  role: item.UserRole || "",
                                  permission: item.privilege || "",
                                  InseminationType: item.InseminationType || "",
                                  InseminationDate: item.InseminationDate || "",
                                  ExpectedPregnancy: item.ExpectedPregnancy || "",
                                  CattleID: item.CattleID || "",
                                  ID: item.ID || "",
                                  quantity: item.quantity || "",
                                  slot: item.slot || "",
                                  milking_date: item.milking_date || "",
                                  issue_type: item.issuetype || "",
                                  issue_date: item.issuedate || "",
                                  severity: item.Severity || "",
                                  need_medicine: item.need_medicine || "",
                                  medication_date: item.medication_date || "",
                                  mode_of_administration:
                                    item.mode_of_administration || "",
                                  medicine_name: item.medicine_name || "",
                                  dosage: item.dosage || "",
                                  inseminationId: item.inseminationId || "",
                                  description: item.description || "",
                                  cured_notes: item.cured_notes || "",
                                  cured_date: item.cured_date || "",
                                  recorded_notes: item.recorded_notes || "",
                                  status: item.status || "",
                                  VaccinationName: item.VaccinationName || "",
                                  LastVaccinated: item.LastVaccinated || "",
                                  HealthStatus: item.HealthStatus || "",
                                  NextVaccination: item.NextVaccination || "",
                                  vaccination_type: item.vaccination_type || "",
                                  end_date: item.end_date || "",
                                  medicinename: item.MedicineName || "",
                                  deworm_date: item.deworm_date || "",
                                  nextdewormingdate: item.nextdewormingdate || "",
                                  Serverity: item.serverity || "",
                                  Description: item.Description || "",
                                  deleveryDate: item.deleveryDate || "",
                                  // newCalf: [item.newCalf],
                                  // weight: [item.weight],
                                  // gender: [item.gender],
                                  calf: item.calf || "",
                                  medicines: item.medicines || "",
                                  type: item.type || "",
                                  remarks: item.remarks || "",
                                  remark:item.remark || ""
                                },
                              ])
                            }
                          />
                        )}
                      </td>
                    );
                  } else if (heading.title === "Actions") {
                    // New implementation for "Actions" column with two buttons
                    return (
                      <td key={heading.id} className="d-flex gap-2">
                        <CommonButton
                          style={{
                            backgroundColor:  value === "Diagnose"?"#E4626F":"#E4626F",
                            color: value ==="Diagnose"?"#fff":"#fff",
                            fontSize: "10px",
                            minWidth: "90px",
                            border:value==="View Report"?"#E4626F":null
                          }}
                          title={item.primaryAction}
                          onClick={() =>
                            handleAction([
                              {
                                id: item.id || "",
                                role: item.UserRole || "",
                                permission: item.privilege || "",
                                InseminationType: item.InseminationType || "",
                                InseminationDate: item.InseminationDate || "",
                                ExpectedPregnancy: item.ExpectedPregnancy || "",
                                CattleID: item.CattleID || "",
                                ID: item.ID || "",
                                quantity: item.quantity || "",
                                slot: item.slot || "",
                                milking_date: item.milking_date || "",
                                issue_type: item.issuetype || "",
                                issue_date: item.issuedate || "",
                                severity: item.Severity || "",
                                need_medicine: item.need_medicine || "",
                                medication_date: item.medication_date || "",
                                mode_of_administration:
                                  item.mode_of_administration || "",
                                medicine_name: item.medicine_name || "",
                                dosage: item.dosage || "",
                                inseminationId: item.inseminationId || "",
                                description: item.description || "",
                                cured_notes: item.cured_notes || "",
                                cured_date: item.cured_date || "",
                                recorded_notes: item.recorded_notes || "",
                                status: item.status || "",
                                VaccinationName: item.VaccinationName || "",
                                LastVaccinated: item.LastVaccinated || "",
                                HealthStatus: item.HealthStatus || "",
                                NextVaccination: item.NextVaccination || "",
                                vaccination_type: item.vaccination_type || "",
                                end_date: item.end_date || "",
                                medicinename: item.MedicineName || "",
                                deworm_date: item.deworm_date || "",
                                nextdewormingdate: item.nextdewormingdate || "",
                                Serverity: item.serverity || "",
                                Description: item.Description || "",
                                deleveryDate: item.deleveryDate || "",
                                // newCalf: [item.newCalf],
                                // weight: [item.weight],
                                // gender: [item.gender],
                                calf: item.calf || "",
                                medicines: item.medicines || "",
                                type: item.type || "",
                                remarks: item.remarks || "",
                                remark:item.remark || ""
                              },
                            ])
                          }
                        />
                        <CommonButton
                          style={{
                            backgroundColor: "#16A34A",
                            color: "#fff",
                            fontSize: "10px",
                            minWidth: "90px",
                          }}
                          title={item.secondaryAction || "Review"}
                          onClick={() =>
                            handleSecondaryAction([
                              {
                                id: item.id ,
                                review:item.review,
                                status:item.Status,
                                // Add other properties as needed
                              },
                            ])
                          }
                        />
                      </td>
                    );
                  
                  } else if (
                    heading.title === "Severity" ||
                    heading.title === "Health Status"
                  ) {
                    const style1 = {
                      fontSize: "12px",

                      fontFamily: "Plus Jakarta Sans",
                      fontWeight: "700",
                      // lineHeight: '19.20px',
                      wordWrap: "break-word",
                      border:
                        value === "normal"
                          ? "1px #ACE2FA solid"
                          : value === "Need Care"
                            ? "1px #FEEAB7  solid"
                            : value === "major"
                              ? "1px #FEEAB7  solid"
                              : value === "critical"
                                ? "1px #FFA7B0 solid"
                                : "gray",
                      color:
                        value === "normal"
                          ? "#0A74A3"
                          : value === "major"
                            ? "#C98F02"
                            : value === "Need Care"
                              ? "#C98F02 "
                              : value === "critical"
                                ? "#E4626F"
                                : "#4F4D55",
                    };
                    return (
                      <td key={heading.id}>
                        <span className="badge" style={style1}>
                          {capitalizeFirstLetter(value) || "--"}
                        </span>
                      </td>
                    );

                    // Other cases remain unchanged
                  } else if (heading.title === "Heat Type") {
                    const badgeStyle = {
                      fontSize: "12px",
                      backgroundColor:
                        value === "Natural"
                          ? "#DBFAE7"
                          : value === "Artificial"
                            ? "#FEEAB780"
                            : value === "Pending"
                              ? "yellow"
                              : value === "Natural"
                                ? "yellow"
                                : "gray",
                      color:
                        value === "Natural"
                          ? "#16A34A"
                          : value === "Artificial"
                            ? "#2B2928"
                            : "white",
                    };

                    return (
                      <td key={heading.id}>
                        <span className="badge" style={badgeStyle}>
                          {value}
                        </span>
                      </td>
                    );
                  } else if (heading.title === "Status")
                    if (value.toggle) {
                      console.log(
                        `Rendering checkbox for userId: ${item.userId}, Status: ${item.Status.value}`
                      );
                      return (
                        <td key={heading.id}>
                          <div className="">
                            <Switch
                              size="small"
                              checked={
                                item.Status.value === true ? "active" : ""
                              }
                              type="checkbox"
                              role="switch"
                              id="SwitchCheck4"
                              onChange={() =>
                                handleToggle(item.userId, item.Status.value)
                              }
                              s
                              style={{}}
                            />
                            <span className="slider" />
                          </div>
                        </td>
                      );
                    } 
                  
                    else {
                      const badgeStyle = {
                        fontSize: "12px",
                        backgroundColor:
                          value === "Milking"
                            ? "#DBFAE7"
                            : value === "pregnant"
                              ? "#DBFAE7"
                              : value === "Dry Cattle"
                                ? "yellow"
                                : // value === 'Inseminated' ? '#FEEAB7' :
                                value === "inseminated" && path.includes("breeding")
                                  ? "#fff"
                                  : value === "Heated"
                                    ? "none"
                                    : value === "re_inseminated"
                                      ? "none"
                                      : "none",

                        color:
                          value === "Milking"
                            ? "#0A74A3"
                            : value === "pregnant"
                              ? "#16A34A"
                              : value === "Dry Cattle"
                                ? "#8C1823"
                                : // value === 'Inseminated' ? '#C98F02' :
                                value === "Heated"
                                  ? "#C98F02"
                                  : value === "inseminated" &&
                                    path.includes("breeding")
                                    ? "#0A74A3"
                                    : value === "re_inseminated"
                                      ? "#E4626F"
                                      : value === "failed"
                                        ? "#E4626F"
                                        : value === "pregnant"
                                          ? "#16A34A"
                                          : value === "diagnosed" || "Cured"
                                            ? "#0A74A3"
                                            : "none",
                        border:
                          value === "inseminated" && path.includes("breeding")
                            ? "1px #ACE2FA solid "
                            // : value === "Diagnosed" || "Cured"
                            //   ? "1px #ACE2FA solid"
                            : value === "reinseminated"
                              ? "1px #FFA7B0 solid"
                              : value === "re_inseminated"
                                ? "1px #FFA7B0 solid"
                                : value === "failed"
                                  ? "1px #FFA7B0 solid"
                                  : value === "Heated"
                                    ? "1px #FEEAB7 solid "
                                    : value === "pregnant"
                                      ? "1px #70EC9E solid"
                                      : "none",
                      };

                      return (
                        <td key={heading.id}>
                          <span
                            className="badge"
                            style={{ ...badgeStyle, alignItems: "center" }}
                          >
                            {capitalizeFirstLetter(value) || "--"}
                          </span>
                        </td>
                      );
                    }
                    else if (heading.title === "ActionButton") {
                      return (
                        <td key={heading.id}>
                          <CommonButton style={{color:"#fff", backgroundColor:"#16A34A"}} title={value || "Action"} onClick={() => handleAction(item)} />
                        </td>
                      );
                    }
                  else {
                    return (
                      <td
                        key={heading.id}
                        onClick={handleUser}
                        style={{
                          color: "#4F4D55",
                          fontSize: "12px",
                          fontFamily: "Plus Jakarta Sans",
                          fontWeight: "700",
                          lineHeight: "19.20px",
                          wordWrap: "break-word",
                        }}
                      >
                        {heading.title === "Email"
                          ? value || "--"
                          : capitalizeFirstLetter(value) || "--"}{" "}
                      </td>
                    ); // Default case
                  }
                })}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {(data.length > itemsPerPage || pagination > 10) && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          {/* <Pagination
            current={currentPage}
            total={pagination}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            showSizeChanger={false}
            style={{ padding: '0.5rem', fontFamily: "sans-serif", color: "#A0A0A0" }}
          /> */}
        </div>
      )}
      {/* </Spin> */}
    </div>
  );
}

export default ReactTable;
