import React, { useEffect, useState } from 'react'
import ReactTable from '../Common/Table';
import { Nav, NavItem, NavLink } from 'reactstrap';
import CommonButton from '../Common/Button';
import Filterbtn from '../Common/Filterbtn';
import SearchBox from '../Common/SearchBox';
import Dropdownbtn from '../Common/Dropdownbtn';
import Heading from '../Common/Heading';
import FlexCol from '../Common/FlexCol';
import cowicon from '../../assets/Images/Cow icon.png'
import RecordMedication from '../Drawer/RecordMedication';
import API, { action } from '../../Api';
import DateFilter from '../Common/DateFilter';
import TableAccordion from '../Common/TableAccordion';
import { Pagination } from 'antd';
import { useLoader } from '../../LoaderContext';


function MedicationReport() {
    const [report, setReport] = useState(false)
    const [medicine, setMedicine] = useState(false)
    const [medreport, setMedreport] = useState([])
    const DiagnoseReport = () => setReport(!report)
    const [tab, setTab] = useState("1");
    const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedData, setSelectedData] = useState([])
    const [datefilter, setDatefilter] = useState()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const { setLoading } = useLoader();
    const pillsToggle = (tabs) => {
        if (tab !== tabs) {
            setTab(tabs);
        }
    };
    console.log(datefilter, "date");
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getMedicationReport = async (page = currentPage, pageSize = 10) => {
        setLoading(true);
        const result = await action(API.GET_MEDICATION_LIST, {
            page: page,
            pageCount: pageSize,
            date: datefilter,
            searchKey: searchTerm

        });
        setLoading(false);
        setPaginationcount(result.response.count);
        if (result && result.response && result.response.data) {
            const formattedData = result.response.data.map((item, index) => ({
                SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), // Format SlNo
                CattleID: item.Cattle.cattle_id,
                medication_date: item.medication_date,
                medicine_name: item.medicine_name,
                dosage: item.dosage,
                slot: item.slot,
                Status: item.status,
                id: item.id,
                recorded_notes: item.recorded_notes,
                action: item.status === "given" ? "View Report" : "Give Medicine",
            }));

            setMedreport(formattedData); // Set formatted data to the state
        }
    }


    useEffect(() => {
        getMedicationReport()
        console.log(medreport, 'medicationlist');

    }, [pageSize, currentPage, searchTerm, datefilter])





    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Medicine Name",
            value: "medicine_name",

        },


        {
            id: 4,
            title: "Slot",
            value: "slot",


        },
        {
            id: 5,
            title: "Status",
            value: "Status",


        },
        {
            id: 6,
            title: "Medication Date",
            value: "medication_date",


        },
        {
            id: 7,
            title: "Action",
            value: "action",


        },



    ]
    const headingsformobile = [


        {
            id: 1,
            title: "Medicine Name",
            value: "medicine_name",

        },


        {
            id: 2,
            title: "Slot",
            value: "slot",


        },
        {
            id: 3,
            title: "Status",
            value: "Status",


        },
        {
            id: 4,
            title: "Medication Date",
            value: "medication_date",


        },

    ]

    const data = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "18-08-2024",
            Severity: "Normal",
            HeatDate: "20-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "07",
            CattleID: "Cattle No.1678",
            LactationNumber: "1",
            LastCalvingdate: "01-09-2024",
            Severity: "Critical",
            HeatDate: "15-11-2024",
            action: "View Report",
        },
        {
            SlNo: "08",
            CattleID: "Cattle No.1234",
            LactationNumber: "4",
            LastCalvingdate: "12-06-2024",
            Severity: "Normal",
            HeatDate: "25-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "09",
            CattleID: "Cattle No.1098",
            LactationNumber: "3",
            LastCalvingdate: "14-08-2024",
            Severity: "Normal",
            HeatDate: "02-11-2024",
            action: "View Report",
        },
        {
            SlNo: "10",
            CattleID: "Cattle No.1999",
            LactationNumber: "2",
            LastCalvingdate: "27-07-2024",
            Severity: "Normal",
            HeatDate: "16-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "11",
            CattleID: "Cattle No.1321",
            LactationNumber: "1",
            LastCalvingdate: "03-09-2024",
            Severity: "Normal",
            HeatDate: "28-11-2024",
            action: "View Report",
        },
        {
            SlNo: "12",
            CattleID: "Cattle No.1156",
            LactationNumber: "4",
            LastCalvingdate: "30-05-2024",
            Severity: "Critical",
            HeatDate: "14-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "13",
            CattleID: "Cattle No.1425",
            LactationNumber: "3",
            LastCalvingdate: "19-08-2024",
            Severity: "Major",
            HeatDate: "01-11-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "14",
            CattleID: "Cattle No.1867",
            LactationNumber: "2",
            LastCalvingdate: "10-07-2024",
            Severity: "Major",
            HeatDate: "15-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "15",
            CattleID: "Cattle No.1789",
            LactationNumber: "1",
            LastCalvingdate: "22-09-2024",
            Severity: "Critical",
            HeatDate: "29-11-2024",
            action: "Give Medicine",
        },
    ];
    const data2 = [
        // {
        //     SlNo: "01",
        //     CattleID: "Cattle No.1856",
        //     LactationNumber: "2",
        //     LastCalvingdate: "06-09-2024",
        //     Severity: "Normal",
        //     HeatDate: "11-10-2024",
        //     action: "Give Medicine",
        // },
        // {
        //     SlNo: "02",
        //     CattleID: "Cattle No.1987",
        //     LactationNumber: "3",
        //     LastCalvingdate: "02-08-2024",
        //     Severity: "Normal",
        //     HeatDate: "12-10-2024",
        //     action: "View Report",
        // },

        // {
        //     SlNo: "07",
        //     CattleID: "Cattle No.1678",
        //     LactationNumber: "1",
        //     LastCalvingdate: "01-09-2024",
        //     Severity: "Critical",
        //     HeatDate: "15-11-2024",
        //     action: "View Report",
        // },
        // {
        //     SlNo: "08",
        //     CattleID: "Cattle No.1234",
        //     LactationNumber: "4",
        //     LastCalvingdate: "12-06-2024",
        //     Severity: "Normal",
        //     HeatDate: "25-10-2024",
        //     action: "Give Medicine",
        // },

        // {
        //     SlNo: "14",
        //     CattleID: "Cattle No.1867",
        //     LactationNumber: "2",
        //     LastCalvingdate: "10-07-2024",
        //     Severity: "Major",
        //     HeatDate: "15-10-2024",
        //     action: "Give Medicine",
        // },
        // {
        //     SlNo: "15",
        //     CattleID: "Cattle No.1789",
        //     LactationNumber: "1",
        //     LastCalvingdate: "22-09-2024",
        //     Severity: "Critical",
        //     HeatDate: "29-11-2024",
        //     action: "Give Medicine",
        // },
    ];
    const data3 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },

    ];
    const data4 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },

    ];
    const data5 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "05",
            CattleID: "Cattle No.1456",
            LactationNumber: "2",
            LastCalvingdate: "25-05-2024",
            Severity: "Critical",
            HeatDate: "30-09-2024",
            action: "View Report",
        },
        {
            SlNo: "06",
            CattleID: "Cattle No.1543",
            LactationNumber: "3",
            LastCalvingdate: "18-08-2024",
            Severity: "Normal",
            HeatDate: "20-10-2024",
            action: "Give Medicine",
        },

    ];
    const data6 = [
        {
            SlNo: "01",
            CattleID: "Cattle No.1856",
            LactationNumber: "2",
            LastCalvingdate: "06-09-2024",
            Severity: "Normal",
            HeatDate: "11-10-2024",
            action: "Give Medicine",
        },
        {
            SlNo: "02",
            CattleID: "Cattle No.1987",
            LactationNumber: "3",
            LastCalvingdate: "02-08-2024",
            Severity: "Normal",
            HeatDate: "12-10-2024",
            action: "View Report",
        },
        {
            SlNo: "03",
            CattleID: "Cattle No.1745",
            LactationNumber: "1",
            LastCalvingdate: "15-06-2024",
            Severity: "Major",
            HeatDate: "10-11-2024",
            action: "View Report",
        },
        {
            SlNo: "04",
            CattleID: "Cattle No.1623",
            LactationNumber: "4",
            LastCalvingdate: "20-07-2024",
            Severity: "Normal",
            HeatDate: "05-10-2024",
            action: "Give Medicine",
        },


    ];
    // const handleActionClick = (item) => {
    //     console.log('Action performed on item:', item);
    //     setReport(!report);


    //   };
    useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getMedicationReport(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getMedicationReport(currentPage, pageSize)
        }
    }, [searchTerm, datefilter, tab, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getMedicationReport(currentPage, pageSize)
        }
    }, [currentPage])



    const handleActionClick = (CattleID) => {
      
        setMedicine(prevState => {

            if (prevState) {
                
                getMedicationReport()
            }
            return !prevState;
        });
        setSelectedData(CattleID)

    }
    const handlePageChange = (page) => {
        setCurrentPage(page)
        getMedicationReport(page, pageSize)
    }
    console.log(report, "report");
    return (
        <div style={{ backgroundColor: "#FDFDFD", minHeight: "calc(100vh - 135px)" }}>
            <FlexCol>
                <div className="d-flex justify-content-between ">
                    <div className='d-flex space-x-2'>
                        <Heading title='Medication Record' />
                        {/* <Dropdownbtn title='Today' style={{backgroundColor:"#FAFAFA",color:"#5A5555",border: '1px #CCCBCB solid'}} /> */}
                    </div>
                    <div className="d-flex space-x-5">
                        <DateFilter selectedDate={datefilter} change={(e) => setDatefilter(e)} placeholder="Choose Date Filter" value={datefilter} />
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm} onSearch={setSearchTerm} />
                        {/* <Filterbtn /> */}
                        {/* <CommonButton title=" Record For All" onClick={DiagnoseReport}/> */}
                    </div>
                </div>
                {/* <Cards />
                <Row className="g-3">
                    <Col md={7} >
                        <Graph title='Sick Cattle Statistics' />

                    </Col>
                    <Col md={5} >
                        <PieChart title="Sick Cattle Distribution" />

                    </Col>

                </Row> */}
                {/* <Nav pills className="nav-success ">
          {["All", "Recordedd", "Pending", "Missed"].map((tabs, index) => (
            <NavItem key={index}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: "#0A0B0A",
                  backgroundColor: tab === String(index + 1) ? '#E3E3E3' : 'transparent',
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "120%",
                  textDecoration: 'none' // Optional underline for active tab
                }}
                className={tab === String(index + 1) ? "active" : ""}
                onClick={() => pillsToggle(String(index + 1))}
              >
                {tabs}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}
                {/* {tab === "1" && <ReactTable headings={headings} data={medreport} headingcolor="#D9D9D9" handleAction={handleActionClick}  pagination={paginationcount} 
            fetchData={getMedicationReport} />}
        {tab === "2" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "3" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "4" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "5" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />}
        {tab === "6" && <ReactTable headings={headings} data={data2} headingcolor="#D9D9D9" handleAction={handleActionClick} />} */}
                {isMobile ? (
                    <TableAccordion headings={headingsformobile} cattleData={medreport} handleAction={handleActionClick} />
                ) : (
                    <ReactTable headings={headings} data={medreport} headingcolor="#FCEFF1" textcolor='#A0454E' handleAction={handleActionClick} pagination={paginationcount}
                        fetchData={getMedicationReport} />
                )}
                {(medreport.length > pageSize || paginationcount > 10) && (
                    <div className="flex justify-end mt-4">
                        <Pagination
                            current={currentPage}
                            total={paginationcount}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            className="p-2 font-sans text-[#A0A0A0]"
                            itemRender={(page, type, originalElement) => {
                                if (type === "page") {
                                    return (
                                        <span
                                            className={`px-2 ${page === currentPage ? "font-bold text-black" : "font-normal"
                                                }`}
                                        >
                                            {page}
                                        </span>
                                    );
                                }
                                return originalElement;
                            }}
                        />
                    </div>
                )}

            </FlexCol>
            <RecordMedication isOpen={report} toggle={DiagnoseReport} />
            <RecordMedication isOpen={medicine} toggle={handleActionClick} selectedData={selectedData} />

        </div>
    )
}

export default MedicationReport