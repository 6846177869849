import React, { useEffect, useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import DropdownButton from '../Common/DropdownButton'
import CommonButton from '../Common/Button'
import TextArea from '../Common/TextArea'
import DateSelect from '../Common/DateSelector'
import { Flex, notification } from 'antd'
import Label from '../Common/Label'
import API, { action } from '../../Api'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { FaTrash } from 'react-icons/fa'

function Review({ isOpen, toggle, selectedData, Api }) {
    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState(selectedData?.[0]?.review || []);
    const [checking,setChecking] = useState(false )
    console.log(checking, "datttata");

    useEffect(() => {
        setChecking(selectedData?.[0]?.status==="diagnosed")
        setReviews(selectedData?.[0]?.review || []);
    }, [selectedData]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: "",
            remark: "",
        },
        validationSchema: Yup.object({
            remark: Yup.string()
                .required('Remarks is required'),
            date: Yup.string().required('Date is required'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await action(API.ADD_REVIEW, {
                    diagnosis_id: selectedData?.[0]?.id,
                    remarks: values.remark,
                    created_date: values.date
                });

                notification.success({ message: "Review Added Successfully", placement: "top" });
                formik.resetForm();
                toggle();
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top" });
            } finally {
                setLoading(false);
            }
        },
    });

    const formatDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB'); // Returns in DD-MM-YYYY format
        } catch (error) {
            return dateString;
        }
    };

    const toggle1 = () => {
        formik.resetForm();
        toggle()
    }

    const handledelete = async (id) => {
        try {
            const response = await action(API.DELETE_REMARKS, {
                id: id
            })
            if (response.status.code === 200) {
                setReviews(prevReviews => prevReviews.filter(review => review.id !== id));
                notification.success({ message: response.response.message, placement: "top" });
            } else {
                notification.success({ message: response.response.message, placement: "top" });
            }
        } catch (error) {
            console.error(error);
            notification.error({ message: error.response?.data?.response?.message, placement: "top" });
        }
    }

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className="w-full md:!w-[320px] 2xl:!w-[480px] flex flex-col">
            <div className="flex flex-col h-full">
                <OffcanvasHeader className="p-3">
                    <div>
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
                            {!checking ? "Remarks Report" : "Add Remarks"}
                        </div>
                        <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
                            Additional Comments & Feedback
                        </div>
                    </div>
                </OffcanvasHeader>

                <OffcanvasBody className="flex-grow overflow-auto p-3">
                    {checking && (
                        <form onSubmit={formik.handleSubmit}>
                            <FormGroup>
                                <FlexCol gap={20}>
                                    <Flex className="grid grid-cols-1 gap-[10px] items-center">
                                        <DateSelect
                                            title="Date"
                                            placeholder="Choose Date"
                                            value={formik.values.date}
                                            change={(e) => formik.setFieldValue("date", e)}
                                            error={formik.touched.date && formik.errors.date}
                                            required={true}
                                        />
                                        <TextArea
                                            title="Remarks"
                                            placeholder="Enter Remarks"
                                            value={formik.values.remark}
                                            change={(e) => formik.setFieldValue("remark", e)}
                                            error={formik.touched.remark && formik.errors.remark}
                                            required={true}
                                        />
                                    </Flex>
                                </FlexCol>
                            </FormGroup>

                            <div className="flex justify-end mb-4">
                                <CommonButton title="Add" style={{ fontSize: "12px" }} type="submit" disabled={loading} />
                            </div>
                        </form>
                    )}

                    {reviews.length > 0 ? (
                        <div className="mt-4">
                            <div className="text-[14px] font-semibold mb-2">Review History</div>
                            {reviews.map((review, index) => (
                                <div key={review.id} className="mb-3">
                                    <hr className="my-2" />
                                    <div className="flex justify-between items-center">
                                        <Label title="Date" value={formatDate(review.created_date)} />
                                        {checking && (
                                            <button className="text-red-500 hover:text-red-700" onClick={() => handledelete(review.id)}>
                                                <FaTrash size={16} />
                                            </button>
                                        )}
                                    </div>
                                    <Label title="Remarks" value={review.remarks} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 my-4 text-sm">No review history available</div>
                    )}
                </OffcanvasBody>

                {/* Fixed Cancel Button at the bottom */}
                <div className="p-3 border-t mt-auto">
                    <CommonButton
                        style={{ backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid", fontSize: "12px", width: "100%" }}
                        onClick={toggle}
                        outline
                        title='Cancel'
                        disabled={loading}
                    />
                </div>
            </div>
        </Offcanvas>
    )
}

export default Review