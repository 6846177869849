import { Color } from 'antd/es/color-picker';
import React from 'react'
import ReactApexChart from 'react-apexcharts';

function ReportChart({ dataColors, series ,barColors = ['#16A34A', '#B7F5CE'] }) {
    const options = {
        chart: {
            height: 370,
            type: "bar",
            stacked: true, // Enable stacking
            toolbar: {
                show: false,
            },
            
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                endingShape: 'rounded',
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // Apply radius to each bar individually
                borderRadiusWhenStacked: 'last',
            },
        },
        colors: barColors,
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul" ,"Aug","Sep","Oct","Nov","Dec"],
            labels: {
                style: {
                    fontFamily:'Plus Jakarta Sans',
                    

                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: false, // Hide y-axis values
            },
        },
        grid: {
            show: false,
            padding: {
                top: 0,
                right: 0,
                bottom: 15,
                left: 10,
            },
            row: {
                colors: undefined, // Remove row colors
                opacity: 0, // Set opacity to 0 to hide horizontal lines
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: 'Plus Jakarta Sans',
                colors: ['#000000']
            },
            shared: true,
            intersect: false,
            yaxis: {
                formatter: (value) => value.toFixed(2),
            },
        },
        dataLabels: {
            enabled: false,
            formatter: (val) => val.toFixed(2), // Format data labels
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series} // Ensure series contains both datasets for stacking
                type="bar"
                height="270"
                width="100%"
                className="apex-charts font-plus-jakarta-sans"
            />
        </React.Fragment>
    );
}

export default ReportChart