import React, { useEffect, useState } from 'react';
import CommonButton from '../Common/Button';
import FormInput from '../Common/FormInput';
import TextArea from '../Common/TextArea';
import DateSelect from '../Common/DateSelector';
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import FlexCol from '../Common/FlexCol';
import { Flex, notification } from 'antd';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import API, { action } from '../../Api';
import DropdownButton from '../Common/DropdownButton';
import MultiSelector from '../Common/MultiSelector';
import Label from '../Common/Label';


function RecordVaccine({ isOpen, toggle, selectedData }) {
  console.log(selectedData, "selectedData");
  const [cattleid, setCattleid] = useState([]);
  const tabs = ['Type A', 'Type B', 'Type C'];
  const [activeTab, setActiveTab] = useState('Type A');
  const [activeTab2, setActiveTab2] = useState('No'); // For "Need Medicine" tab
  const tabs2 = ['Yes', 'No'];
  const id = localStorage.getItem('userid');
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const isDisabled = !!selectedData;

  console.log(activeTab, "active tab");

  useEffect(() => {
    if (isOpen && !selectedData) {
      setActiveTab('Type A');
    }
  }, [isOpen]);


  const healthstatusOption = [
    { value: "critical", label: "Critical" },
    { value: "normal", label: "Normal" },
    { value: "major", label: "Major" },
  ];


  const getCattleId = async () => {
    try {

      const result = await action(API.GET_CATTLE_ID, {
        searchKey: searchKey,
        page: 1,
        pageCount: 60,
      });
      const cattleID = result.response.data.map(id => ({
        label: id.cattle_id, // Display name
        value: id.cattle_id // Unique identifier
      }));
      setCattleid(cattleID); // Set data with both label and value
    } catch (error) {
      console.error("Error fetching cattle data:", error);
    }
  };

  useEffect(() => {
    getCattleId();
  }, [searchKey]);




  // Validation Schema using Yup
  const validationSchema = Yup.object({
    vaccineName: Yup.string().required('Vaccine Name is required'),
    dosage: Yup.string().required('Dosage is required'),
    vaccineDate: Yup.date().required('Vaccination Date is required'),
    cattle: Yup.mixed().required('Cattle  is required'),
    healthStatus: Yup.string().required('Health Status is required'),
    // description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      vaccineName: '',
      dosage: '',
      vaccineDate: '',
      cattle: null,
      healthStatus: 'normal',
      nextvaccineDate: '',
      medicineName: '',
      medicineDosage: '',
      description: ''
    },

    validationSchema: selectedData ? null : validationSchema,

    onSubmit: async (values) => {
      // Conditionally add medicine validation if activeTab2 is "Yes"
      //   if (activeTab2 === 'Yes') {
      //     if (!values.medicineName) {
      //       formik.setErrors({ medicineName: 'Medicine Name is required' });
      //       return;
      //     }
      //     if (!values.medicineDosage) {
      //       formik.setErrors({ medicineDosage: 'Medicine Dosage is required' });
      //       return;
      //     }
      //   }
      setLoading(true);
      try {
        // Determine if it's an update or a new record
        if (selectedData) {
          // Update existing vaccination record
          const result = await action(API.UPDATE_VACCINATION, {
            id: selectedData[0]?.id, // Assuming `id` is a unique identifier for the record
            status: "recorded"
          });

          notification.success({ message: result.response.message, placement: "top" });
          formik.resetForm();
          toggle();
        } else {
          await action(API.ADD_VACCINATION, {
            cattle_id: values.cattle,
            vaccination_type: activeTab, // Using activeTab as the vaccine type
            vaccination_name: values.vaccineName,
            dosage: values.dosage,
            vaccination_date: values.vaccineDate,
            health_status: values.healthStatus,
            next_vaccination_date: values.nextvaccineDate,
            medicine_name: values.medicineName,
            medicine_dosage: values.medicineDosage,
            description: values.description,
            status: 'assigned',
            created_by: id,

          });
          notification.success({ message: "Record vaccination added successfully!", placement: "top" });
          formik.resetForm();
          toggle();
        }
      } catch (error) {
        console.error(error);
        notification.error({ message: error.response?.data?.response?.message, placement: "top" });
      } finally {
        setLoading(false); // Stop loading
      }
    },
  });


  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      // Set basic fields
      formik.setFieldValue("healthStatus", selectedData[0]?.HealthStatus);
      formik.setFieldValue("vaccineDate", selectedData[0]?.LastVaccinated);
      formik.setFieldValue("cattle", selectedData[0]?.CattleID);
      formik.setFieldValue("vaccineName", selectedData[0]?.VaccinationName);
      formik.setFieldValue("dosage", selectedData[0]?.dosage);
      formik.setFieldValue("nextvaccineDate", selectedData[0]?.NextVaccination);
      formik.setFieldValue("activeTab", selectedData[0]?.vaccination_type);
      formik.setFieldValue("slot", selectedData[0]?.slot);
      formik.setFieldValue("description", selectedData[0]?.Description);
     
    }
  }, [selectedData]);



  const toggle1 = () => {
    formik.resetForm();
    setActiveTab('Type A')
    setActiveTab2('No')
    toggle()
  }
  // useEffect(()=>{
  //   console.log(selectedData?.[0]?.vaccination_type,"type123",activeTab)
  //   setActiveTab(selectedData?.[0]?.vaccination_type)
  // },[isOpen])

  useEffect(() => {
    if (selectedData?.[0]?.vaccination_type) {
      setActiveTab(selectedData[0].vaccination_type);
    } else if (isOpen) {
      setActiveTab('Type A');
    }
  }, [isOpen, selectedData]);
  return (
    <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className=" w-full md:!w-[320px] 2xl:!w-[480px] max-h-full overflow-y-auto">
      <FlexCol className="p-4">
        <OffcanvasHeader>
          <div>
            <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B] leading-[21.6px] break-words font-plus-jakarta-sans">
              Record Vaccine
            </div>
            <div className="text-[10px] 2xl:text-sm text-[#726C6C] font-plus-jakarta-sans">
              Provide vaccination data and follow-up.
            </div>
          </div>
        </OffcanvasHeader>

        <OffcanvasBody>
          <form onSubmit={formik.handleSubmit}>
            <FormGroup>
              {!isDisabled ? (
                <FlexCol className="" gap={20}>
                  <Flex className="grid grid-cols-1 gap-[30px] items-center">
                    {/* <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                    <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                      Vaccine Type
                    </div>
                    <div className="flex gap-2">
                      {tabs.map((tab) => (
                        <div
                          key={tab}
                          onClick={() => !isDisabled && setActiveTab(tab)}
                          
                          className={`p-2 rounded-lg cursor-pointer ${activeTab === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                        >
                          <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                            {tab}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div> */}

                    <FormInput
                      title="Vaccine Name"
                      placeholder="Enter Vaccine Name"
                      value={formik.values.vaccineName}
                      change={value => formik.setFieldValue('vaccineName', value)}
                      error={formik.errors.vaccineName && formik.touched.vaccineName ? formik.errors.vaccineName : null}
                      disabled={isDisabled}
                      required={true}
                    />

                    <FormInput
                      title="Dosage (mg/bwt)"
                      type='number'
                      placeholder="Enter Dosage"
                      value={formik.values.dosage}
                      change={value => formik.setFieldValue('dosage', value)}
                      error={formik.errors.dosage && formik.touched.dosage ? formik.errors.dosage : null}
                      disabled={isDisabled}
                      required={true}
                      maxLength={4}
                    />

                    <DateSelect
                      title="Vaccination Date"
                      placeholder="Choose Vaccination Date"
                      value={formik.values.vaccineDate}
                      change={value => formik.setFieldValue('vaccineDate', value)}
                      error={formik.errors.vaccineDate && formik.touched.vaccineDate ? formik.errors.vaccineDate : null}
                      disabled={isDisabled}
                      required={true}
                      disableBeforeDate={new Date().toISOString().split('T')[0]}
                    />

                    <MultiSelector
                      title="Cattle"
                      placeholder="Select Cattle"
                      options={cattleid}
                      value={formik.values.cattle}
                      onSearch={setSearchKey}
                      onChange={value => formik.setFieldValue('cattle', value)}
                      error={formik.errors.cattle && formik.touched.cattle ? formik.errors.cattle : null}
                      disabled={isDisabled}
                      required={true}
                    />

                    <DropdownButton
                      title="Health Status"
                      placeholder="Select Health Status"
                      value={formik.values.healthStatus}
                      change={value => formik.setFieldValue('healthStatus', value)}
                      options={healthstatusOption}
                      error={formik.errors.healthStatus && formik.touched.healthStatus ? formik.errors.healthStatus : null}
                      disabled={isDisabled}
                      required={true}
                      SelectName='Select Health Status'
                    />
                    {!selectedData && (
                      <DateSelect
                        title="Next Vaccination Date"
                        placeholder="Choose Next Vaccination Date"
                        value={formik.values.nextvaccineDate}
                        change={value => formik.setFieldValue('nextvaccineDate', value)}
                        disabled={isDisabled}
                        disableBeforeDate={formik.values.vaccineDate}
                      />
                    )}
                    {/* {!selectedData && (
                    <div style={{ flexDirection: 'column', justifyContent: 'flex-start', gap: 14, display: 'flex' }}>
                      <div className="text-[#16A34A] text-[16px] md:text-[12px] 2xl:text-[16px] font-medium leading-[19.2px] break-words font-plus-jakarta-sans">
                        Need Medicine
                      </div>
                      <div className="flex gap-2">
                        {tabs2.map((tab) => (
                          <div
                            key={tab}
                            onClick={() => setActiveTab2(tab)}
                            className={`p-2 rounded-lg cursor-pointer ${activeTab2 === tab ? 'bg-[#E3E3E3]' : 'bg-transparent'} flex justify-center items-center`}
                          >
                            <div className="text-[#0A0B0A] text-[12px] md:text-[10px] font-medium leading-[16.8px] break-words">
                              {tab}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!selectedData && activeTab2 === 'Yes' && (
                    <>
                      <FormInput
                        title="Medicine Name"
                        placeholder="Enter Medicine Name"
                        value={formik.values.medicineName}
                        change={value => formik.setFieldValue('medicineName', value)}
                        error={formik.errors.medicineName && formik.touched.medicineName ? formik.errors.medicineName : null}
                        required= {true}
                      />

                      <FormInput
                        title="Dosage"
                        type='number'
                        placeholder="Enter Dosage"
                        value={formik.values.medicineDosage}
                        change={value => formik.setFieldValue('medicineDosage', value)}
                        error={formik.errors.medicineDosage && formik.touched.medicineDosage ? formik.errors.medicineDosage : null}
                        required= {true}
                      />
                    </>
                  )} */}
                    {/* {!selectedData && ( */}
                    <TextArea
                      title="Description"
                      placeholder="Enter Description"
                      value={formik.values.description}
                      change={value => formik.setFieldValue('description', value)}
                      // error={formik.errors.description && formik.touched.description ? formik.errors.description : null}
                      // required= {true}
                      disabled={isDisabled}
                    />
                    {/* )} */}
                  </Flex>
                </FlexCol>
              ) : (
                <>
                  {/* <Label title=' Vaccine Type' value={selectedData?.[0]?.vaccination_type || ''} /> */}

                  <Label title='Vaccine Name' value={formik.values.vaccineName || ''} />
                  <Label title='Dosage (mg/bwt)' value={formik.values.dosage || ''} />
                  <Label title='Vaccination Date' value={formik.values.vaccineDate
                    || ''} />
                  <Label title='Cattle' value={formik.values.cattle
                    || ''} />
                  <Label title='Health Status' value={formik.values.healthStatus || '--'} />
                  <Label title='Next Vaccination Date' value={formik.values.nextvaccineDate || '--'} />
                  <Label title='Description' value={formik.values.description || '--'} />
                </>
              )}
            </FormGroup>

            <div className="flex justify-between mt-8">
              <CommonButton
                style={{
                  backgroundColor: 'none',
                  color: '#726C6C',
                  border: '1px #CCCBCB solid',
                  marginRight: '8px',
                  fontSize: '12px'
                }}
                onClick={toggle}
                outline
                title="Discard"
                disabled={loading}
              />
              {selectedData?.[0]?.status !== "recorded" && (
                <CommonButton
                  title={selectedData ? "Record Vaccination" : "Assign Vaccination"}
                  type="submit"
                  style={{
                    fontSize: '12px'
                  }}
                  disabled={loading}
                />
              )}
            </div>
          </form>
        </OffcanvasBody>
      </FlexCol>
    </Offcanvas>
  );
}

export default RecordVaccine;
