import React from 'react'
import { GoBell, GoPencil } from 'react-icons/go';
import { PiDotsThreeOutlineVerticalFill } from 'react-icons/pi';
import feedalerts from "../../assets/Images/feed alerts.svg"
function FieldAlertlist({title,text,bgcolor}) {
    const feedAlerts = [
        {
          id: 1,
          text: "Feed Type 1",
          subtext: "34 cattle are on this feed plan.",
        },
        {
            id: 2,
            text: "Feed Type 2",
            subtext: "34 cattle are on this feed plan.",
        },
        {
            id: 3,
            text: "Sick Feed Group",
            subtext: "34 cattle are on this feed plan.",
        },
        {
            id: 4,
            text: "Feed Type 4",
            subtext: "34 cattle are on this feed plan.",
        },
        {
            id: 5,
            text: "Feed Type 5",
            subtext: "34 cattle are on this feed plan.",
        },
       
      ];
  return (
    <div className="w-full h-full p-3 bg-[#FAFAFA] rounded-xl border-1 border-[#E3E3E3] flex flex-col items-start gap-2.5">
            <div className="self-stretch h-full flex flex-col justify-start items-start gap-4">
                <div className="self-stretch flex justify-between items-center">
                    <div className="text-[#414D55] text-sm font-bold leading-[21.6px] font-plus-jakarta-sans">{title}s</div>
                    <div className="text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">See All</div>
                </div>
                <div className="self-stretch h-full flex flex-col justify-start items-start gap-3">
                    {feedAlerts.map((feeds) => (
                        <div  className="self-stretch p-2 bg-white rounded-lg border border-[#E3E3E3] flex justify-between items-center">
                            <div className="self-stretch flex justify-start items-start gap-2">
                                <div className="relative w-10 h-10">
                                    <div style={{ backgroundColor: bgcolor }} className="absolute w-10 h-10  rounded-md"/>
                                    <img
                                        className="absolute w-9 h-9 rounded-md top-0 left-0.5"
                                        src={feedalerts}
                                    //   alt={item.text}
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-start gap-1">
                                    <div className="self-stretch text-[#0A0B0A] text-sm font-bold leading-[19.2px] font-plus-jakarta-sans">{feeds.text}</div>
                                    <div className="self-stretch text-[#898483] text-xs font-normal leading-[16.8px] font-plus-jakarta-sans">{feeds.subtext}</div>
                                </div>
                            </div>
                  <div className="flex gap-2">
                      <GoBell  className="text-[#0EA5E9]" />
                      <GoPencil   className="text-[#B5B3B3]" />
                      <PiDotsThreeOutlineVerticalFill  className="text-[#B5B3B3]" />
                    </div>
                </div>
              ))}
           </div>
            </div>
        </div>
  )
}

export default FieldAlertlist