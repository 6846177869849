import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import cowicon from '../../assets/Images/Cow icon.png';

const TableAccordion = ({ cattleData, headings ,handleAction }) => {
    const [openSections, setOpenSections] = useState([0]);
console.log(cattleData,'datatat');

    // Define headings for dynamic mapping
    //   const headings = [
    //     { id: 3, title: 'Severity', value: 'severity' },
    //     { id: 4, title: 'Heat Date', value: 'heatDate' },
    //     { id: 5, title: 'Lactation Number', value: 'lactationNumber' },
    //     { id: 6, title: 'Last Calving Date', value: 'lastCalvingDate' },
    //   ];

    // Sample data
    //   const cattleData = [
    //     {
    //       id: '1856',
    //       severity: 'Critical',
    //       heatDate: '11-10-2024',
    //       lactationNumber: 2,
    //       lastCalvingDate: '04-09-2024',
    //     },
    //     {
    //       id: '1856',
    //       severity: 'Normal',
    //       heatDate: '11-10-2024',
    //       lactationNumber: 1,
    //       lastCalvingDate: '04-09-2024',
    //     },
    //     {
    //       id: '1856',
    //       severity: 'Critical',
    //       heatDate: '11-10-2024',
    //       lactationNumber: 3,
    //       lastCalvingDate: '04-09-2024',
    //     },
    //   ];

    const toggleSection = (index) => {
        if (openSections.includes(index)) {
            setOpenSections(openSections.filter((i) => i !== index));
        } else {
            setOpenSections([...openSections, index]);
        }
    };

    return (
        <div className="max-w-md w-full mx-auto bg-gray-50 rounded-lg">
            <div className="p-4 bg-[#E3E3E3] rounded-t-lg">
                <div className="flex items-center gap-2">
                    <img src={cowicon} alt="Cow Icon" className="w-5 h-5 text-gray-600" />
                    <span className="text-[#0A0B0A] text-[14px] font-[700] font-plus-jakarta-sans leading-[16.8px] break-words">
                        Cattle ID
                    </span>
                </div>
            </div>

            {cattleData.map((cattle, index) => (
                <div key={index} className="border-t border-gray-200">
                    <button
                        onClick={() => toggleSection(index)}
                        className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 transition-colors"
                    >
                        <div className="flex flex-col items-start">
                            <span className="text-[#4F4D55] text-[14px] font-plus-jakarta-sans font-bold leading-[16.8px] break-words">
                                Cattle No.{cattle.CattleID}
                            </span>
                            {!openSections.includes(index) && (
                                <span
                                    className={`text-sm ${cattle.severity === 'Critical' ? 'text-red-600' : 'text-green-600'
                                        }`}
                                >
                                    {cattle.severity}
                                </span>
                            )}
                        </div>
                        {openSections.includes(index) ? (
                            <ChevronUp className="w-5 h-5 text-gray-500" />
                        ) : (
                            <ChevronDown className="w-5 h-5 text-gray-500" />
                        )}
                    </button>

                    <div
                        className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openSections.includes(index) ? 'max-h-96' : 'max-h-0'
                            }`}
                    >
                        {openSections.includes(index) && (
                            <div className="px-4 py-3 bg-white">
                                <div className="space-y-2">
                                    {headings.map((heading) => (
                                        <div key={heading.id} className="flex ">
                                            <span className="text-[#4F4D55] font-plus-jakarta-sans text-[12px] font-semibold leading-[12px] break-words">{heading.title}:</span>
                                            <span
                                                className={`"text-[#4F4D55] ml-2 capitalize font-plus-jakarta-sans text-[12px] font-semibold leading-[12px] break-words ${heading.value === 'severity' &&
                                                        cattle[heading.value] === 'Critical'
                                                        ? 'text-red-600'
                                                        : heading.value === 'severity'
                                                            ? 'text-green-600'
                                                            : 'text-[#4F4D55]'
                                                    }`}
                                            >
                                                {cattle[heading.value]}
                                            </span>
                                        </div>
                                    ))}
                                    <button className="w-full mt-2 px-4 py-2 bg-yellow-400 text-yellow-900 rounded-md hover:bg-yellow-500 transition-colors text-sm font-medium"  onClick={() =>
                              handleAction([
                                {
                                  id: cattle.id,
                                  role: cattle.UserRole,
                                  permission: cattle.privilege,
                                  InseminationType: cattle.InseminationType,
                                  InseminationDate: cattle.InseminationDate,
                                  ExpectedPregnancy: cattle.ExpectedPregnancy,
                                  CattleID: cattle.CattleID,
                                  ID: cattle.ID,
                                  quantity: cattle.quantity,
                                  slot: cattle.slot,
                                  milking_date: cattle.milking_date,
                                  issue_type: cattle.issuetype,
                                  issue_date: cattle.issuedate,
                                  severity: cattle.Severity,
                                  need_medicine: cattle.need_medicine,
                                  medication_date: cattle.medication_date,
                                  mode_of_administration:
                                  cattle.mode_of_administration,
                                  medicine_name: cattle.medicine_name,
                                  dosage: cattle.dosage,
                                  inseminationId: cattle.inseminationId,
                                  description: cattle.description,
                                  cured_notes: cattle.cured_notes,
                                  cured_date: cattle.cured_date,
                                  recorded_notes: cattle.recorded_notes,
                                  status: cattle.status,
                                  VaccinationName: cattle.VaccinationName,
                                  LastVaccinated: cattle.LastVaccinated,
                                  HealthStatus: cattle.HealthStatus,
                                  NextVaccination: cattle.NextVaccination,
                                  vaccination_type: cattle.vaccination_type,
                                  end_date: cattle.end_date,
                                  medicinename: cattle.MedicineName,
                                  deworm_date: cattle.deworm_date,
                                  nextdewormingdate: cattle.nextdewormingdate,
                                  Serverity: cattle.serverity,
                                  Description: cattle.Description,
                                  deleveryDate: cattle.deleveryDate,
                                  // newCalf: [item.newCalf],
                                  // weight: [item.weight],
                                  // gender: [item.gender],
                                  calf:cattle.calf
                                },
                              ])
                            }>
                                        {cattle.action}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TableAccordion;
