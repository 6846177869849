import React, { useEffect, useState } from 'react'
import SearchBox from '../Common/SearchBox'
import CommonButton from '../Common/Button'
import Heading from '../Common/Heading'
import FlexCol from '../Common/FlexCol'
import { Pagination } from 'antd'
import { useLoader } from '../../LoaderContext'
import cowicon from "../../assets/Images/Cow icon.png"
import API, { action } from '../../Api'
import TableAccordion from '../Common/TableAccordion'
import ReactTable from '../Common/Table'
import AddCulling from '../Drawer/AddCulling'
function CattleCulling() {
 const [paginationcount, setPaginationcount] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isInitialLoad, setIsInitialLoad] = useState(true)
     const [searchTerm, setSearchTerm] = useState("");
     const [cullingList,setCullingList] = useState([])
     const [form,setForm] = useState(false)
      const [selectedData, setSelectedData] = useState([])
    const { setLoading } = useLoader();
    const headings = [

        {
            id: 1,
            title: "Sl No",
            value: "SlNo",

        },
        {
            id: 2,
            title: "Cattle ID",
            value: "CattleID",
            icon: cowicon


        },
        {
            id: 3,
            title: "Lactation Number",
            value: "lactationNumber",

        },


        {
            id: 4,
            title: "Last Calving date",
            value: "lastCalvingDate",


        },
        {
            id: 5,
            title: "status",
            value: "Statuss",


        },
      
       
        {
            id: 6,
            title: "Action",
            value: "action",


        },



    ]
    useEffect(() => {
            const handleResize = () => setIsMobile(window.innerWidth <= 768);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);




        const getCullingList = async (page = currentPage, pageSize = 10) => {
            setLoading(true);
            const result = await action(API.GET_CATTLE_CULLING_LIST, {
    
                searchKey: searchTerm,
                page: page,
                pageCount: pageSize
            });
            setLoading(false);
            console.log(result.response.data, "get milk list")
           
            setPaginationcount(result.response.count);
            setCullingList(result.response.data)
            const formattedData = result.response.data.map((item, index) => ({
                SlNo: ((page - 1) * pageSize + index + 1).toString().padStart(2, '0'), 
                CattleID: item.cattle_id, 
                lactationNumber: item.lactation,
                lastCalvingDate: item.last_calving_date,
                Statuss: item.cattle_status,
                action: item.status===1?"Culling":"View Cattle",
                type: item.Culling?.type,
                remarks:item.Culling?.remarks
                
    
            }))
    
            setCullingList(formattedData);
        };
    
    
    
        useEffect(() => {
            getCullingList()
          
    
        }, [pageSize, currentPage, searchTerm])


 useEffect(() => {
        if (!isInitialLoad) {
            setCurrentPage(1)
            getCullingList(1, pageSize)
        } else {
            setIsInitialLoad(false)
            getCullingList(currentPage, pageSize)
        }
    }, [searchTerm, pageSize])

    useEffect(() => {
        if (!isInitialLoad) {
            getCullingList(currentPage, pageSize)
        }
    }, [currentPage])
    const handlePageChange = (page) => {
        setCurrentPage(page)
        getCullingList(page, pageSize)
    }

    const handleclick = (userId) =>{
        setForm(prevState => {

            if (prevState) {
                getCullingList()
            }
            return !prevState;
          });
          setSelectedData(userId)
    }

  return (
    <div style={{ backgroundColor: "#FDFDFD" }} className='h-[calc(100vh_-_135px)]'>
            <FlexCol>
                <div className="flex flex-col sm:flex-row justify-between">
                    <div className='d-flex space-x-2'>
                        <Heading title='Culling Report' />
                        
                    </div>
                    <div className="d-flex space-x-5">
                        <SearchBox placeholder='Search Cattle' className='bg-[#F8F9FB]' value={searchTerm} onSearch={setSearchTerm} />
                       
                        <CommonButton title='Record Culling' style={{backgroundColor:"#6728F3"}} onClick={handleclick}  />
                    </div>
                </div>
                {isMobile ? (
                    <TableAccordion  cattleData={cullingList}  />
                ) : (
                    <ReactTable headings={headings} data={cullingList} headingcolor="#E6DBFD" textcolor='#21055E'  pagination={paginationcount} fetchData={getCullingList} handleAction={handleclick} />
                )}
                {(cullingList?.length > pageSize || paginationcount > 10) && (
                    <div className="flex justify-end mt-4">
                        <Pagination
                            current={currentPage}
                            total={paginationcount}
                            pageSize={pageSize}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                            className="p-2 font-sans text-[#A0A0A0]"
                            itemRender={(page, type, originalElement) => {
                                if (type === "page") {
                                    return (
                                        <span
                                            className={`px-2 ${page === currentPage ? "font-bold text-black" : "font-normal"
                                                }`}
                                        >
                                            {page}
                                        </span>
                                    );
                                }
                                return originalElement;
                            }}
                        />
                    </div>
                )}
                </FlexCol>
                <AddCulling isOpen={form} toggle={handleclick} data={selectedData}/>
                </div>
  )
}

export default CattleCulling