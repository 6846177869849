import { Popover, Select } from "antd";
import React, { useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { FaAsterisk } from "react-icons/fa";

export default function DropdownButton({
  title = "",
  value = null,
  change = () => {},
  options = [],
  error = "",
  placeholder = "",
  className = "",
  onSearch = () => {},
  styles,
  description,
  required = false,
  descriptionTop = "",
  rightIcon = false,
  PopoverContent = {},
  icondropDown = false,
  icon = false,
  image = false,
  disabled = false,
  disableFilterSort = false,
  SelectName = "Select",
  showErrorMessage = true,
  disableCondition = () => false,
  defaultValue = null, // New prop for initial selection
}) {
  const isSmallScreen = useMediaQuery({ maxWidth: 1439 });
  const { Option } = Select;

  // Set initial value when component mounts
  useEffect(() => {
    if (defaultValue && !value) {
      change(defaultValue);
    }
  }, [defaultValue]);

  const filterSortFunction = disableFilterSort
    ? undefined
    : (optionA, optionB) =>
        (optionA?.label ?? "")
          ?.toLowerCase()
          .localeCompare((optionB?.label ?? "")?.toLowerCase());

  const filterOption = (input, option) =>
    option.children.toLowerCase().includes(input?.toLowerCase());


  const formatLabel = (label) => {
    if (typeof label === 'string') {
      return label.charAt(0).toUpperCase() + label.slice(1);
    }
    return label; // Return as-is if it's not a string (e.g., number)
  };

  const renderSelect = () => {
    const commonProps = {
      showSearch: true,
      placeholder,
      value: value || defaultValue,
      onChange: change,
      onSearch,
      filterOption,
      className: `w-full rounded-lg capitalize`,
      style: {
        ...styles,
        borderRadius: "8px",
        boxShadow: error
          ? "0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          : "",
      },
      menuItemSelectedIcon: <FaAsterisk className="text-[8px]" />,
      status: `${error && "error"}`,
      size: isSmallScreen ? "default" : "large",
      disabled,
    };

    if (icondropDown || image) {
      return (
        <Select
          {...commonProps}
          filterSort={filterSortFunction}
          optionLabelProp="label"
        >
          {options?.map((each, i) => (
            <Option key={i} value={each.value}>
              <div className="flex items-center gap-3">
                {image && (
                  <div className="size-6 rounded-full overflow-hidden">
                    <img
                      src={each.image}
                      alt={each.label}
                      className="object-cover w-full h-full object-center"
                    />
                  </div>
                )}
                <div className="flex flex-col gap-1 country-option capitalize ">
                  <span>{each.label}</span>
                  <p className="text-gray-500 text-xs font-medium font-['Inter'] leading-none">
                    {each.description}
                  </p>
                </div>
              </div>
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <Select {...commonProps} optionFilterProp="label" filterSort={filterSortFunction} >
        {/* <Option disabled className="text-gray-400">{SelectName}</Option> */}
        {options?.map((each, i) => (
          <Option
            key={i}
            value={each.value}
            disabled={disableCondition(each)}
            other={each.other}
          >
            {formatLabel( each.label)} 
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <div className={`${className} flex flex-col gap-2`}>
      {title && (
        <div className="flex flex-col gap-0.5">
          <div className="flex items-center text-[#16A34A] gap-0.5">
            <label htmlFor="" className="font-medium text-[16px] md:text-[12px] 2xl:text-[16px]">
              {title}
            </label>
            {required && <FaAsterisk className="text-[6px] text-rose-600" />}
          </div>
          {descriptionTop && (
            <p className="text-xs font-medium opacity-50 dark:text-white">
              {descriptionTop}
            </p>
          )}
        </div>
      )}
      <div className="relative flex items-center">
        {renderSelect()}
        {error && (
          <FiAlertCircle className="absolute top-2.5 right-8 -mr-1 transform -translate-y-2/5 text-red-400" />
        )}
        {rightIcon && (
          <Popover content={PopoverContent} style={{ borderRadius: "13.45px" }}>
            <IoAlertCircleOutline className="pl-1 text-xl opacity-50" />
          </Popover>
        )}
      </div>
      {description && (
        <p className="text-xs font-normal 2xl:text-sm opacity-70 dark:text-white">
          {description}
        </p>
      )}
      {error && showErrorMessage && (
        <p className="flex justify-start items-center my-1 mb-0 text-[10px] text-red-500">
          <span className="text-[10px] pl-1">{error}</span>
        </p>
      )}
    </div>
  );
}