import React, { useState, useEffect } from "react";
import { CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import RevenueCharts from "./RevenueCharts";  // Assuming RevenueCharts handles the actual chart rendering

function Graph({ title = "", series = [], categories = [] }) {
  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);

  useEffect(() => {
    // If there is valid series data, update the chartData and categories
    if (series && series.length > 0) {
      setChartData(series);
      setChartCategories(categories);
    }
  }, [series, categories]);

  return (
    <React.Fragment>
      <div className="2xl:p-6 p-2 bg-white rounded-xl border-1 border-[#E3E3E3]">
        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div className="border-0 align-items-center d-flex ml-5">
              <h4 className="card-title mb-0 flex-grow-1 mt-2 text-[14px] 2xl:text-[18px]" style={{
                color: "#495057",
                fontFamily: "Plus Jakarta Sans",
                fontWeight: 700,
              }}>{title}</h4>
              {/* <UncontrolledDropdown className="card-header-dropdown mr-5 mt-2" direction="start">
                <DropdownToggle tag="a" className="text-reset dropdown-btn text-[14px] 2xl:text-[18px]" style={{ textDecoration: 'none' }} role="button">
                  <span className="text-muted" style={{
                    color: "#898483",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: 400,
                  }}>Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end">
                  <DropdownItem>Download Report</DropdownItem>
                  <DropdownItem>Export</DropdownItem>
                  <DropdownItem>Import</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
            <div dir="ltr" className="mt-4">
              <RevenueCharts
                series={chartData}
                dataColors='["--vz-secondary", "--vz-primary", "--vz-primary-rgb, 0.50"]'
                categories={chartCategories}
              />
            </div>
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
}

export default Graph;
