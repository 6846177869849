import React, { useEffect, useState } from 'react'
import { FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'
import FlexCol from '../Common/FlexCol'
import { Flex, notification } from 'antd'
import DateSelect from '../Common/DateSelector'
import FormInput from '../Common/FormInput'
import TextArea from '../Common/TextArea'
import CommonButton from '../Common/Button'
import API, { action } from '../../Api'
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import MultiSelector from '../Common/MultiSelector'
import DropdownButton from '../Common/DropdownButton'
import Label from '../Common/Label'

function RecordDeworming({ isOpen, toggle, selectedData }) {
    console.log(selectedData, "eererere");
    const isDisabled = !!selectedData;
    const [cattleid, setCattleid] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const id = localStorage.getItem('userid');
    const [loading, setLoading] = useState(false);
    const healthstatusOption = [
        { value: "critical", label: "Critical" },
        { value: "normal", label: "Normal" },
        { value: "major", label: "Major" },
    ];
    const getCattleId = async () => {
        try {

            const result = await action(API.GET_CATTLE_ID, {
                searchKey: searchKey,
                page: 1,
                pageCount: 60,
            });
            const cattleID = result.response.data.map(id => ({
                label: id.cattle_id, // Display name
                value: id.cattle_id // Unique identifier
            }));
            setCattleid(cattleID); // Set data with both label and value
        } catch (error) {
            console.error("Error fetching cattle data:", error);
        }
    };

    useEffect(() => {
        getCattleId();
    }, [searchKey]);


    const validationSchema = Yup.object({
        medicineName: Yup.string().required('Medicine Name is required'),
        dosage: Yup.string().required('Dosage is required'),
        dewormingDate: Yup.date().required('Deworming Date is required'),
        cattle: Yup.mixed().required('Cattle  is required'),
        healthStatus: Yup.string().required('Health Status is required'),
        // description: Yup.string().required('Description is required')
    });

    const formik = useFormik({
        initialValues: {
            medicineName: '',
            dosage: '',
            dewormingDate: '',
            cattle: null,
            healthStatus: 'normal',
            nextDewormingDate: '',
            description: '',
        },


        validationSchema: selectedData ? null : validationSchema,
        onSubmit: async (values) => {
            // Conditionally add medicine validation if activeTab2 is "Yes"
            //   if (activeTab2 === 'Yes') {
            //     if (!values.medicineName) {
            //       formik.setErrors({ medicineName: 'Medicine Name is required' });
            //       return;
            //     }
            //     if (!values.medicineDosage) {
            //       formik.setErrors({ medicineDosage: 'Medicine Dosage is required' });
            //       return;
            //     }
            //   }
            setLoading(true);
            try {
                // Determine if it's an update or a new record
                if (selectedData) {
                    // Update existing vaccination record
                    const result = await action(API.UPDATE_DEWORMING, {
                        id: selectedData[0]?.id, // Assuming `id` is a unique identifier for the record

                    });

                    notification.success({ message: result.response.message, placement: "top" });
                    formik.resetForm();
                    toggle();
                } else {

                    const result = await action(API.ADD_DEWORMING, {
                        cattle_ids: values.cattle,
                        medicine_name: values.medicineName,
                        dosage: values.dosage,
                        deworm_date: values.dewormingDate,
                        next_deworm_date: values.nextDewormingDate,
                        status: values.healthStatus,
                        description: values.description,
                        created_by: id,

                    });
                    notification.success({ message: result.response.message, placement: "top", });
                    // }
                    // Optionally reset the form or close the offcanvas
                    formik.resetForm();
                    toggle(); // Close the offcanvas
                }
            } catch (error) {
                console.error(error);
                notification.error({ message: error.response?.data?.response?.message, placement: "top", });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });


    useEffect(() => {
        if (selectedData && selectedData.length > 0) {
            // Set basic fields
            formik.setFieldValue("healthStatus", selectedData[0]?.Serverity);
            formik.setFieldValue("dewormingDate", selectedData[0]?.deworm_date);
            formik.setFieldValue("cattle", selectedData[0]?.CattleID);
            formik.setFieldValue("medicineName", selectedData[0]?.medicinename);
            formik.setFieldValue("dosage", selectedData[0]?.dosage);
            formik.setFieldValue("nextDewormingDate", selectedData[0]?.nextdewormingdate);
            formik.setFieldValue("activeTab", selectedData[0]?.vaccination_type);
            formik.setFieldValue("slot", selectedData[0]?.slot);
            formik.setFieldValue("description", selectedData[0]?.Description);
          
        }
    }, [selectedData]);


    const toggle1 = () => {
        formik.resetForm();

        toggle()
    }
    const isStatusProvided = selectedData && selectedData[0]?.status === 'given';

    return (
        <Offcanvas isOpen={isOpen} toggle={toggle1} direction="end" className="w-full md:!w-[320px] 2xl:!w-[480px]  max-h-full overflow-y-auto"    >
            <FlexCol className={"p-4"} >

                <OffcanvasHeader >
                    <div >
                        <div className="text-[16px] font-semibold 2xl:text-2xl text-[#09090B]  leading-[21.6px] break-words font-plus-jakarta-sans">Record Deworming</div>
                        <div className="text-[10px] 2xl:text-sm  text-[#726C6C] font-plus-jakarta-sans ">Provide deworming data and follow-up.</div>
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody >
                    <form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                        {!isDisabled ? (
                            <FlexCol className={""} gap={20}>
                                <Flex className="grid grid-cols-1 gap-[30px] items-center">

                                    <FormInput
                                        title="Medicine Name"
                                        placeholder="Enter Medicine Name"
                                        name="medicineName"
                                        value={formik.values.medicineName}
                                        change={(e) => formik.setFieldValue('medicineName', e)}
                                        error={formik.errors.medicineName && formik.touched.medicineName ? formik.errors.medicineName : null}
                                        disabled={isDisabled}
                                        required={true}
                                    />
                                    <FormInput
                                        title="Dosage (mg/bwt)"
                                        placeholder="Enter Dosage"
                                        name="dosage"
                                        value={formik.values.dosage}
                                        type='number'
                                        change={(e) => formik.setFieldValue('dosage', e)}
                                        error={formik.errors.dosage && formik.touched.dosage ? formik.errors.dosage : null}
                                        disabled={isDisabled}
                                        required={true}
                                        maxLength={4}
                                    />
                                    <DateSelect
                                        title="Deworming Date"
                                        placeholder="Choose Deworming Date"
                                        name="dewormingDate"
                                        value={formik.values.dewormingDate}
                                        change={(e) => formik.setFieldValue('dewormingDate', e)}
                                        error={formik.errors.dewormingDate && formik.touched.dewormingDate ? formik.errors.dewormingDate : null}
                                        disabled={isDisabled}
                                        required={true}
                                    />
                                    <MultiSelector
                                        title="Cattle"
                                        placeholder="Select Cattle"
                                        options={cattleid}
                                        value={formik.values.cattle}
                                        onSearch={setSearchKey}
                                        onChange={value => formik.setFieldValue('cattle', value)}
                                        error={formik.errors.cattle && formik.touched.cattle ? formik.errors.cattle : null}
                                        disabled={isDisabled}
                                        required={true}
                                    />
                                    <DropdownButton
                                        title="Health Status"
                                        placeholder="Critical"
                                        value={formik.values.healthStatus}
                                        change={value => formik.setFieldValue('healthStatus', value)}
                                        options={healthstatusOption}
                                        error={formik.errors.healthStatus && formik.touched.healthStatus ? formik.errors.healthStatus : null}
                                        required={true}
                                        disabled={isDisabled}
                                        SelectName='Select Health Status'

                                    />
                                    {!isDisabled && (

                                        <DateSelect
                                            title="Next Deworming Date"
                                            placeholder="Choose Next Deworming Date"
                                            name="nextDewormingDate"
                                            value={formik.values.nextDewormingDate}
                                            change={(date) => formik.setFieldValue('nextDewormingDate', date)}
                                            disableBeforeDate={formik.values.dewormingDate}
                                            disabled={isDisabled}
                                        />
                                    )}
                                    <TextArea
                                        title="Description"
                                        placeholder="Enter Description"
                                        name="description"
                                        value={formik.values.description}
                                        change={(e) => formik.setFieldValue('description', e)}
                                        disabled={isDisabled}
                                        // required={true}
                                        // error={formik.errors.description && formik.touched.description ? formik.errors.description : null}
                                    />

                                </Flex>
                            </FlexCol>
                        ):(
                            <>
                            <Label title=' Medicine Name' value={formik.values.medicineName || '--'} />
            
                            <Label title='Dosage (mg/bwt)' value={formik.values.dosage || ''} />
                            <Label title='Deworming Date' value={formik.values.dewormingDate || ''} />
                            <Label title='Cattle' value={formik.values.cattle
                                || ''} />
                            <Label title='Health Status' value={formik.values.healthStatus
                                || ''} />
                           <Label title='Next Deworming Date' value={formik.values.nextDewormingDate || '--'} />                         
                           <Label title='Description' value={formik.values.description || '--'} />
                        </>
                        )}
                        </FormGroup>
                        <div className=" flex justify-between mt-8 ">
                            <CommonButton
                                style={{ fontSize: "12px", backgroundColor: "none", color: "#726C6C", border: "1px #CCCBCB solid ", marginRight: "8px" }}
                                onClick={toggle}
                                outline
                                title='Discard'
                            />
                            {!isStatusProvided && (


                                <CommonButton title='Record Deworming' style={{ fontSize: "12px" }} />
                            )}

                        </div>
                    </form>
                </OffcanvasBody>

            </FlexCol>
        </Offcanvas>
    )
}

export default RecordDeworming