import React, { useState } from 'react';
import { Dropdown, Checkbox, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { RiEqualizerFill } from 'react-icons/ri';

const Filterbtn = () => {
  // State to manage checkbox selections
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Sample filter options - replace with your actual options
  const filterOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' },
    { label: 'Option 4', value: '4' }
  ];

  // Handle checkbox changes
  const handleCheckboxChange = (checkedValues) => {
    setSelectedOptions(checkedValues);
  };

  // Dropdown content
  const dropdownContent = (
    <div className="bg-white p-4 rounded-lg shadow-lg min-w-[200px]">
      <Checkbox.Group 
        value={selectedOptions}
        onChange={handleCheckboxChange}
        className="flex flex-col gap-2"
      >
        {filterOptions.map(option => (
          <Checkbox 
            key={option.value} 
            value={option.value}
            className="text-gray-700 hover:bg-gray-50 p-2 rounded transition-colors"
          >
            {option.label}
          </Checkbox>
        ))}
      </Checkbox.Group>
      
      <div className="mt-4 pt-3 border-t border-gray-200 flex justify-end gap-2">
        <button 
          onClick={() => setSelectedOptions([])}
          className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 transition-colors"
        >
          Clear
        </button>
        <button 
          onClick={() => console.log('Apply:', selectedOptions)}
          className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
        >
          Apply
        </button>
      </div>
    </div>
  );

  return (
    <Dropdown 
      overlay={dropdownContent} 
      trigger={['click']}
      placement="bottomRight"
    >
      <button className="border border-gray-300 bg-white py-2 px-3 transition-all duration-300 hover:bg-gray-100 md:py-3 md:px-4 md:text-lg">
      <RiEqualizerFill className="text-[#726C6C] text-sm 2xl:text-2xl" />
      </button>
    </Dropdown>
  );
};

export default Filterbtn;