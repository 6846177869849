import React from "react";
import ReactApexChart from "react-apexcharts";


const StockInventoryChart = ({ dataColors, series ,barColors = ['#16A34A', '#B7F5CE'] }) => {
 // Available stock color and needed stock color

    const options = {
        chart: {
            height: 370,
            type: "bar",
            stacked: true, // Enable stacking
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                endingShape: 'rounded',
                horizontal: false,
            },
        },
        colors: barColors,
        xaxis: {
            categories: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7"],
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        grid: {
            show: false,
            padding: {
                top: 0,
                right: 0,
                bottom: 15,
                left: 10,
            },
            row: {
                colors: undefined, // Remove row colors
                opacity: 0, // Set opacity to 0 to hide horizontal lines
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            yaxis: {
                formatter: (value) => value.toFixed(2),
            },
        },
        dataLabels: {
            enabled: false,
            formatter: (val) => val.toFixed(2), // Format data labels
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series} // Ensure series contains both datasets for stacking
                type="bar"
                height="370"
                width="100%"
                className="apex-charts"
            />
        </React.Fragment>
    );
}

export default StockInventoryChart;
